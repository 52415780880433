import { defineStore, skipHydrate } from 'pinia';
import { getCurrentInstance, ref } from '@vue/composition-api';
import { load } from '@vf/shared/src/utils/helpers/load-script';
import { useI18n } from '@vf/composables';
import { isClient } from '@vf/shared/src/utils/helpers';

export type PayPalContext = 'mini-cart' | 'cart' | 'shipping' | 'payment';

export const usePayPalStore = (instance?) => {
  const currentInstance = getCurrentInstance();
  const localInstance = currentInstance?.proxy || instance;
  return defineStore('paypal', () => {
    const payPalClientId = localInstance.$getEnvValueByCurrentLocale(
      'PAYPAL_CLIENT_ID'
    );
    const { defaultCurrency } = useI18n(localInstance);

    const paypalScriptLoaded = skipHydrate(ref(false));
    const payPalContext = skipHydrate(ref<PayPalContext>('cart'));

    if (isClient) {
      load(
        `https://www.paypal.com/sdk/js?client-id=${payPalClientId}&disable-funding=card,p24,credit,sepa&intent=order&currency=${defaultCurrency.value}`,
        () => {
          paypalScriptLoaded.value = true;
        },
        () => {
          localInstance.$log.error(
            `[@composables/store/paypal::usePayPalStore] Error loading PayPal script`
          );
        }
      );
    }

    const setPayPalContext = (value: PayPalContext) => {
      payPalContext.value = value;
    };

    return {
      setPayPalContext,
      paypalScriptLoaded,
      payPalContext,
    };
  })();
};

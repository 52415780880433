var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vf-plp-visual-filter",class:{
    'vf-plp-visual-filter--has-selection': _vm.hasSelection,
  }},[_c('div',{staticClass:"vf-plp-visual-filter__container"},[_c('UiSwiper',{class:{
        'vf-plp-visual-filter__container__arrows-hidden': !_vm.showArrows,
      },attrs:{"scroll-props":{
        gap: '2px',
        loop: true,
        scrollBy: _vm.$viewport.isSmall ? 1 : undefined,
        align: _vm.$viewport.isSmall ? 'center' : undefined,
      },"icon-size":"1.75rem","pagination":_vm.showPagination}},_vm._l((_vm.filters),function(filter,index){return _c('button',{key:index,staticClass:"vf-plp-visual-filter__container__btn",class:{
          'vf-plp-visual-filter__container__btn--selected': _vm.isSelected(
            filter
          ),
        },attrs:{"aria-label":filter.headline},on:{"click":function($event){return _vm.toggleFilter(filter)}}},[_c('VfPlpVisualFilterTile',{attrs:{"headline":filter.headline,"subcopy":filter.subcopy,"selected":_vm.isSelected(filter),"background-style":filter.backgroundStyle,"image-url":filter.imageUrl}})],1)}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
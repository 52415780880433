//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'VfShippingDestination',
  props: {
    icon: {
      type: String,
      default: '',
    },
    selected: Boolean,
    title: {
      type: String,
      required: true,
    },
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    !_vm.attributesData.isFutureProduct ||
    (_vm.attributesData.isFutureProduct && !_vm.isAlreadySubscribed)
  )?_c('div',{staticClass:"product-add-to-cart",class:{ 'product-add-to-cart--redesign': _vm.isVansPdpRedesignEnabled },attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'])}},[(_vm.product && _vm.product.customsAvailability)?_c('VfText',{staticClass:"product-add-to-cart__delivery-time-label",attrs:{"text-color":"var(--c-gray)","font-weight":"bold","font-sizes":['sm']}},[_vm._v("\n    "+_vm._s(_vm.product.customsAvailability)+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{class:{
      'product-add-to-cart__favorites':
        _vm.showAddToFavourites && _vm.product && !_vm.showProductCustomize,
      'product-add-to-cart__with-custom': _vm.showProductCustomize,
    }},[(!_vm.isNotifyMeEnabled || !_vm.isNotifyMeFormVisible)?[(!_vm.isSignInToBuy)?_c('VfButton',{ref:"addToCartRef",staticClass:"product-add-to-cart__cta",class:_vm.buttonClasses,attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'componentConfig'),"disabled":_vm.isButtonDeactivated,"loading":_vm.isButtonLoading,"aria-live":"polite"},on:{"click":function($event){!_vm.isNotifyMeEnabled ? _vm.validateAndAddToCart() : _vm.getNotifyMeAction()}}},[_vm._v("\n        "+_vm._s(_vm.buttonText)+"\n      ")]):_c('VfButton',{ref:"addToCartRef",staticClass:"product-add-to-cart__cta",class:_vm.buttonClasses,attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'componentConfig')},on:{"click":_vm.openSignInToBuyModal}},[_vm._v("\n        "+_vm._s(_vm.translations.signInToBuy)+"\n      ")]),_vm._v(" "),(_vm.showProductCustomize)?_c('ProductCustomize',{attrs:{"context-key":_vm.contextKey,"button-component":"ThemeButton","icon":"customize_board","button-size":"lg","is-full-width":true,"button-style":{ color: 'secondary', variant: 'border' },"icon-position":"right","text":_vm.translations.buttonCTACustomize}}):_vm._e(),_vm._v(" "),(_vm.showAddToFavourites && _vm.shouldShowAddToFavButton)?_c('SaveToFavorites',{staticClass:"product-add-to-cart__add-to-fav-cta",attrs:{"context-key":_vm.contextKey,"aria-label":_vm.translations.favoritesAriaLabel,"heart-button":"","added-to-favorites-notification":_vm.translations.addedToFavoritesNotification}}):_vm._e()]:_vm._e(),_vm._v(" "),(_vm.isNotifyMeEnabled || _vm.isNotifyMeFormVisible)?_c('NotifyMeModal',{attrs:{"translations":_vm.translations,"visible":_vm.isNotifyMeFormVisible,"sizes":_vm.availableSizes,"lengths":_vm.product.lengths,"widths":_vm.product.widths,"zips":_vm.product.zips,"prepopulate-email":_vm.customerEmail,"context-key":_vm.contextKey},on:{"close":function($event){_vm.isNotifyMeFormVisible = false}}}):_vm._e()],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
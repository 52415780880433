//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed, ref, inject } from '@vue/composition-api';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'VfFlagReview',
  mixins: [validationMixin],
  serverCacheKey: getCacheKeyFromProps,
  props: {
    flagged: {
      type: Boolean,
      default: () => false,
    },
    reviewId: {
      type: [String, Number],
      default: () => '',
    },
    flagUgc: {
      type: Boolean,
      default: () => false,
    },
    translations: {
      type: Object,
      default: () => ({
        flagReviewNotFlagged: 'Flag this review',
        flagReviewFlagged: 'You flagged this review',
        flagUGCFlagged: 'Flagged',
        flagUGCNotFlagged: 'Flag',
        heading: 'What’s the issue?',
        emailPlaceholder: 'Email Address',
        emailRequiredText: 'Email address is required',
        emailInvalidText: 'Please enter a valid email address',
        commentsPlaceholder: 'Comments',
        flagButtonLabel: 'Flag',
        cancelButtonLabel: 'Cancel',
        flagTypes: {
          profane: 'Profanity',
          wrong_product: 'Wrong Product',
          spam: 'Spam',
          duplicate: 'Duplicate',
          copyright: 'Copyright Violation',
          not_review: 'Not a Product Review',
          customer_image: 'Customer Image',
          other: 'Other',
        },
      }),
    },
  },
  emits: ['flag-review-submit-reviews'],
  setup(props) {
    const issue = ref('profane');
    const email = ref(null);
    const comments = ref('');
    const modalVisible = ref(false);
    const flaggedState = ref(props.flagged);

    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    const radioOptionsColumn1 = computed(() => {
      return [
        { id: 'profane', text: props.translations.flagTypes.profane },
        {
          id: 'wrong_product',
          text: props.translations.flagTypes.wrong_product,
        },
        { id: 'spam', text: props.translations.flagTypes.spam },
        { id: 'duplicate', text: props.translations.flagTypes.duplicate },
      ];
    });

    const radioOptionsColumn2 = computed(() => {
      return [
        { id: 'copyright', text: props.translations.flagTypes.copyright },
        { id: 'not_review', text: props.translations.flagTypes.not_review },
        {
          id: 'customer_image',
          text: props.translations.flagTypes.customer_image,
        },
        { id: 'other', text: props.translations.flagTypes.other },
      ];
    });

    const handleFlagReviewOpen = () => {
      modalVisible.value = true;
    };

    const setToFlaggedState = () => {
      flaggedState.value = true;
    };

    const handleModalClose = () => {
      modalVisible.value = false;
    };

    return {
      issue,
      email,
      comments,
      radioOptionsColumn1,
      radioOptionsColumn2,
      modalVisible,
      flaggedState,
      handleFlagReviewOpen,
      handleModalClose,
      setToFlaggedState,
      isCoreRedesignEnabled,
    };
  },
  computed: {
    requireComment() {
      return !!this.$themeConfig?.flagReview?.requireComment;
    },
  },
  methods: {
    flagReviewSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      const eventParams = {
        flagType: this.issue,
        email: this.email,
        comments: this.comments,
        reviewId: this.reviewId,
      };
      this.$emit('flag-review-submit-reviews', eventParams);
      this.setToFlaggedState();
      this.handleModalClose();
    },
  },
  validations() {
    return {
      email: {
        required,
        email: (eml) => email(eml ? eml.toLowerCase() : eml), // vuelidate considers domain names with captial letters as invalid
      },
      comments: {
        required: this.requireComment ? required : false,
      },
    };
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('div',{staticClass:"pdp-upsell__container placeholder",class:{
    'pdp-upsell__container--redesign': _vm.isVansPdpRedesignEnabled,
  }},[_c('div',{staticClass:"pdp-upsell__container-layout"})]):(_vm.upsellItem && !_vm.attributesData.isSoldOut)?_c('div',{staticClass:"pdp-upsell__container",class:{
    'pdp-upsell__container--redesign': _vm.isVansPdpRedesignEnabled,
  }},[_c('div',{staticClass:"pdp-upsell__container-layout"},[_c('VfHeading',{staticClass:"pdp-upsell__container-extras",attrs:{"level":"6","title-modifier":"title-6","title":_vm.translations.extras}}),_vm._v(" "),_c('VfUpsellsCheckbox',{class:_vm.upsellsCheckboxModifier,attrs:{"selected":_vm.isUpsellSelected,"upsell":_vm.upsellItem,"name":_vm.upsellItem.name,"upsell-title":_vm.translations.title.replace('{{productName}}', _vm.upsellItem.name),"add-to-cart-text":_vm.translations.addToCartCta,"details-button-text":_vm.translations.details},on:{"update:selected":_vm.handleUpdateSelected,"details-button-click":function($event){_vm.isModalOpen = true}}})],1),_vm._v(" "),_c('VfModal',{staticClass:"pdp-upsell__modal",attrs:{"visible":!!_vm.isModalOpen},on:{"close":function($event){_vm.isModalOpen = false}}},[_c('VfContainer',[_c('VfRow',[_c('VfColumn',{attrs:{"medium":"7","large":"8"}},[_c('VfPrice',{attrs:{"regular":_vm.getFormattedPrice(
                _vm.upsellItem.price.original,
                _vm.upsellItem.price.currency
              ),"special":_vm.hasSpecialPrice
                ? _vm.getFormattedPrice(
                    _vm.upsellItem.price.current,
                    _vm.upsellItem.price.currency
                  )
                : ''}}),_vm._v(" "),_c('VfHeading',{attrs:{"level":3,"title-modifier":"title-2","title":_vm.upsellItem.name}}),_vm._v(" "),_c('VfImage',{staticClass:"pdp-upsell__small-image small-only",attrs:{"alt":_vm.upsellItem.image.alt,"src":_vm.upsellItem.image.src}}),_vm._v(" "),_c('VfText',{attrs:{"content":_vm.upsellItem.description,"html":""}})],1),_vm._v(" "),_c('VfColumn',{attrs:{"medium":"5","large":"4","hidden-on-small":""}},[_c('VfImage',{attrs:{"alt":_vm.upsellItem.image.alt,"src":_vm.upsellItem.image.src}})],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
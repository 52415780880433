


























































































































































































import {
  computed,
  defineComponent,
  onBeforeUnmount,
  ref,
  watch,
} from '@vue/composition-api';
import { helpers, required } from 'vuelidate/lib/validators';
import {
  useAccount,
  useFindInStore,
  useProduct,
  useRequestTracker,
  useValidation,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import ls from '@vf/composables/src/utils/localStorage';
import {
  PdpShippingMethod,
  useCartStore,
} from '@vf/composables/src/store/cartStore';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import VfShippingDestination from '@vf/ui/components/Atom.ShippingDestination.vue';

export default defineComponent({
  name: 'VfShippingDestinations',
  components: {
    VfShippingDestination,
  },
  props: {
    contextKey: {
      type: String,
      default: 'product',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { onAllDone } = useRequestTracker(root);
    const {
      getProductAvailabilities,
      getStores,
      requestPendingFlag,
      resetData,
      stores: _,
    } = useFindInStore(root);
    const {
      checkAttributes,
      isQuickShopContext,
      product,
      scrollToFirstValidationError,
    } = useProduct(root, props.contextKey);
    const cart = useCartStore();
    const { setValidation, $v } = useValidation(root, 'SEARCH_STORE_FORM');
    const {
      isCoreRedesignEnabled,
      isNewPdpStickyHeaderBehaviourEnabled,
    } = useFeatureFlagsStore();

    const attributesNotValid = computed(() => checkAttributes({ lazy: true }));
    const { favoriteStoreId, setFavoriteStoreId } = useAccount(root);

    const qtyThreshold = 10;
    const storeDetailsIndex = ref(null);
    const search = ref('');
    const showSearchForm = ref(false);
    const searchApiErrorMessage = ref('');
    const apiNoStores = ref('');
    const selectedStore = ref(JSON.parse(ls.getItem('defaultStore')));
    const isProductAvailableInStore = ref(true);
    const storeId = ref(null);
    const availableToday = ref(false);
    const stores = ref([]);

    const checkProductAvailable = (store) =>
      availableToday.value
        ? store.has_product && store.bopis_enabled === '1'
        : (store.has_product && store.bopis_enabled === '1') ||
          store.sts_enabled === '1';

    const setStores = () => {
      stores.value = _.value.filter(checkProductAvailable).slice(0, 5);
    };

    const setStore = (store) => {
      if (!store) return;
      selectedStore.value = store;
      storeId.value = store.enterprise_store_identifier;
      isProductAvailableInStore.value = true;
      if (favoriteStoreId.value !== storeId.value) {
        setFavoriteStoreId(storeId.value);
      }
      ls.setItem('defaultStore', JSON.stringify(store));
    };

    /**
     * return default Store if is present in stores
     * return first available store is we don't have default Store
     */
    const getPresetStore = () => {
      let findIndex = 0;
      if (selectedStore.value) {
        findIndex = stores.value.findIndex(
          (store) =>
            store.enterprise_store_identifier ===
            selectedStore.value.enterprise_store_identifier
        );
      }
      return stores.value[findIndex];
    };

    const setErrorMessage = (response: null | unknown) => {
      if (response === null) {
        searchApiErrorMessage.value = root.$t(
          'shippingDestinations.locationNotRecognized'
        ) as string;
      } else {
        apiNoStores.value = root.$t(
          availableToday.value
            ? 'shippingDestinations.noStoresToday'
            : 'shippingDestinations.noStores'
        ) as string;
      }
    };

    const resetErrorMessage = () => {
      searchApiErrorMessage.value = '';
      apiNoStores.value = '';
    };

    const searchByPC = async () => {
      $v.value.$touch();
      resetErrorMessage();

      if ($v.value.$invalid) {
        resetData();
        return;
      }

      storeDetailsIndex.value = null;
      cart.setCartLoading(true);
      const response = await getStores({
        postalCode: search.value,
        productId: product.value.variant.id,
      });
      setStores();
      if (stores.value.length) {
        setStore(getPresetStore());
      } else {
        setErrorMessage(response);
      }

      cart.setCartLoading(false);
    };

    const scrollToError = () => {
      const {
        isHeaderSticky,
        scrollToErrorOffsetWithTopStickyHeader,
        scrollToErrorOffset: scrollToErrorOffsetTheme,
      } = root.$themeConfig.productAddToCart;
      // TODO: GLOBAL15-63801 clean up
      const scrollToErrorOffsetDesktop =
        isNewPdpStickyHeaderBehaviourEnabled || isHeaderSticky
          ? scrollToErrorOffsetWithTopStickyHeader
          : scrollToErrorOffsetTheme;
      // TODO: GLOBAL15-63801 clean up
      const scrollToErrorOffset = !root.$viewport.isSmall
        ? scrollToErrorOffsetDesktop
        : scrollToErrorOffsetTheme;
      scrollToFirstValidationError(
        scrollToErrorOffset,
        root.$viewport.isSmall && isQuickShopContext.value
      );
    };

    const selectShipInStore = () => {
      if (attributesNotValid.value) {
        checkAttributes();
        scrollToError();
        return false;
      }
      cart.pdpShippingMethod = PdpShippingMethod.Pickup;
      return true;
    };

    const changeStore = () => {
      if (!selectShipInStore()) return;
      cart.setCartLoading(true);
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            resetErrorMessage();
            const response = await getStores({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
              productId: product.value.variant.id,
            });
            setStores();
            if (stores.value.length) {
              setStore(getPresetStore());
            } else {
              setErrorMessage(response);
              showSearchForm.value = true;
            }
            if (!selectedStore.value) {
              showSearchForm.value = true;
            }
          } catch {
            showSearchForm.value = true;
          } finally {
            cart.setCartLoading(false);
          }
        },
        () => {
          showSearchForm.value = true;
          cart.setCartLoading(false);
        }
      );
    };

    watch(availableToday, () => {
      if (stores.value.length > 0 && stores.value.length < 5) setStores();
      if (selectedStore.value)
        isProductAvailableInStore.value = checkProductAvailable(
          selectedStore.value
        );
    });

    const getVariantId = () => {
      if (
        selectedStore.value &&
        !attributesNotValid.value &&
        product.value?.variant
      ) {
        for (const attrKey in product.value.variant.attributes) {
          if (
            product.value.variant.attributes[attrKey] !==
            product.value[attrKey]?.value
          )
            return;
        }
        return product.value.variant.id;
      }
    };

    const availabilityCheck = async (productId?: string) => {
      if (productId) {
        try {
          const availabilityResponse = await getProductAvailabilities(
            [selectedStore.value],
            productId
          );
          const quantity =
            availabilityResponse.data.storeInventory?.[0].quantity || 0;
          selectedStore.value.has_product = quantity > 0;
          selectedStore.value.quantity = quantity;
          isProductAvailableInStore.value = checkProductAvailable(
            selectedStore.value
          );
        } catch (err) {
          isProductAvailableInStore.value = false;
          root.$log.error(
            `[[@theme/components/static/pdp/ShippingDestinations::availabilityCheck]: Failed getProductAvailabilities of productId: ${productId}`,
            err
          );
        }
      }
    };

    let unwatchAvailabilityCheck = () => null;

    onAllDone(() => {
      unwatchAvailabilityCheck = watch(
        [attributesNotValid, () => product.value?.variant?.id],
        () => {
          resetErrorMessage();
          resetData();
          setStores();

          const variantId = getVariantId();
          if (!variantId) {
            return;
          }

          availabilityCheck(variantId);
          if (showSearchForm.value) {
            search.value = search.value || selectedStore.value?.postalcode;
            searchByPC();
          }
        },
        {
          immediate: true,
        }
      );
    });

    onBeforeUnmount(() => {
      unwatchAvailabilityCheck();
    });

    const apiValidator = () => searchApiErrorMessage.value === '';

    return {
      apiNoStores,
      apiValidator,
      searchApiErrorMessage,
      attributesNotValid,
      searchByPC,
      selectShipInStore,
      changeStore,
      setStore,
      cart,
      isCoreRedesignEnabled,
      isProductAvailableInStore,
      storeDetailsIndex,
      requestPendingFlag,
      availableToday,
      qtyThreshold,
      stores,
      storeId,
      selectedStore,
      setValidation,
      search,
      showSearchForm,
      PdpShippingMethod,
    };
  },
  mounted() {
    this.setValidation(this.$v);
  },
  validations() {
    return {
      search: {
        required,
        alphaNumWithSpace: helpers.regex(
          'alphaNumWithSpace',
          /^[a-zA-Z0-9\s\-]*$/
        ),
        apiValidator: this.apiValidator,
      },
    };
  },
});

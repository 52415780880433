































































































import type { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import { useCms } from '@vf/composables';
import type { SiteSelectorTranslations } from '@vf/api-contract';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'SiteSelector',
  mixins: [validationMixin],
  validations: {
    deliveryLocation: {
      required,
    },
  },
  props: {
    logo: {
      type: [String, Object],
      default: '',
    },
    locales: {
      type: Array,
      default: () => [],
    },
    translations: {
      type: Object as PropType<SiteSelectorTranslations>,
      default: () => ({
        heading: 'Welcome',
        deliveryLocationLabel: 'Please select your delivery location',
        deliveryLocationPlaceHolder: 'Select Location',
        languageSelectorLabel: 'Select a language',
        buttonText: 'Go',
        helperText:
          'Please note that by changing location all items in your shopping cart will be removed',
        currencyText: 'Currency',
        languageSelectorPlaceHolder: 'Please select language',
        languageSelectedLabel: 'Language',
        locationSelectionRequired: 'Location is required',
      }),
    },
  },
  setup(props: any) {
    const { root } = useRootInstance();
    return {
      cms: useCms(root, props.contextKey),
    };
  },
  data() {
    return {
      deliveryLocation: null,
      locale: null,
      selectedLocaleInfo: null,
    };
  },
  computed: {
    selectedLocale() {
      return this.$root.$i18n.locale;
    },
    languages() {
      const selectedLocation = this.deliveryLocation
        ? this.locales.find((l) => l.displayName === this.deliveryLocation)
        : [];

      return selectedLocation.locales || [];
    },
  },
  watch: {
    locale() {
      const countryIdx = this.locales.findIndex((site) => {
        if (site.locales.find((l) => l.locale === this.locale)) return true;
      });

      const selectedSiteObj = this.locales[countryIdx]
        ? this.locales[countryIdx]
        : null;

      const selectedLocaleObj = selectedSiteObj
        ? selectedSiteObj.locales.find(
            (l) => !!this.locale && l.locale === this.locale
          )
        : null;

      this.selectedLocaleInfo = selectedLocaleObj ? selectedLocaleObj : null;
    },
  },
  methods: {
    changeDeliveryLocation(value) {
      this.deliveryLocation = value;

      if (this.languages && this.languages.length === 1) {
        this.locale = this.languages[0].locale;
      } else {
        this.locale = null;
      }
    },
    changeLocale(value) {
      this.locale = value;
    },
    async goToLocale() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      if (this.locale === this.selectedLocale) {
        history.length > 2
          ? window.history.back()
          : window.location.replace(`/${this.selectedLocale}`);
        return;
      }

      const localeToGo = this.locale ?? this.languages.slice().shift()?.locale;
      if (localeToGo) {
        await this.cms.setup({ forcePreview: this.$root.$isPreview });
        this.cms.changeLocale(localeToGo);
        this.$root.$i18n.configureApiInternalization();
      }
    },
  },
});

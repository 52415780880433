import { DirectiveBinding } from 'vue/types/options';
import { Element } from './../types';

const setVisibilityState = (el: Element, value: boolean) => {
  el.style.visibility = value ? 'visible' : 'hidden';
};

export const visibility = {
  bind(el: Element, binding: DirectiveBinding): void {
    setVisibilityState(el, !!binding.value);
  },

  update(el: Element, binding: DirectiveBinding): void {
    setVisibilityState(el, !!binding.value);
  },
};

















































































































import type { PropType } from 'vue';
import { storeToRefs } from 'pinia';
import { computed, defineComponent } from '@vue/composition-api';
import { CheckoutContext } from '@vf/api-contract';
import { useRewardCycleTranslations } from '@vf/composables';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import { useDiscountStore } from '@vf/composables/src/store/discountStore';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';
import useRootInstance from '@/shared/useRootInstance';
import { isClient } from '@vf/shared/src/utils/helpers';

const useSidebarContext = (context: CheckoutContext) => {
  if (context === CheckoutContext.Success) {
    const {
      order,
      orderShipments,
      orderPickups,
      giftCards,
      rewards,
      appliedPromotions,
      appliedFamilyVoucher,
      athleteDiscounts,
    } = storeToRefs(useCheckoutStore());

    const mapper = ({ shippingMethod, ...shipment }) => {
      return {
        shipmentId: shipment.shipmentId,
        code: shippingMethod.code,
        label: shippingMethod.label,
        cost: shippingMethod.price.amount,
        currency: shippingMethod.price.currency,
        address: shipment.shippingAddress,
        shippingPromotions: shipment.shippingPromotions,
        methods: [],
      };
    };

    return {
      cartShipments: computed(() => orderShipments.value.map(mapper)),
      cartPickups: computed(() => orderPickups.value.map(mapper)),
      currency: computed(() => order.value.totals.currency),
      totals: computed(() => order.value.totals),
      totalItems: computed(() =>
        order.value.items.reduce((acc, item) => acc + item.qty, 0)
      ),
      totalBuyInStoreFixedPrices: computed(() => 0), // TODO: GLOBAL15-58540 missing mapping
      appliedPromotions,
      giftCards,
      rewards,
      appliedFamilyVoucher, // TODO: GLOBAL15-65058 rename
      athleteDiscounts,
      province: computed(
        () => orderShipments.value[0]?.shippingAddress?.province
      ),
    };
  }

  const {
    cartShipments,
    cartPickups,
    currency,
    totals,
    totalItems,
    athleteDiscounts,
    shippingAddress,
  } = storeToRefs(useCartStore());
  const {
    totalBuyInStoreFixedPrices,
    giftCards,
    rewardCards,
    appliedPromotions,
    appliedFamilyVoucher,
  } = storeToRefs(useDiscountStore());

  return {
    cartShipments,
    cartPickups,
    currency,
    totals,
    totalItems,
    totalBuyInStoreFixedPrices,
    athleteDiscounts,
    giftCards,
    rewards: rewardCards,
    appliedPromotions,
    appliedFamilyVoucher,
    province: computed(() => shippingAddress.value?.province),
  };
};

export default defineComponent({
  name: 'OrderSummarySidebar',
  props: {
    currentStep: {
      type: String as PropType<CheckoutContext>,
      default: CheckoutContext.Cart,
    },
    showDivider: Boolean,
  },
  setup(props) {
    const { root } = useRootInstance();

    const {
      cartShipments,
      cartPickups,
      currency,
      totals,
      totalItems,
      totalBuyInStoreFixedPrices,
      athleteDiscounts,
      giftCards,
      rewards,
      appliedPromotions,
      appliedFamilyVoucher,
      province,
    } = useSidebarContext(props.currentStep);

    const sidebarType = computed<'cart' | 'checkout' | 'success'>(() => {
      const context = {
        [CheckoutContext.Cart]: 'cart',
        [CheckoutContext.Success]: 'success',
      };
      return context[props.currentStep] ?? 'checkout';
    });

    const t = (key) =>
      root.$t(`orderSummarySidebar.${sidebarType.value}.${key}`) as string;

    const { getRewardCycleText } = useRewardCycleTranslations({
      summer: t('issuedSummer'),
      winter: t('issuedWinter'),
      spring: t('issuedSpring'),
    });

    const itemSubtotalText = computed(() => {
      if (props.currentStep === CheckoutContext.Cart) {
        return t('itemSubtotal');
      }
      return `${t('itemSubtotal')} (${totalItems.value})`;
    });

    const mapShippingPromotion = (shipment) =>
      (shipment.shippingPromotions ?? [])
        .filter((promo) => promo.appliedDiscount)
        .map(({ itemText, price }) => ({
          itemText,
          price: Math.abs(price),
        }));

    const calculateTotalShippingDiscount = (shippingPromotions) =>
      shippingPromotions.reduce((acc, { price }) => (acc += price), 0);

    const prepareShippingPromotionsForDisplay = (shippingPromotions) =>
      shippingPromotions.map((promo) => ({
        label: t('shippingPromotionLabel') + promo.itemText,
        value: `-${root.$formatPrice(promo.price, currency.value)}`,
      }));

    const deliveryOptions = computed(() => {
      if (props.currentStep === CheckoutContext.Cart) {
        const shippingPromotions = cartShipments.value.flatMap(
          mapShippingPromotion
        );

        const totalShippingDiscount = calculateTotalShippingDiscount(
          shippingPromotions
        );

        const adjustedShippingPrice =
          totals.value.shipping - (totals.value.enforcedShippingFee || 0);

        const totalShippingCost = adjustedShippingPrice + totalShippingDiscount;

        return [
          {
            label: t('shipping'),
            value:
              totalShippingCost > 0
                ? root.$formatPrice(totalShippingCost, currency.value)
                : t('free'),
            tooltip: t('shippingTooltip'),
          },
          ...prepareShippingPromotionsForDisplay(shippingPromotions),
        ];
      }

      const deliveries = cartShipments.value.flatMap((shipment) => {
        const shippingPromotions = mapShippingPromotion(shipment);

        const totalShippingDiscount = calculateTotalShippingDiscount(
          shippingPromotions
        );

        const totalShippingCost = shipment.cost + totalShippingDiscount;

        const shippingCost =
          totalShippingCost > 0
            ? root.$formatPrice(totalShippingCost, shipment.currency)
            : t('free');

        const { deliveryTime } =
          shipment.methods?.find((method) => method.code === shipment.code) ??
          {};

        const deliveryDateLabel = isClient
          ? root.$getEDDLabel(root, deliveryTime)
          : deliveryTime;

        const isCustoms = shipment.code.includes('customs');
        const infoPrefix = isCustoms ? t('customs') : '';

        const infoLabel =
          sidebarType.value === 'success'
            ? shipment.label
            : deliveryDateLabel
            ? `${shipment.label} (${deliveryDateLabel})`
            : '';

        return [
          {
            label: t('shipping'),
            value: shippingCost,
            info: infoPrefix + infoLabel,
            tooltip: isCustoms ? '' : t('shippingTooltip'),
          },
          ...prepareShippingPromotionsForDisplay(shippingPromotions),
        ];
      });

      const pickups = cartPickups.value
        .map(() => {
          return {
            label: t('pickup'),
            value: t('free'),
            // tooltip: t('pickupTooltip'),
          };
        })
        .slice(0, 1);

      return [...deliveries, ...pickups];
    });

    const enforcedShippingFee = computed(() => {
      const { enforcedShippingFee } = totals.value;
      if (!enforcedShippingFee) return null;
      const prefix = `enforcedShippingFee.${province.value}`;
      return {
        label: t(`${prefix}.label`),
        value: root.$formatPrice(enforcedShippingFee, currency.value),
        info: t(`${prefix}.description`),
        tooltip: t(`${prefix}.tooltip`),
      };
    });

    const taxText = computed(() => {
      const taxContextList = [CheckoutContext.Cart, CheckoutContext.Shipping];
      if (taxContextList.includes(props.currentStep)) {
        return t('estimatedTax');
      }
      return t('tax');
    });

    const appliedPromotionsFormatted = computed(() => {
      if (props.currentStep === CheckoutContext.Cart) {
        return appliedPromotions.value.map((promo) => {
          return {
            ...promo,
            name: t('promotionLabel') + (promo.itemText || promo.description),
            price: `-${root.$formatPrice(promo.price, currency.value)}`,
          };
        });
      } else {
        const descriptions = appliedPromotions.value
          .map(({ description }) => description)
          .join('\n');
        return descriptions.length > 0
          ? [
              {
                id: 'joined',
                name: t('promotionLabel'),
                info: descriptions,
                price: `-${root.$formatPrice(
                  Math.abs(totals.value.totalDiscount),
                  currency.value
                )}`,
              },
            ]
          : [];
      }
    });

    const showRemainingToPay = computed(() => {
      const appliedInstruments = [
        rewards.value.length > 0,
        giftCards.value.length > 0,
        !!appliedFamilyVoucher.value,
        athleteDiscounts.value.length > 0,
      ];
      return appliedInstruments.some(Boolean);
    });

    return {
      appliedFamilyVoucher,
      itemSubtotalText,
      deliveryOptions,
      totalBuyInStoreFixedPrices,
      athleteDiscounts,
      enforcedShippingFee,
      taxText,
      totals,
      showRemainingToPay,
      currency,
      giftCards,
      rewards,
      appliedPromotionsFormatted,
      sidebarType,
      getRewardCycleText,
      t,
    };
  },
});











































































import type { PropType } from 'vue';
import type { AddressFormContext, AddressModel } from './addressModel';
import { addressModel as defaultAddressModel } from './addressModel';
import type { CartAddressRequest } from '@vf/api-contract';

import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api';
import { apiClientFactory } from '@vf/api-client';
import { useAccount, useCart, useRequestTracker } from '@vf/composables';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import { useUserStore } from '@vf/composables/src/store/user';
import { useAddressVerificationService } from '@vf/composables/src/useAddressVerificationService';
import { scrollToFirstError } from '@vf/shared/src/utils/helpers';
import debounce from '@vf/shared/src/utils/helpers/debounce';
import useRootInstance from '@/shared/useRootInstance';
import useLoader from '@/shared/useLoader';

import AddressForm from './AddressForm.vue';
import AddressPreview from '@/components/static/AddressPreview.vue';
import AddressConfirmationModal from './AddressConfirmationModal.vue';
import AddressSuggestionModal from './AddressSuggestionModal.vue';

export default defineComponent({
  components: {
    AddressForm,
    AddressPreview,
    AddressConfirmationModal,
    AddressSuggestionModal,
  },
  props: {
    contextName: {
      type: String as PropType<AddressFormContext>,
      default: 'shippingForm',
    },
    value: Object as PropType<AddressModel>,
  },
  setup(props) {
    const { root } = useRootInstance();
    const userStore = useUserStore(root);
    const { addAddress, updateAddress, getAddresses } = useAccount(root);
    const cartStore = useCartStore();
    const {
      setShippingAddress: setShippingAddressAPI,
      setBillingAddress: setBillingAddressAPI,
    } = apiClientFactory(root);
    const { updateCart, cartId } = useCart(root);
    const {
      verifyAddress: avsVerifyAddress,
      isRejected: avsIsRejected,
      originalAddress: avsOriginalAddress,
      suggestedAddress: avsSuggestedAddress,
    } = useAddressVerificationService(root);
    const { showSpinner, hideSpinner } = useLoader();
    const { trackRequest, clearRequest } = useRequestTracker(root);

    // the form component reference
    const addressFormRef = ref();

    const hasShippingContext = computed(
      () => props.contextName === 'shippingForm'
    );

    // the type of addresses that will populate the address list: shippingForm or billingForm
    const approachType = computed(() => (hasShippingContext.value ? 'S' : 'B'));

    // the list of addresses that are stored in the user account, if registered
    const storedAddresses = computed(() =>
      userStore.loggedIn
        ? (getAddresses(approachType.value).value as AddressModel[])
        : []
    );

    // the reference to the new address. This value is added to the end of the address list
    // it's different than the model (below the Address Selector) as:
    // - object formats are slightly different
    // - newAddress is validated and it's main purpose it's to display the new address in the address selector and address display
    // - model may be not yet validated and is used only on the form
    // once validated, the model is copied to the newAddress
    const newAddress = ref({ ...defaultAddressModel, id: null });

    // among the list of addresses, the main one or the first one should be selected by default
    const mainAddressId = computed(() => {
      return (
        (
          storedAddresses.value.find((address) => address.main) ||
          storedAddresses.value[0]
        )?.id || null
      );
    });

    // the list of addresses that will be displayed in the address selector, including both stored and the new address
    const addressList = computed(() =>
      // TODO: GLOBAL15-61134 - skip billing addresses - isBillingAddressValidForKlarna(billingAddress.value) - GLOBAL15-35783 - klarna limitations
      [storedAddresses.value, newAddress.value].flat()
    );

    const selectedAddressId = ref(null);
    const selectedAddress = computed(
      (): AddressModel =>
        addressList.value.find(
          (address) => address.id === selectedAddressId.value
        ) || newAddress.value
    );

    const showAddressFormSelector = computed(
      () => !!storedAddresses.value.length
    );

    const newAddressText = computed(() =>
      hasShippingContext.value
        ? root.$t('checkoutAddress.addNewShippingAddress')
        : root.$t('checkoutAddress.addNewBillingAddress')
    );

    const addressSelectorLabel = computed(() =>
      props.contextName === 'shippingForm'
        ? root.$t('checkoutAddress.defaultAddressLabel')
        : root.$t('checkoutAddress.billingAddress')
    );

    const handleAddressSelectorChange = (value) => {
      setEditMode(value === 'new');
    };

    const addNewAddress = () => {
      // Should we reset the form? or keep any info the user already introduced?
      setEditMode(true);
      selectedAddressId.value = 'new';
    };

    // the address object that is passed to the form
    const model = ref();
    // Edit mode toggles between the address form and the address selector and display
    const isEditMode = ref(false);
    const setEditMode = (value: boolean) => {
      model.value = selectedAddress.value;
      isEditMode.value = value;
    };

    // the shipping / billing address stored in the cart
    const cartAddress = computed(() =>
      hasShippingContext.value
        ? cartStore.shippingAddress
        : cartStore.billingAddress
    );

    // modals setup
    const showAddressConfirmationModal = ref(false);
    const showAddressSuggestionModal = ref(false);
    const resolveModal = ref(null); // callback passed to the modal to resolve the promise

    const initState = () => {
      // try to match the cart address with the stored addresses
      // if there's a match, make that one the selected address
      if (
        addressList.value.find(({ id }) => id === cartAddress.value.addressId)
      ) {
        selectedAddressId.value = cartAddress.value.addressId;
      } else {
        // there's no match so it can be:
        // user is guest, we should show the address form
        // user is registered, we should pick the default address from it's address book, or show the address form
        // user (guest or registered) already filled the form, didn't save it to the address book and now it's editing again

        // rule out if the user is returning after adding a new address
        // since addressId will be nulish in any case, we can check if the address is empty
        if (cartAddress.value?.addressLine1) {
          newAddress.value = {
            ...cartAddress.value,
            id: null,
            addressId: cartAddress.value.addressId || null,
            emailSubscription: !!cartAddress.value.subscriptions
              ?.newsletterConsent,
            main: false,
            saveAddress: false,
          };
          selectedAddressId.value = 'new'; // VF select hack, it does not play well with nulish values
        } else {
          // if the user has stored addresses, pick the main one
          if (mainAddressId.value) {
            selectedAddressId.value = mainAddressId.value;
          } else {
            // user is either guest or registered but has no addresses (ie: just registered)
            newAddress.value = { ...defaultAddressModel, id: null };
            selectedAddressId.value = 'new'; // VF select hack, it does not play well with nulish values
          }
        }
      }

      // Check if we should show the address form by default
      // We show the form if the address is not saved to the address book
      if (selectedAddressId.value === 'new') setEditMode(true);
    };

    onMounted(() => {
      initState();
      if (hasShippingContext.value && model.value?.postalCode) {
        // this will trigger presourcing in Mule to make sure shipping methods are accurate
        updateCartAddress(mapLocalAddressToApi(model.value));
      }
    });

    const updateCartAddress = async (address: CartAddressRequest) => {
      if (cartId.value) {
        const { tag } = trackRequest('checkout:set-checkout-address');
        try {
          const response = await (hasShippingContext.value
            ? setShippingAddressAPI(cartId.value, [address])
            : setBillingAddressAPI(cartId.value, {
                ...address,
                addressId: address.addressId || address.id,
              }));

          if (response.status === 200) {
            await updateCart(response.data, true);
          }
        } catch (err) {
          root.$log.error(
            '[@theme/components/static/addressBook/CheckoutAddress::updateCartAddress]',
            err.message
          );
          return false;
        } finally {
          clearRequest(tag);
        }
      }
    };

    const mapLocalAddressToApi = (data): CartAddressRequest => {
      return {
        ...data,
        subscriptions: { newsletterConsent: !!data.emailSubscription },
        saveAddress: undefined,
        emailSubscription: !userStore.loggedIn
          ? !!data.emailSubscription
          : false,
        main: !!data.main,
      };
    };

    const hasAnyFieldChanged = (address: AddressModel, fields = []) =>
      fields.some((field) => model.value?.[field] !== address[field]);

    // This is needed to prevent multiple updates when using browser's autocomplete
    const debounceUpdateCartAddress = debounce(
      async (address: CartAddressRequest) => await updateCartAddress(address),
      100
    );

    const handleAddressFormChange = async (data: [AddressModel, string]) => {
      const [address] = data;

      if (
        hasShippingContext.value &&
        hasAnyFieldChanged(address, [
          'postalCode',
          'province',
          'addressLine1',
          'addressLine2',
        ])
      ) {
        // fetch updated shipping methods
        debounceUpdateCartAddress(mapLocalAddressToApi(address));
      }

      model.value = address;
    };

    const handleAddressAutocomplete = (address: AddressModel) =>
      handleAddressFormChange([address, 'postalCode']);

    // connect to the address verification service to validate the address
    // it may reject the address, in that case the user must confirm it manually or change it.
    // it also may return a modified address, in that case user must choose to accept it or use the one he provided
    // @returns:
    // - null: if the user cancels the operation (rejects the address or the suggestion)
    // - the address to be used, either the original one or the suggested one
    const verifyAddress = async (
      address: CartAddressRequest
    ): Promise<CartAddressRequest | null> => {
      // skip verification for billing address
      if (!hasShippingContext.value) return address;

      // for shipping addresses, run the verification service
      await avsVerifyAddress(address, cartId.value);

      // API rejected address
      if (avsIsRejected.value) {
        hideSpinner();
        showAddressConfirmationModal.value = true;

        const confirmed = await new Promise((resolve) => {
          resolveModal.value = resolve;
        });

        showAddressConfirmationModal.value = false;
        confirmed && showSpinner();
        return confirmed ? address : null;
      }

      // API suggest a different address
      if (avsSuggestedAddress.value) {
        hideSpinner();
        showAddressSuggestionModal.value = true;

        const confirmed = await new Promise((resolve) => {
          resolveModal.value = resolve;
        });

        showAddressSuggestionModal.value = false;
        if (confirmed === false) return null; // user rejected the suggested address

        showSpinner();
        return confirmed === 'original'
          ? address // user keeps the original address
          : { ...address, ...avsSuggestedAddress.value }; // user accepted the suggested changes
      }

      // API accepted the address
      return address;
    };

    const submit = async () => {
      // if not in edit mode, we don't need to validate the form locally
      // but still need to be sure the address is saved to the cart (ie: address was picked from the selector)
      // and verified server side
      if (!isEditMode.value) {
        // the address object we are going to send to the API
        let apiAddress = mapLocalAddressToApi(selectedAddress.value);
        apiAddress.addressId =
          selectedAddress.value.addressId || selectedAddress.value.id;
        apiAddress.approachType = approachType.value;

        // address verification
        apiAddress = await verifyAddress(apiAddress);
        if (!apiAddress) return false;

        await updateCartAddress(apiAddress);
        return true;
      }

      // if in edit mode, we need to validate the form first
      // and save the information if necessary
      if (!addressFormRef.value.validate()) {
        scrollToFirstError();
        return false;
      }

      // the address object we are going to send to the API
      let apiAddress = mapLocalAddressToApi(model.value);
      apiAddress.approachType = approachType.value;
      // address verification
      apiAddress = await verifyAddress(apiAddress);
      if (!apiAddress) return false;

      try {
        // SAVE TO ADDRESS BOOK
        if (model.value.saveAddress) {
          const response = await addAddress(apiAddress);
          selectedAddress.value.addressId = response.data.addressId;
          apiAddress.addressId = response.data.addressId;
          newAddress.value = { ...defaultAddressModel, id: null };
        } else if (model.value.id) {
          await updateAddress(model.value.id, apiAddress);
          apiAddress.addressId = model.value.id;
        } else {
          // if we are not saving the address, should be treated as the "New Address" option, no matter we are editing a stored address
          // but only if the address has changes
          if (addressFormRef.value.hasChanges.value)
            model.value.addressId = null;
        }
      } catch (err) {
        root.$log.error(
          '[@components/static/addressBook/CheckoutAddress::submit]',
          err.message
        );
        return false;
      }

      // - if the user created a new address and saved it, the addressId changed and needs to be stored in the cart
      await updateCartAddress(apiAddress);

      return true;
    };

    watch(selectedAddress, async (newAddress, oldAddress) => {
      if (
        oldAddress.postalCode &&
        newAddress.postalCode !== oldAddress.postalCode &&
        selectedAddressId.value !== 'new'
      ) {
        // update address only if postal code change to get up to date EDDs
        await updateCartAddress(mapLocalAddressToApi(newAddress));
      }
    });

    watch(cartAddress, (address) => {
      if (addressList.value.find(({ id }) => id === address.addressId))
        selectedAddressId.value = address.addressId;
    });

    return {
      addNewAddress,
      addressFormRef,
      addressList,
      handleAddressFormChange,
      handleAddressAutocomplete,
      handleAddressSelectorChange,
      hasShippingContext,
      newAddressText,
      isEditMode,
      model,
      newAddress,
      selectedAddress,
      selectedAddressId,
      setEditMode,
      showAddressFormSelector,
      submit,
      cartAddress,
      showAddressConfirmationModal,
      showAddressSuggestionModal,
      resolveModal,
      avsSuggestedAddress,
      avsOriginalAddress,
      addressSelectorLabel,
    };
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'])}},[_c('span',{staticClass:"sr-only",attrs:{"id":"pr-container","role":"region","tabindex":"-1"}},[_vm._v("\n    "+_vm._s(_vm.hasReviews ? _vm.translations.heading : _vm.translations.writeFirstReview)+"\n  ")]),_vm._v(" "),(_vm.isLoading)?_c('div'):(_vm.hasReviews)?_c('VfReviews',{attrs:{"translations":_vm.translations,"review":_vm.productReview,"reduced-list-count":_vm.reducedListCount},on:{"click:add-filter":function (value) {
        _vm.addFilter(value);
        _vm.changePage(1);
        _vm.getReviews();
      },"click:remove-filter":function (value) {
        _vm.removeFilter(value);
        _vm.changePage(1);
        _vm.getReviews();
      },"change:sorting":function (value) {
        _vm.setSorting(value);
        _vm.changePage(1);
        _vm.getReviews();
      },"flag-review-submit":_vm.handleFlagReviewSubmit,"click:previous-page":function () {
        _vm.changePage(_vm.paging.currentPageNumber - 1);
        _vm.getReviews(_vm.isScrollToReviewsWhenPaging);
      },"click:next-page":function () {
        _vm.changePage(_vm.paging.currentPageNumber + 1);
        _vm.getReviews(_vm.isScrollToReviewsWhenPaging);
      },"click:helpful-thumb-up":function (value) { return _vm.addReviewVote(
          value.reviewId,
          _vm.ProductReviewVoteType.Helpful,
          value.mediaId
        ); },"click:helpful-thumb-down":function (value) { return _vm.addReviewVote(
          value.reviewId,
          _vm.ProductReviewVoteType.NotHelpful,
          value.mediaId
        ); },"click:write-review":_vm.handleWriteReview}}):_c('div',{staticClass:"vf-first-review"},[_c(_vm.useWriteAReviewButton ? 'ThemeButton' : 'ThemeLink',{tag:"component",attrs:{"aria-label":_vm.translations.writeFirstReview,"size":"md","color":_vm.useWriteAReviewButton || _vm.isCoreRedesignEnabled ? 'primary' : '',"variant":_vm.useWriteAReviewButton ? 'border' : '',"legacy":false},on:{"click":_vm.handleWriteReview}},[_vm._v("\n      "+_vm._s(_vm.translations.writeFirstReview)+"\n    ")])],1),_vm._v(" "),(!_vm.isWriteReviewPage)?_c('WriteReviewModal',{attrs:{"visible":_vm.isModalVisable,"page-id":_vm.productId,"page-id-variant":_vm.variantId},on:{"close":_vm.onModalClose}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }



































































































import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api';
import { useGiftCards, useNotification, useReCaptcha } from '@vf/composables';
import { PaymentMethodCode } from '@vf/api-client';
import useRootInstance from '@/shared/useRootInstance';
import { storeToRefs } from 'pinia';

import { VueMaskDirective } from 'v-mask';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import {
  getGiftCardFormValidationPatterns,
  maskGiftCardNumber,
} from '@vf/composables/src/utils/giftCard';
import useValidators from '@vf/composables/src/useValidators';
import {
  validateRegex,
  validateRequired,
} from '@vf/composables/src/useValidators/validators';
import { useCartStore } from '@vf/composables/src/store/cartStore';

export default defineComponent({
  name: 'CheckoutGiftCard',
  serverCacheKey: getCacheKeyFromProps,
  directives: { mask: VueMaskDirective },
  setup() {
    const { root } = useRootInstance();
    const cartStore = useCartStore();
    const recaptcha = useReCaptcha(root);
    const { appliedGiftCards } = storeToRefs(cartStore);

    const { applyGiftCard, removeGiftCard, canAddMoreCards } = useGiftCards(
      root
    );
    const { addNotification, clearNotifications } = useNotification(root);

    const state = reactive({
      cardNumber: null,
      pin: null,
    });

    const showPinTooltip = ref(false);

    const showGiftCardForm = ref(true);

    const giftCardValidation = getGiftCardFormValidationPatterns(
      root.$getEnvValueByCurrentLocale<{
        cardNumberLengths: number[];
        pinLengths: number[];
      }>(PaymentMethodCode.GIFT_CARD)
    );

    const { validate, validationFields } = useValidators(
      state,
      ref({
        cardNumber: [
          validateRequired(
            root.$t('giftCard.validation_messages.required') as string
          ),
          validateRegex(
            new RegExp(giftCardValidation.cardNumber),
            root.$t('giftCard.validation_messages.digitsLength') as string
          ),
        ],
        pin: [
          validateRequired(
            root.$t('giftCard.validation_messages.required') as string
          ),
          validateRegex(
            new RegExp(giftCardValidation.pin),
            root.$t('giftCard.validation_messages.pinLength') as string
          ),
        ],
      })
    );

    onMounted(() => {
      recaptcha.showBadge();
      watch(
        () => appliedGiftCards.value.length,
        () => {
          showGiftCardForm.value = !appliedGiftCards.value.length;
          state.cardNumber = null;
          state.pin = null;
        }
      );
    });
    onUnmounted(() => recaptcha.hideBadge());

    const isValid = computed(
      () =>
        state.cardNumber &&
        state.pin &&
        validationFields.value.cardNumber.valid &&
        validationFields.value.pin.valid
    );

    const onApplyGiftCard = async () => {
      clearNotifications();
      const payload = {
        ...state,
        recaptchaToken: await recaptcha.executeRecaptcha('giftCard'),
      };
      await applyGiftCard(payload);
    };

    const onRemoveGiftCard = async (paymentId: string, method: string) => {
      const response = await removeGiftCard({ paymentId, method });
      if ([200, 201].includes(response?.status)) {
        clearNotifications();
        addNotification({
          message: root.$t('giftCard.removeSuccessMessage') as string,
          type: 'success',
        });
      }
    };

    return {
      ...toRefs(state),
      isValid,
      canAddMoreCards,
      appliedGiftCards,
      showPinTooltip,
      showGiftCardForm,
      validationFields,
      validate,
      onApplyGiftCard,
      onRemoveGiftCard,
      maskGiftCardNumber,
    };
  },
});

import { render, staticRenderFns } from "./Molecule.Histogram.vue?vue&type=template&id=2f9cffbc&scoped=true&"
import script from "./Molecule.Histogram.vue?vue&type=script&lang=js&"
export * from "./Molecule.Histogram.vue?vue&type=script&lang=js&"
import style0 from "./Molecule.Histogram.vue?vue&type=style&index=0&id=2f9cffbc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f9cffbc",
  null
  
)

export default component.exports
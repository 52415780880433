





























































































































import {
  ref,
  Ref,
  computed,
  ComputedRef,
  PropType,
  defineComponent,
} from '@vue/composition-api';
import { ParsedProductReviewUgcItem, VideoObject } from '@vf/api-contract';
import VfFlagReview from './Organism.FlagReview.vue';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'VfReviewUgc',
  components: {
    VfFlagReview,
  },
  serverCacheKey: getCacheKeyFromProps,
  props: {
    rating: {
      type: Number as PropType<number>,
      default: 0,
    },
    reviewTitle: {
      type: String as PropType<string>,
      default: '',
    },
    media: {
      type: Array as PropType<Array<ParsedProductReviewUgcItem>>,
      default: () => [],
    },
    translations: {
      type: Object,
      default: () => ({
        flagReviewNotFlagged: 'Flag this review',
        flagReviewFlagged: 'You flagged this review',
        flagUGCFlagged: 'Flagged',
        flagUGCNotFlagged: 'Flag',
        heading: 'What’s the issue?',
        emailPlaceholder: 'Email Address',
        emailRequiredText: 'Email address is required',
        emailInvalidText: 'Please enter a valid email address',
        commentsPlaceholder: 'Comments',
        flagButtonLabel: 'Flag',
        cancelButtonLabel: 'Cancel',
        flagTypes: {
          profane: 'Profanity',
          wrong_product: 'Wrong Product',
          spam: 'Spam',
          duplicate: 'Duplicate',
          copyright: 'Copyright Violation',
          not_review: 'Not a Product Review',
          customer_image: 'Customer Image',
          other: 'Other',
        },
      }),
    },
  },
  emits: [
    'click:helpful-thumb-up',
    'click:helpful-thumb-down',
    'flag-review-submit',
  ],
  setup(props, { root }) {
    const overlaySettings = {
      small: true,
      medium: true,
      large: true,
    };
    const contentPosition = {
      small: 'bottom_center',
      medium: 'bottom_center',
      large: 'bottom_center',
    };
    const isModalOpen: Ref<boolean> = ref(false);
    const activeMediaIndex: Ref<number> = ref(0);
    const activeMediaItem: ComputedRef<ParsedProductReviewUgcItem> = computed(
      () => props.media?.[activeMediaIndex.value]
    );

    const hasOverlayVisible = computed(() => {
      return overlaySettings[root.$viewport?.size || 'small'];
    });

    const openModal = (ugcIndex: number): void => {
      activeMediaIndex.value = ugcIndex;
      isModalOpen.value = true;
    };

    const onModalClose = (): void => {
      isModalOpen.value = false;
      activeMediaIndex.value = 0;
    };

    const showPreviousItem = (): void => {
      if (activeMediaIndex.value === 0) {
        activeMediaIndex.value = props.media.length - 1;
        return;
      }
      activeMediaIndex.value--;
    };

    const showNextItem = (): void => {
      if (activeMediaIndex.value === props.media.length - 1) {
        activeMediaIndex.value = 0;
        return;
      }
      activeMediaIndex.value++;
    };

    const getVideoObject = (
      ugcItem: ParsedProductReviewUgcItem,
      isThumbnail = false
    ): { small: VideoObject } => {
      return {
        small: {
          sources: [
            {
              src: ugcItem.uri,
              type: `video/${ugcItem.uri.split('.').pop()}`,
            },
          ],
          seo: {
            title: ugcItem.caption,
            date: '',
            description: '',
          },
          poster: '',
          tracks: [],
          options: {
            hideControls: isThumbnail,
            autoplay: !isThumbnail,
            preload: 'metadata',
            loop: false,
            muted: true,
            showPlayButton: !isThumbnail,
            showSubtitles: false,
          },
        },
      };
    };

    return {
      isModalOpen,
      activeMediaIndex,
      activeMediaItem,
      openModal,
      onModalClose,
      showPreviousItem,
      showNextItem,
      overlaySettings,
      hasOverlayVisible,
      contentPosition,
      getVideoObject,
    };
  },
});

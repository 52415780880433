





import type { PropType } from 'vue';
import type { PaymentInstruments } from '@vf/api-client';
import type { Address } from '@vf/api-client/src/types';
import type { PutOrderObject } from '@vf/api/src/types/Checkout';

import { onMounted, ref } from '@vue/composition-api';
import { defineComponent } from '@nuxtjs/composition-api';
import { apiClientFactory, PaymentMethodCode } from '@vf/api-client';
import { CheckoutContext } from '@vf/api-contract';
import { ROUTES, useI18n, useUtilities } from '@vf/composables';
import { useCart, useReCaptcha } from '@vf/composables/src';
import { useOrders } from '@vf/composables/src/useCheckout/composables/useOrders';
import { errorMessages } from '@vf/composables/src/utils/errorMessages';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import useRootInstance from '@/shared/useRootInstance';
import useLoader from '@/shared/useLoader';

interface PaypalAddress {
  firstName: string | null;
  lastName: string | null;
  shopperEmail: string | null;
  country: string | null;
  street: string | null;
  state: string | null;
  postalCode: string | null;
  stateOrProvince: string | null;
  city: string | null;
  province: string | null;
  telephoneNumber: string | null;
  stateCode: string | null;
  countryCode: string | null;
}

type PaypalAdditionalDetailsState = {
  data: {
    details: {
      orderID: string;
      payerID: string;
      paymentID: string;
      billingToken: null;
      facilitatorAccessToken: string;
      paymentSource: 'paypal';
    };
    paymentData: string;
  };
};

export default defineComponent({
  name: 'AdyenPayPal',
  props: {
    config: {
      type: Object as PropType<{
        locale: string;
        environment: string;
        clientKey: string;
      }>,
      required: true,
    },
    context: {
      type: String as PropType<CheckoutContext>,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { executeRecaptcha } = useReCaptcha(root);
    const { getCountry } = useUtilities(root);
    const { defaultCurrency, localePath } = useI18n(root);
    const { updateCart } = useCart(root);
    const { requestOrder } = useOrders(root);
    const cartStore = useCartStore();
    const { showSpinner, hideSpinner } = useLoader();
    const { displayErrorMessages } = errorMessages(root);
    const {
      getCartPaymentInstruments: getCartPaymentInstrumentsAPI,
      setShippingAddress: setShippingAddressAPI,
      setBillingAddress: setBillingAddressAPI,
    } = apiClientFactory(root);

    const payPalStyle = root.$themeConfig.payPal;
    const isExpress = props.context === CheckoutContext.Cart;

    const isPaypalAddressComplete = ref(false);
    const paymentInstrumentId = ref<string>();
    const billingAddress = ref();
    const paypalPaymentData = ref();

    const getPaypalInstance = () => {
      return window.AdyenCheckout(props.config);
    };

    const getPaypalInstrument = (data: {
      payment_instruments: PaymentInstruments[];
    }): PaymentInstruments => {
      return (
        data.payment_instruments.find(({ payment_method_id }) => {
          return payment_method_id === PaymentMethodCode.PAYPAL;
        }) || ({} as PaymentInstruments)
      );
    };

    const mapAddressData = (address: PaypalAddress) => {
      const [addressLine1 = '', addressLine2 = ''] =
        address.street?.split(',') || [];
      const stateCode =
        (address.state || address.stateOrProvince)?.toUpperCase() || '';
      const countryCode =
        (address.countryCode || address.country)?.toUpperCase() || '';

      return {
        addressId: '',
        firstName: address.firstName || '',
        lastName: address.lastName || '',
        email: address.shopperEmail || '',
        country: countryCode,
        addressLine1,
        addressLine2,
        postalCode: address.postalCode || '',
        city: address.city || '',
        phone: address.telephoneNumber || '',
        stateCode,
        countryCode,
        province: stateCode,
        region: stateCode,
      };
    };

    const onSubmit = async (state, component) => {
      const { data } = await getCartPaymentInstrumentsAPI({
        cartId: cartStore.cartId,
        payment_method_id: PaymentMethodCode.PAYPAL,
        additionalDetails: { paymentData: JSON.stringify(state.data) },
      });
      const { c_requestID, payment_instrument_id } = getPaypalInstrument(data);
      component.handleAction(JSON.parse(c_requestID));
      paymentInstrumentId.value = payment_instrument_id;
      return data;
    };

    const addBillingAddress = async (address?: Address) => {
      const response = await setBillingAddressAPI(
        cartStore.cartId,
        address || cartStore.fullShippingAddress
      );
      if (response.status === 200) {
        await updateCart(response.data);
      }
    };

    const addShippingAddress = async (address: Address) => {
      const response = await setShippingAddressAPI(
        cartStore.cartId,
        [
          {
            ...address,
            subscriptions: { newsletterConsent: false },
          },
        ],
        { query: `context=${PaymentMethodCode.PAYPAL_EXPRESS}` }
      );
      if (response.status === 200) {
        await updateCart(response.data);
      }
      return response;
    };

    const placePaypalOrder = async (
      data: PaypalAdditionalDetailsState['data']
    ) => {
      type PaypalOrderPayload = Partial<
        PutOrderObject<{
          c_paymentID: string;
          c_payerID: string;
          c_requestID: string;
          payment_instrument_id: string;
          payment_method_id: PaymentMethodCode.PAYPAL;
        }>
      >;
      const recaptchaToken = await executeRecaptcha('placeOrder');
      const payload: PaypalOrderPayload = {
        recaptcha_response: recaptchaToken,
        billingAddress: billingAddress.value,
        paymentMethod: {
          code: PaymentMethodCode.PAYPAL,
          id: 'adyen',
          additionalData: {
            c_paymentID: '',
            c_payerID: JSON.stringify(data),
            c_requestID: '',
            payment_instrument_id: paymentInstrumentId.value,
            payment_method_id: PaymentMethodCode.PAYPAL,
          },
        },
      };
      try {
        showSpinner();
        return await requestOrder(payload);
      } catch (e) {
        displayErrorMessages(e);
      } finally {
        hideSpinner();
      }
    };

    const paypalConfiguration = {
      style: {
        ...payPalStyle,
        height: payPalStyle.height[props.context] || payPalStyle.height.base,
      },
      countryCode: getCountry().toUpperCase(),
      amount: {
        currency: defaultCurrency.value,
        value: cartStore.totals.total,
      },
      configuration: { intent: 'authorize' },
      blockPayPalCreditButton: true,
      blockPayPalPayLaterButton: true,
      blockPayPalVenmoButton: true,
      showPayButton: true,
      isExpress,
      ...(isExpress
        ? {
            onShopperDetails: async (shopperDetails, rawData, actions) => {
              const { shopperEmail, telephoneNumber } = shopperDetails;
              try {
                showSpinner();
                const shippingAddressFromPayPal = mapAddressData({
                  ...shopperDetails.shippingAddress,
                  ...shopperDetails.shopperName,
                  shopperEmail,
                  telephoneNumber,
                });
                isPaypalAddressComplete.value = !cartStore.isShippingAddressIncomplete(
                  shippingAddressFromPayPal
                );
                const { data } = await addShippingAddress(
                  shippingAddressFromPayPal
                );
                const { c_requestID } = getPaypalInstrument(data);
                paypalPaymentData.value = JSON.parse(c_requestID);

                // Create Billing Object from paypal
                const newBillingAddress = mapAddressData({
                  ...shopperDetails.billingAddress,
                  ...shopperDetails.shopperName,
                  shopperEmail,
                  telephoneNumber,
                });
                await addBillingAddress(newBillingAddress);
                const stateCode = newBillingAddress?.province || '';
                billingAddress.value = {
                  ...newBillingAddress,
                  stateCode,
                  region: stateCode,
                };
                actions.resolve();
              } catch (e) {
                displayErrorMessages(e);
              } finally {
                hideSpinner();
              }
            },
            onShippingAddressChange: async (
              { shippingAddress },
              _actions,
              component
            ) => {
              const shippingAddressFromPayPal = mapAddressData(shippingAddress);
              isPaypalAddressComplete.value = !cartStore.isShippingAddressIncomplete(
                shippingAddressFromPayPal
              );
              try {
                showSpinner();
                const { data } = await addShippingAddress(
                  shippingAddressFromPayPal
                );
                const { c_requestID } = getPaypalInstrument(data);
                paypalPaymentData.value = JSON.parse(c_requestID);
                component.updatePaymentData(
                  paypalPaymentData.value.paymentData
                );
              } catch (e) {
                displayErrorMessages(e);
              } finally {
                hideSpinner();
              }
            },
            onSubmit,
            onAdditionalDetails: (
              { data }: PaypalAdditionalDetailsState,
              component
            ) => {
              if (!isPaypalAddressComplete.value) {
                // If the address is incomplete navigate the user to shipping page
                return root.$router.push(localePath(ROUTES.CHECKOUT()));
              }
              // Update paypals payment data
              component.updatePaymentData(paypalPaymentData.value);
              return placePaypalOrder(data);
            },
          }
        : {
            onSubmit: async (state, component) => {
              await addBillingAddress();
              const response = await onSubmit(state, component);
              const stateCode = response.billingAddress?.province || '';
              billingAddress.value = {
                ...response.billingAddress,
                stateCode,
                region: stateCode,
              };
            },
            onAdditionalDetails: ({ data }: PaypalAdditionalDetailsState) => {
              return placePaypalOrder(data);
            },
          }),
    };

    onMounted(async () => {
      const checkout = await getPaypalInstance();
      checkout.create('paypal', paypalConfiguration).mount('#paypal-container');
    });
  },
});

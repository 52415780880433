










import type { PropType } from 'vue';
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from '@vue/composition-api';
import { CheckoutContext } from '@vf/api-contract';
import {
  usePaymentProvider,
  usePaymentStore,
  useNotification,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

import CybersourcePayPal from './CybersourcePayPal.vue';
import AdyenPayPal from './AdyenPayPal.vue';

export default defineComponent({
  name: 'PayPal',
  components: {
    CybersourcePayPal,
    AdyenPayPal,
  },
  props: {
    header: {
      type: String,
      default: '',
    },
    context: {
      type: String as PropType<CheckoutContext>,
      required: true,
    },
  },
  setup() {
    const { root } = useRootInstance();
    const paymentStore = usePaymentStore(root);
    const config = ref();
    const paypalScriptLoaded = ref(false);

    onMounted(async () => {
      try {
        await paymentStore.getSession({
          paymentMethod: 'paypal',
        });
      } catch (e) {
        useNotification(root).addNotification({
          errorMessageId: '',
          message: '',
          type: 'danger',
        });
      }

      const {
        locale,
        meta: { environment, clientKey },
      } = paymentStore.session;
      config.value = { locale, environment, clientKey };

      const paymentProvider = usePaymentProvider(root);
      await paymentProvider.load();
      await paymentProvider.initService();
      paypalScriptLoaded.value = true;
    });

    onBeforeUnmount(() => {
      usePaymentProvider(root).unload();
    });

    return {
      paypalScriptLoaded,
      paymentStore,
      config,
    };
  },
});

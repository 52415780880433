



















































































import polyfill from 'smoothscroll-polyfill';
import {
  computed,
  ref,
  onMounted,
  onBeforeUnmount,
  defineComponent,
} from '@vue/composition-api';
import {
  useProduct,
  useNotification,
  useFavorites,
  useGtm,
  useNotifyMe,
  useValidation,
} from '@vf/composables';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import useRootInstance from '@/shared/useRootInstance';
import { PageTypeName } from '@vf/composables/src/useCms/types';
import { useFavoritesWithDataLayer } from '@/shared/useFavoritesWithDataLayer';
import { scrollTo as scrollToTop } from '@vf/shared/src/utils/helpers';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import { visibility } from '@vf/shared/src/utils/directives';
import debounce from '@vf/shared/src/utils/helpers/debounce';
import { useHeaderAndFooterStore } from '@vf/composables/src/store/headerAndFooter';
import { storeToRefs } from 'pinia';
import { addVariantOptionToUrlParams } from '@/helpers';
import useSignInToBuy from '../shared/composables/useSignInToBuy';
import { useUserStore } from '@vf/composables/src/store/user';

export default defineComponent({
  name: 'PdpStickyHeader',
  directives: { visibility },
  props: {
    priceType: {
      type: String,
      default: 'sale',
      validator: (value: string) => {
        return ['sale', 'regular'].includes(value);
      },
    },
    /** Attribute name */
    attributeName: {
      type: String,
      default: '',
    },
    contextKey: {
      type: String,
      default: 'product',
    },
    translations: {
      type: Object,
      default: () => ({
        chooseSize: 'Choose Your Size',
        addToCart: 'Add to Cart',
      }),
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const {
      attributesData,
      checkAttributes,
      checkNotifyMeEnabled,
      priceData,
      product,
      scrollToFirstValidationError,
      shouldShowPdpStickyHeader,
    } = useProduct(root, props.contextKey);
    const { subscribe, isUnavailableSizeHovered } = useNotifyMe(
      root,
      props.contextKey
    );
    const { toggleFavorites, isFavorite } = useFavoritesWithDataLayer(
      PageTypeName.PDP
    );
    const {
      addToFavorites,
      loading: onFavoriteClickLoading,
      favoriteId,
      showFavoritesTooltip,
      makeImageVariantUrlIdentifierByProduct,
    } = useFavorites(root);
    const { clearNotifications, addNotification } = useNotification(root);
    const featureFlags = useFeatureFlagsStore();
    const { dispatchEvent } = useGtm(root);
    const headerAndFooterStore = useHeaderAndFooterStore();
    const { showSticky } = storeToRefs(headerAndFooterStore);
    const { $v } = useValidation(root, 'NOTIFY_ME');
    const { getSignInToBuyState } = useSignInToBuy(root, props.contextKey);
    const userStore = useUserStore(root);
    const { loyaltyEnrolled } = storeToRefs(userStore);

    const theme = root.$themeConfig.pdpStickyHeader;

    const onFavoriteClick = async (product) => {
      if (
        theme.toggleableFavorites &&
        !theme.fullyConfiguredProductForFavorites
      ) {
        await handleToggleFavorites(product);
        return;
      }
      await handleAddToFavorites(product);
    };

    const handleAddToFavorites = async (product) => {
      if (checkAttributes()) {
        scrollToFirstValidationError(
          root.$themeConfig?.productAddToCart?.scrollToErrorOffset
        );
        return;
      }
      if (!isFavorite(product.variant.id)) {
        let productUrl = product.pageUrl;
        const selectedVariants = Object.entries(product.variant.attributes);
        selectedVariants.forEach((variant) => {
          productUrl = addVariantOptionToUrlParams(
            root,
            variant[0].toString(),
            variant[1].toString(),
            productUrl
          );
        });

        const imageUrlIdentifier = makeImageVariantUrlIdentifierByProduct(
          product
        );

        const success = await addToFavorites({
          favoriteId: favoriteId.value ?? '',
          recipeId: product.recipeId ?? '',
          id: product.variant.id,
          itemType: 'product',
          pdpUrl: productUrl,
          pageUrl: product.pageUrl ?? productUrl,
          productImageUrl: root.$mediaUrlGenerator({
            colorName: product.color?.label,
            pid: imageUrlIdentifier,
            productName: product.name,
          }) as string,
          available: product.available || '',
          qty: 1,
          defaultProductView: '',
          public: true,
        });
        if (success) {
          scrollToTop();
          showFavoritesTooltip(10000);
          dispatchEvent(
            getEventFromTemplate('favorite:add', {
              eventCategory: PageTypeName.PDP,
              eventLabel: `${product.variant.id} - ${product.colorDescription}`,
            })
          );
        }
      }
    };

    const handleToggleFavorites = async (product) => {
      clearNotifications();

      const isAdded = await toggleFavorites(product);

      if (isAdded) {
        const message = props.translations.addedToFavoritesNotification?.replace(
          '{{product}}',
          product?.name ?? ''
        );

        addNotification({
          errorMessageId: null,
          message,
          type: 'success',
        });
      }
    };

    const attributeValue = computed(() => {
      return product.value?.[props.attributeName] || null;
    });

    const hasSpecialPrice = computed(
      () => props.priceType === 'sale' && priceData.value.hasSpecialPrice
    );

    const regularPrice = computed(() =>
      root.$formatPrice(
        priceData.value.selectedVariant.originalPrice,
        priceData.value.currency
      )
    );

    const specialPrice = computed(() =>
      root.$formatPrice(
        priceData.value.selectedVariant.currentPrice,
        priceData.value.currency
      )
    );

    const showAdd = computed(() => {
      return (
        (product.value.sizes.length ? product.value.size : true) &&
        (product.value.lengths.length ? product.value.length : true)
      );
    });

    const showAddToFavorites = computed(() => {
      return (
        !featureFlags.isVansPdpRedesignEnabled &&
        (!theme.fullyConfiguredProductForFavorites || !!product.value.size)
      );
    });

    const handleNotifyMeButtonClick = async () => {
      let scrollToContainer = true;

      if (isNotifyMeEnabled.value) {
        $v.value.$touch();

        if (!$v.value.$error) {
          try {
            await subscribe(
              product.value.variant.id,
              !attributesData.value.isFutureProduct
            );
          } catch (e) {
            scrollToContainer = false;

            addNotification({
              message: props.translations.notificationErrorMessage,
              type: 'danger',
              modifiers: 'scrollToNotification',
            });
          }
        }
      }

      if (scrollToContainer) {
        root.$eventBus.$emit('moveToSize');
      }
    };

    const callEventAddToCart = () => {
      root.$eventBus.$emit('pDpStickyEmit');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const showStickyHeader = (val) => {
      if (!hiddenByObservable.value) {
        headerAndFooterStore.$patch({ showSticky: val });
      }
    };

    const displaySticky = computed(
      () => showSticky.value && shouldShowPdpStickyHeader.value
    );

    let observer = ref(null);
    const hiddenByObservable = ref(false);
    const handleIntersectingEntry = (entry: IntersectionObserverEntry) => {
      hiddenByObservable.value =
        entry.isIntersecting &&
        entry.boundingClientRect.height > window.innerHeight;
      if (hiddenByObservable.value) {
        headerAndFooterStore.$patch({ showSticky: false });
      }
    };

    const intersectFooter = () => {
      observer.value && observer.value.disconnect();

      const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        entries.forEach(handleIntersectingEntry);
      };
      const footerEl = document.getElementsByClassName('vf-footer')[0];
      /*
       * In case when element has been hidden by Column wrapper component we should have fallback to 0 to not break threshold calculation
       * */
      const footerHeight = footerEl
        ? (footerEl as HTMLElement)?.offsetHeight
        : 0;
      /*
       * PDPStickyHeader height is used to properly calculate threshold and prevent PDPStickyHeader from hiding prematurely
       * */
      const headerEl = document.getElementsByClassName('sticky-add-to-cart')[0];
      const headerHeight = headerEl
        ? (headerEl as HTMLElement)?.offsetHeight
        : 0;
      const calculatedThreshold =
        footerHeight > window.innerHeight
          ? (window.innerHeight - headerHeight) / footerHeight
          : 1;
      const threshold = theme.footerIntersectionThreshold
        ? theme.footerIntersectionThreshold
        : calculatedThreshold;
      observer.value = new IntersectionObserver(handleIntersection, {
        threshold,
      });
      try {
        observer.value.observe(footerEl);
      } catch (e) {
        console.error('Cannot observe footerEl (.vf-footer):', e);
      }
    };

    const debounceIntersectFooter = debounce(intersectFooter, 1000);

    onMounted(() => {
      debounceIntersectFooter();
      window.addEventListener('resize', debounceIntersectFooter);
      polyfill?.polyfill();
      root.$eventBus.$on('showStickyHeader', showStickyHeader);
    });

    onBeforeUnmount(() => {
      root.$eventBus.$off('showStickyHeader', showStickyHeader);
      window.removeEventListener('resize', debounceIntersectFooter);
      root.$eventBus.$emit('headerControlVisibility', false);
    });

    const productIsFavorite = computed(() => {
      const id = theme.fullyConfiguredProductForFavorites
        ? product.value.variant.id
        : product.value.id;

      return isFavorite(id);
    });

    const getAddToFavoriteButtonIcon = () => {
      return productIsFavorite.value ? 'heart__fill' : 'heart';
    };

    const isNotifyMeEnabled = computed(() =>
      checkNotifyMeEnabled(isUnavailableSizeHovered.value)
    );

    const notifyMeButtonText = computed(
      () =>
        props.translations[isNotifyMeEnabled.value ? 'notifyMe' : 'chooseSize']
    );

    return {
      isSignInToBuy: computed(() =>
        getSignInToBuyState(product.value, loyaltyEnrolled.value)
      ),
      product,
      attributeValue,
      showSticky,
      hasSpecialPrice,
      regularPrice,
      specialPrice,
      showAdd,
      callEventAddToCart,
      displaySticky,
      onFavoriteClick,
      onFavoriteClickLoading,
      getAddToFavoriteButtonIcon,
      theme,
      showPriceRange: computed(() => priceData.value.showPriceRange),
      priceRange: computed(() => priceData.value.priceRange),
      showAddToFavorites,
      intersectFooter,
      handleIntersectingEntry,
      hiddenByObservable,
      observer,
      productIsFavorite,
      notifyMeButtonText,
      handleNotifyMeButtonClick,
    };
  },
});









































































































































































import { mask } from 'ke-the-mask';
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  useMeta,
} from '@nuxtjs/composition-api';
import { usePaymentProvider } from '@vf/composables';

import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'CreditCardMicroform',
  directives: { mask },
  setup() {
    const { root } = useRootInstance();
    const {
      form: { expirationDate, validate, validationFields },
      isExpiryExternal,
      render,
      showCreditCardNumberSuccess,
      showExpirationDateSuccess,
      showSecurityNumberSuccess,
      unmount,
    } = usePaymentProvider(root);

    useMeta(() => ({
      link: [
        {
          rel: 'stylesheet',
          type: 'text/css',
          href: root.$config.ADYEN_URLS.cssBaseUrl,
          integrity:
            'sha384-zgFNrGzbwuX5qJLys75cOUIGru/BoEzhGMyC07I3OSdHqXuhUfoDPVG03G+61oF4',
          crossorigin: 'anonymous',
        },
      ],
    }));

    const showCvcTooltip = ref(false);
    const isCvcTooltipVisible = ref(false);

    onMounted(() => {
      render().then(() => (showCvcTooltip.value = true));
    });

    onBeforeUnmount(() => {
      unmount();
    });

    return {
      expirationDate,
      isCvcTooltipVisible,
      isExpiryExternal,
      showCreditCardNumberSuccess,
      showCvcTooltip,
      showExpirationDateSuccess,
      showSecurityNumberSuccess,
      validate,
      validationFields,
    };
  },
  // You need to define an empty head to activate this functionality
  head: {},
});

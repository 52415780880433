




































































































































































































































































































import {
  defineComponent,
  onMounted,
  ref,
  Ref,
  PropType,
  computed,
  inject,
} from '@vue/composition-api';
import VfContentPages from './Organism.ContentPages.vue';
import { isClient } from '@vf/shared/src/utils/helpers';
import { AnalyticsDataDomLocation } from '../../theme/types';

const getSpacersClasslist = ({ margins = null, paddings = null } = {}) => {
  const getClassNames = (spacers) => {
    const classList = [];
    Object.entries(spacers).forEach(([key, className]) => {
      if (['top', 'right', 'bottom', 'left'].includes(key)) {
        classList.push(className);
      }
    });
    return classList;
  };
  return margins || paddings
    ? [
        getClassNames(margins.smallMargins),
        getClassNames(margins.mediumMargins),
        getClassNames(margins.largeMargins),
        getClassNames(paddings.smallPaddings),
        getClassNames(paddings.mediumPaddings),
        getClassNames(paddings.largePaddings),
        {
          'custom-margin-sm': !!margins.smallMargins.custom,
          'custom-margin-md': !!margins.mediumMargins.custom,
          'custom-margin-lg': !!margins.largeMargins.custom,
          'custom-padding-sm': !!paddings.smallPaddings.custom,
          'custom-padding-md': !!paddings.mediumPaddings.custom,
          'custom-padding-lg': !!paddings.largePaddings.custom,
        },
      ]
    : [];
};
const getSpacersCustomStyles = ({
  margins = null,
  paddings = null,
  orderLarge = null,
} = {}) => {
  return margins || paddings || orderLarge
    ? {
        order: orderLarge,
        '--sm-custom-margin': margins.smallMargins.custom || undefined,
        '--md-custom-margin': margins.mediumMargins.custom || undefined,
        '--lg-custom-margin': margins.largeMargins.custom || undefined,
        '--sm-custom-padding': paddings.smallPaddings.custom || undefined,
        '--md-custom-padding': paddings.mediumPaddings.custom || undefined,
        '--lg-custom-padding': paddings.largePaddings.custom || undefined,
      }
    : undefined;
};

export default defineComponent({
  name: 'VfMegaMenu',
  components: {
    VfContentPages,
  },
  props: {
    ssr: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String as PropType<string>,
      default: '',
    },
    visible: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    subcategories: {
      type: [Object, Array],
      default: () => ({}),
    },
    subcategoriesMaxItemsConfig: {
      type: Object,
      default: () => ({}),
    },
    featured: {
      type: [Object, Array],
      default: () => ({}),
    },
    withAside: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  setup(props, { root, emit, slots }) {
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    const activePageId: Ref<number> = ref(null);

    const isShopAllSlotUsed = computed(() => !!slots['shop-all']);

    const stateFulfilledCategories = (props.subcategories as {
      stateFulfilled: boolean;
    }[]).filter((i) => i.stateFulfilled);

    const hasEspot = (subcategory) => {
      return subcategory.level2MenuItems
        .flatMap((menuItems) =>
          menuItems?.children?.map((child) => child.isImage)
        )
        ?.includes(true);
    };

    onMounted(() => {
      if (
        isClient &&
        root.$viewport &&
        Object.keys(props.subcategoriesMaxItemsConfig).length
      ) {
        switch (root.$viewport.size) {
          case 'large':
            activePageId.value =
              props.subcategoriesMaxItemsConfig.largeMaxItems;
            return;
          case 'medium':
            activePageId.value =
              props.subcategoriesMaxItemsConfig.mediumMaxItems;
            return;
        }
      }
    });

    const handleNavLinkClick = (e, link, data) => {
      if (data.isModal) {
        e.preventDefault();
      }
      emit('nav-menu-link-clicked', {
        link,
        l2: data.l2 || '',
        l3: data.l3 || '',
        isModal: data.isModal || false,
      });
    };

    const formatForScreenReader = (title) => {
      if (!title) return;
      const reg = /-(.*\))/; // regex matching '-' followed by ")" e.g. "-XXL)"
      const regMatchingIndex = title.indexOf(title.match(reg)?.[0]);
      return regMatchingIndex !== -1
        ? title.slice(0, regMatchingIndex) +
            ` ${root.$t('headerMegaMenu.screenReaderCouplerWord')} ` +
            title.slice(regMatchingIndex + 1)
        : title;
    };

    const setSubcategoryTag = (subcategory) =>
      subcategory.level2MenuItems?.find((l2) => l2.children?.length)
        ? 'ul'
        : 'div';

    const setL2Tag = (l2) => (l2.children?.length ? 'li' : 'div');

    return {
      activePageId,
      getSpacersClasslist,
      getSpacersCustomStyles,
      stateFulfilledCategories,
      AnalyticsDataDomLocation,
      handleNavLinkClick,
      isShopAllSlotUsed,
      isCoreRedesignEnabled,
      hasEspot,
      formatForScreenReader,
      setSubcategoryTag,
      setL2Tag,
    };
  },
});










































import type { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import type { PageTitleTranslations } from '@vf/api-contract';
import { useSearch, useArticles, useI18n, ROUTES } from '@vf/composables';
import PageTitle from '../shared/PageTitle.vue';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  components: { PageTitle },
  props: {
    /** Name of the context where component should be displayed (one of 'category' | 'search') */
    contextName: {
      type: String as PropType<'category' | 'search'>,
      default: 'search',
    },
    /** Title heading level 1-6 */
    level: {
      type: Number,
      default: () => 2,
    },
    /** For search results. Display "No results for X, showing Y instead" */
    showAutoCorrectQueryInfo: {
      type: Boolean,
      default: false,
    },
    translations: {
      type: Object as PropType<PageTitleTranslations>,
      default: () => ({}),
    },
    contextKey: {
      type: String,
      default: 'page-content',
    },
    numberOfTextSuggestions: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const {
      pagination,
      searchType,
      autoCorrectQuery,
      queryString,
      didYouMean,
    } = useSearch(root);
    const {
      pagination: articlesPagination,
      noArticlesFound,
      loading: loadingArticles,
      transitioningFromProducts,
      noResultQueryString,
    } = useArticles(root, props.contextKey);
    const { localePath } = useI18n(root);

    const {
      itemsCountPlural,
      itemsCountSingular,
      misspellQueryResults,
      autoCorrectQueryInfo,
      weFound,
    } = props.translations;

    const getSearchQtyLabel = computed(() => {
      return itemsCountLabel.value > 1 ? itemsCountPlural : itemsCountSingular;
    });

    const searchQuery = computed<string>(() => {
      const query = root.$route.query.q || queryString.value;
      return Array.isArray(query) ? query[0] ?? '' : query;
    });
    const itemsCountLabel = computed(() => {
      if (
        searchType.value === 'product' ||
        (loadingArticles.value && transitioningFromProducts.value)
      ) {
        return pagination.value.total;
      }
      return noArticlesFound.value || noResultQueryString.value
        ? 0
        : articlesPagination.value.total;
    });

    const showAutoCorrectQueryInfo = computed<boolean>(
      () =>
        props.showAutoCorrectQueryInfo &&
        !!autoCorrectQuery.value &&
        searchType.value === 'product' &&
        autoCorrectQuery.value.toLowerCase() !== searchQuery.value.toLowerCase()
    );

    const misspellInfo = computed<string | undefined>(() =>
      showAutoCorrectQueryInfo.value
        ? misspellQueryResults.replace('{{query}}', searchQuery.value)
        : undefined
    );
    const correctionInfo = computed<string | undefined>(() =>
      showAutoCorrectQueryInfo.value
        ? autoCorrectQueryInfo.replace('{{correction}}', autoCorrectQuery.value)
        : undefined
    );
    const subtitle = computed<string | undefined>(() => {
      let text =
        showAutoCorrectQueryInfo.value && itemsCountLabel.value > 0
          ? undefined
          : `${weFound} ${itemsCountLabel.value} ${getSearchQtyLabel.value}`;

      if (
        root.$themeConfig.SearchTitle?.overrideSubtitle &&
        itemsCountLabel.value === 0 &&
        ((!pagination.value.total && !suggestions?.value?.length) ||
          searchQuery?.value?.length < 3)
      ) {
        text = misspellQueryResults.replace(
          '{{query}}',
          searchQuery.value ?? ''
        );
      }

      return text;
    });

    const renderQueryCorrectionsAsLinks =
      root.$themeConfig.SearchTitle?.renderQueryCorrectionsAsLinks;

    const suggestions = computed(() => {
      return didYouMean.value
        .filter(Boolean)
        .slice(0, props.numberOfTextSuggestions);
    });

    // TODO: GLOBAL15-63799 update when redesign work is done
    const { isVansPlpRedesignEnabled } = useFeatureFlagsStore();

    const showBackButton = isVansPlpRedesignEnabled
      ? false
      : root.$themeConfig.SearchTitle?.showBackButton ?? true;

    const showSearchQueryInQuotes =
      root.$themeConfig.SearchTitle?.showSearchQueryInQuotes ?? false;

    return {
      searchQuery,
      pagination,
      articlesPagination,
      itemsCountLabel,
      misspellInfo,
      correctionInfo,
      subtitle,
      renderQueryCorrectionsAsLinks,
      getSuggestionLink: (query) => localePath(ROUTES.SEARCH(query)),
      suggestions,
      showBackButton,
      showSearchQueryInQuotes,
      isVansPlpRedesignEnabled,
    };
  },
});

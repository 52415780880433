import { ComposablesStorage } from '../../../types';
import { ref, Ref, computed, ComputedRef } from '@nuxtjs/composition-api';
import { apiClientFactory } from '@vf/api-client';
import initStorage from '../../../utils/storage';
import { useAccount } from '../../../useAccount';

type SubscriptionFeedback = { attempted: boolean; success: boolean };

type UseNotifyMeStorage = {
  isUnavailableSizeHovered: Ref<boolean>;
  isNotifyMeFormVisible: Ref<boolean>;
  emailAddress: Ref<string>;
  subscriptionFeedback: Ref<SubscriptionFeedback>;
};

export const useNotifyMe = (instance, contextKey?: string) => {
  const { notifyMe: notifyMeAPI } = apiClientFactory(instance);
  const { customerEmail } = useAccount(instance);

  const storage: ComposablesStorage<UseNotifyMeStorage> = initStorage<UseNotifyMeStorage>(
    instance,
    `useNotifyMe-${contextKey}`
  );

  const isUnavailableSizeHovered: Ref<boolean> =
    storage.get('isUnavailableSizeHovered') ??
    storage.save('isUnavailableSizeHovered', ref(false));

  const isNotifyMeFormVisible: Ref<boolean> =
    storage.get('isNotifyMeFormVisible') ??
    storage.save('isNotifyMeFormVisible', ref(false));

  const emailAddress: Ref<string> =
    storage.get('emailAddress') ??
    storage.save('emailAddress', ref(customerEmail.value));

  const subscriptionFeedback: Ref<SubscriptionFeedback> =
    storage.get('subscriptionFeedback') ??
    storage.save(
      'subscriptionFeedback',
      ref({ attempted: false, success: false })
    );

  const isAlreadySubscribed: ComputedRef<boolean> = computed(
    () =>
      subscriptionFeedback.value.attempted &&
      !subscriptionFeedback.value.success
  );

  const reset = () => {
    emailAddress.value = customerEmail.value;
    subscriptionFeedback.value = { attempted: false, success: false };
  };

  const subscribe = async (
    productId: string,
    backInStock: boolean = true,
    email?: string | undefined
  ) => {
    const response = await notifyMeAPI({
      email: email ?? emailAddress.value,
      productId,
      backInStock,
    });
    subscriptionFeedback.value.attempted = true;
    subscriptionFeedback.value.success = response.data.success;
    if (response.data.success) emailAddress.value = '';
    return response.data;
  };

  return {
    isUnavailableSizeHovered,
    isNotifyMeFormVisible,
    subscribe,
    emailAddress,
    subscriptionFeedback,
    isAlreadySubscribed,
    reset,
  };
};

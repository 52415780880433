


















import { PropType } from 'vue';
import {
  defineComponent,
  nextTick,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api';
import { CheckoutContext } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';
import usePayPalButton from '@/shared/usePayPalButton';
import {
  usePayPalStore,
  PayPalContext,
} from '@vf/composables/src/store/paypal';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'PayPal',
  /** PayPal style object */
  props: {
    header: {
      type: String,
      default: '',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    context: {
      type: String as PropType<CheckoutContext>,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const payPalStore = usePayPalStore(root);
    const { paypalScriptLoaded } = storeToRefs(payPalStore);
    const {
      renderPayPalButton,
      setPayPalContext,
      payPalContext,
    } = usePayPalButton();

    const button = ref(null);

    const buttonStyle = {
      color: root.$themeConfig.payPal.color,
      shape: root.$themeConfig.payPal.shape,
      height:
        root.$themeConfig.payPal.height[props.context] ??
        root.$themeConfig.payPal.height.base,
    };

    watch(
      () => props.context,
      (newContext) => setPayPalContext(newContext as PayPalContext),
      {
        immediate: true,
      }
    );

    onMounted(() => {
      const unwatch = watch(
        paypalScriptLoaded,
        async (value) => {
          if (value) {
            renderPayPalButton(button.value, buttonStyle);
            nextTick(() => {
              // https://github.com/vuejs/docs/issues/575
              unwatch?.();
            });
          }
        },
        {
          immediate: true,
        }
      );
    });

    return {
      button,
      payPalContext,
    };
  },
});

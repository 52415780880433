import { render, staticRenderFns } from "./KlarnaModal.vue?vue&type=template&id=a44cfda8&scoped=true&"
import script from "./KlarnaModal.vue?vue&type=script&lang=ts&"
export * from "./KlarnaModal.vue?vue&type=script&lang=ts&"
import style0 from "./KlarnaModal.vue?vue&type=style&index=0&id=a44cfda8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a44cfda8",
  null
  
)

export default component.exports














































import { computed, defineComponent } from '@vue/composition-api';
import { useNotification } from '@vf/composables';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';
import AddressSummary from '../AddressSummary.vue';

export default defineComponent({
  name: 'AddressVerification',
  serverCacheKey: getCacheKeyFromProps,
  components: {
    AddressSummary,
  },
  props: {
    /** Prop to decide on address verification visibility */
    visible: {
      type: Boolean,
      default: false,
    },
    /** Data passed to render heading and button texts */
    translations: {
      type: Object,
      default: () => ({}),
    },
    /** Object with suggested address data */
    suggestedAddress: {
      type: Object,
      default: () => ({}),
    },
    /** Object with original address data */
    originalAddress: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();

    const { clearNotifications } = useNotification(root);
    const onCloseModal = () => {
      clearNotifications();
      emit('close');
    };

    const buttonSelector = computed(() => {
      return (
        root.$themeConfig?.addressVerificationButton?.buttonChooseAddress ||
        'vf-button--fullWidth'
      );
    });
    const buttonSelectorTertiary = computed(() => {
      return (
        root.$themeConfig?.addressVerificationButton
          ?.buttonChooseAddressOriginal ||
        'vf-button--secondary vf-button--fullWidth'
      );
    });

    return {
      onCloseModal,
      buttonSelector,
      buttonSelectorTertiary,
    };
  },
});

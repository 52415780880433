//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PlaceOrderButton',
  props: {
    disabled: {
      type: Boolean,
    },
  },
};

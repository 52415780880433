


































import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  watch,
  nextTick,
} from '@nuxtjs/composition-api';
import { MetaInfo } from 'vue-meta';
import {
  useCategory,
  useGtm,
  useRequestTracker,
  useSearch,
  useSignInToStore,
} from '@vf/composables';
import { isClient } from '@vf/shared/src/utils/helpers';
import { CmsProps } from '@/components/smart/shared/ProductsGrid/CmsProps';
import { PageTypeName } from '@vf/composables/src/useCms/types';
import useRootInstance from '@/shared/useRootInstance';
import ProductsGrid from '../../smart/shared/ProductsGrid.vue';
import { useFavoritesWithDataLayer } from '@/shared/useFavoritesWithDataLayer';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';

export default defineComponent({
  name: 'CategoryProducts',
  components: { ProductsGrid },
  props: {
    ...CmsProps,
    /** How many items should be displayed on single page */
    productsPerPage: {
      type: Number,
      default: 25,
    },
    /** Added title if can exist for some cases */
    title: {
      type: String,
      default: '',
    },
    /** Added css class */
    cssClassProp: {
      type: Object,
      default: null,
    },
    /** Link for catalog wich needed */
    customLinkCatalog: {
      type: String,
      default: '',
    },
    /** Name of the context where component should be displayed (one of 'category' | 'favorites' | 'search') */
    contextName: {
      type: String as PropType<'category' | 'favorites' | 'search'>,
      default: 'category',
    },
    /** How many items should be displayed on the first page */
    initialProductsLoad: {
      type: Number,
      default: 25,
    },
    contextKey: {
      type: String as PropType<string>,
      default: '',
    },
    /** Manually added products list */
    manualProductsList: {
      type: Array,
      default: () => [],
    },
    /** Flag to determine if FE should shuffle products */
    randomizeProducts: {
      type: Boolean,
      default: false,
    },
    /** Determine maximum shown products */
    maxProducts: {
      type: [Number, null],
      default: null,
    },
    /** Flag to determine product source - category or productList */
    productSource: {
      type: String,
      default: 'category',
    },
    /** Custom category title - only for manual products */
    customTitle: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const {
      customLinkCatalog,
      contextKey,
      maxProducts,
      randomizeProducts,
      productsPerPage,
      initialProductsLoad,
      manualProductsList,
      productSource,
      title,
      customTitle,
    } = props;

    const { toggleFavorites } = useFavoritesWithDataLayer(PageTypeName.PLP);

    const {
      loading,
      setProductsPerPage,
      setInitialLoadProductsAmount,
      products,
      fetchedProducts,
      pagination,
      selectedFilters,
      selectedSortingOptionId,
      getCurrentCategory,
      category,
      getCatalog,
      setHeaderLinks,
      headerLinks,
      loadPreviousPage: loadPreviousCategoryPage,
    } = useCategory(root, contextKey);

    const {
      getSearchResults,
      setActivePage,
      setQueryString,
      products: searchProducts,
      loading: searchLoading,
    } = useSearch(root);

    const cmsRefStore = useCmsRefStore(root.$pinia);

    const { dispatchEvent } = useGtm(root);
    const { employeeConnected } = useSignInToStore(root);
    const { onBaseDone } = useRequestTracker(root);
    const skipEvents = computed(
      () =>
        cmsRefStore.stateRedirectUrlExecuted ||
        cmsRefStore.canonicalRedirectUrlExecuted
    );
    const slicedProducts = ref([]);
    const shownProducts = ref(products.value);
    const categoryTitle = productSource === 'productList' ? customTitle : title;
    const isLoading = productSource === 'productList' ? searchLoading : loading;
    let disableGMTrigger = false;

    const triggerProductImpression = (lastProducts: typeof products.value) => {
      getCurrentCategory();
      dispatchEvent({
        eventName: 'productImpressions',
        composablesContexts: { useCategory: props.contextKey },
        overrideAttributes: { productIds: lastProducts.map((p) => p.id) },
        persistentVariables: { category: category.value },
      });
    };

    onBeforeMount(() => {
      if (!customLinkCatalog && !manualProductsList.length) return;
      shownProducts.value = [];
    });

    onUnmounted(() => {
      disableGMTrigger = true;
    });

    let latestValidWatch = null;
    const waitOnBaseDone = (validWatch: number) => async () => {
      await nextTick();
      if (disableGMTrigger || validWatch !== latestValidWatch) {
        return;
      }
      setHeaderLinks(isClient, initialProductsLoad);
      triggerProductImpression(
        maxProducts
          ? fetchedProducts.value.slice(0, maxProducts)
          : fetchedProducts.value
      );
    };

    const startWatchGTM = () => {
      if (isClient && !skipEvents.value) {
        watch(
          fetchedProducts,
          () => {
            latestValidWatch = Date.now();
            onBaseDone(waitOnBaseDone(latestValidWatch));
          },
          { immediate: true }
        );
      }
    };

    onMounted(async () => {
      if (!customLinkCatalog && !manualProductsList.length) {
        startWatchGTM();
        return;
      }
      const urlCategory = location.pathname.match(
        /women|mens|kids|bags-and-gear/gm
      );

      const productQuery = (manualProductsList as any)
        .map((item) => `"${(item as any).split('/').pop()}"`)
        .join(' OR ');

      setActivePage(1);
      productQuery && setQueryString(`*&efq=(${productQuery})`);

      productSource === 'productList'
        ? await getSearchResults()
        : await getCatalog({
            filters: [
              {
                code: 'cgid',
                value: urlCategory?.[0],
              },
            ],
            customID: customLinkCatalog,
          });

      (shownProducts.value as any) =
        productSource === 'productList' ? searchProducts.value : products.value;

      randomizeProducts && shuffleProducts(shownProducts.value);
      if (maxProducts) {
        slicedProducts.value = shownProducts.value.slice(0, maxProducts);
      }

      startWatchGTM();
    });

    watch(products, () => {
      shownProducts.value = products.value.concat(searchProducts.value as any);
    });

    setProductsPerPage(productsPerPage);
    setInitialLoadProductsAmount(initialProductsLoad);

    const searchState = computed(() => ({
      page: pagination.value.page,
      previousPage: pagination.value.previousPage,
      selectedFilters: selectedFilters.value,
      selectedSortingOptionId: selectedSortingOptionId.value,
    }));

    const shuffleProducts = (productsArr) => {
      for (let i = productsArr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [productsArr[i], productsArr[j]] = [productsArr[j], productsArr[i]];
      }
    };

    // Do not show if employee is connected and config is true.
    const strictShowAddToFavourites = computed(() => {
      return root.$themeConfig?.saveToFavorites?.hideFavouriteCtaForEmployees &&
        employeeConnected.value
        ? false
        : props.showAddToFavourites;
    });

    const nextUrl = computed(() => {
      try {
        return headerLinks.value.find((link) => link.hid === 'linkNext').href;
      } catch {
        return '';
      }
    });

    const loadPreviousPage = async (elementToScroll) => {
      await loadPreviousCategoryPage();

      await nextTick();

      const element = document.querySelector(elementToScroll);
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    };

    return {
      slicedProducts,
      shownProducts,
      espots: computed(() => cmsRefStore.espots),
      isLoading,
      headerLinks,
      nextUrl,
      searchState,
      toggleFavorites,
      categoryTitle,
      loadPreviousPage,
      strictShowAddToFavourites,
    };
  },
  head() {
    const meta: MetaInfo = {};

    if (this.headerLinks.length > 0) {
      meta.link = this.headerLinks;
    }

    // simply returning empty {} resets the page title ECOM15-12247
    if (Object.keys(meta).length > 0) {
      return meta;
    }
  },
});











































































































































































































































import type { PropType } from 'vue';
import type { ContactUsCategory } from '@vf/api-contract';
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  watch,
  ref,
} from '@vue/composition-api';
import { VueMaskDirective } from 'v-mask';
import { validationMixin } from 'vuelidate';
import { required, email, requiredIf } from 'vuelidate/lib/validators';
import type { ContactFormTranslations } from '@vf/api-contract';
import { useNotification, useReCaptcha, useValidation } from '@vf/composables';
import { useContactForm } from '@vf/composables/src/useContactForm';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';

const phoneNumber = (phone) => {
  const reg = /^\d{3}-\d{3}-\d{4}$/;
  return phone ? reg.test(phone) : true;
};

export default defineComponent({
  name: 'ContactForm',
  serverCacheKey: getCacheKeyFromProps,
  directives: { mask: VueMaskDirective },
  mixins: [validationMixin],
  props: {
    translations: {
      type: Object as PropType<ContactFormTranslations>,
      default: () => ({}),
    },
    /** Categories object */
    categories: {
      type: Object,
      default: () => ({
        order: 'Order Inquiry',
        product: 'Product Information',
        brand: 'Vans.ca',
      }),
    },
    /** Subjects object */
    subjects: {
      type: Object,
      default: () => ({
        order: ['General Status', 'Lost package', 'Cancel Order', 'Return'],
        product: ['Availability', 'Custom Shoes', 'General Product Question'],
        brand: [
          'Employment',
          'General Vans Question',
          'Gift Card',
          'International Shipping',
          'Password Reset',
          'Retail Comments',
          'Site Issues',
          'Sticker Request',
        ],
      }),
    },
    textOnlySubjects: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { addNotification, clearNotifications } = useNotification(root);
    const { $v, setValidation } = useValidation(root, 'CONTACT_FORM');
    const reCaptcha = useReCaptcha(root);
    const { isSfccContactUs } = useFeatureFlagsStore();

    const initialForm = {
      productInfo: null,
      subject: null,
      fullName: null,
      consumerEmail: null,
      consumerPhone: null,
      orderNumber: null,
      message: null,
      ...(isSfccContactUs ? { lastName: '', consent: false } : {}),
    };
    const contactForm = ref({ ...initialForm });
    const categoriesSubjects = ref<
      Array<string | ContactUsCategory['subjects']>
    >([]);
    const categoriesSfcc = ref([]);
    const form = ref(null);
    const showForm = ref(true);
    const messageSuccessfullySent = ref(false);
    const richText = ref('');
    const isOrderNumberRequired = computed(() =>
      isSfccContactUs ? false : contactForm.value.productInfo === 'order'
    );
    const categoriesReactive = ref<typeof props.categories>(() =>
      isSfccContactUs ? [] : props.categories
    );

    const phoneMask = '###-###-####';
    const categoryChange = (value) => {
      contactForm.value.productInfo = value;
      contactForm.value.subject = '';
      if (isSfccContactUs) {
        categoriesSubjects.value =
          categoriesSfcc.value.find((cat) => cat.category === value)
            ?.subjects || [];
      } else {
        if (Object.prototype.hasOwnProperty.call(props.subjects, value)) {
          categoriesSubjects.value = props.subjects[value];
        }
      }
    };

    const subjectChange = (value) => {
      contactForm.value.subject = value;
      if (isSfccContactUs) return;

      /**
       * Check the textOnlySubjects prop for any subjects with custom RTE
       * textOnlySubjects: {
       *     subjects.brand.7:"<div><p>Some Custom Rich Text 1</p></div>"
       *     subjects.order.2:"<div><p>Some Custom Rich Text 2</p></div>"
       * }
       */
      const selectedCategorySubject =
        props.subjects[contactForm.value.productInfo];
      const selectedSubjectIndex = selectedCategorySubject.indexOf(value);
      const key = `subjects.${contactForm.value.productInfo}.${selectedSubjectIndex}`;
      if (Object.prototype.hasOwnProperty.call(props.textOnlySubjects, key)) {
        showForm.value = false;
        richText.value = props.textOnlySubjects[key];
      } else {
        showForm.value = true;
        richText.value = '';
      }
    };

    const isLoading = ref(false);

    const submitForm = async () => {
      clearNotifications();
      isLoading.value = true;
      try {
        const recaptchaToken = await reCaptcha.executeRecaptcha('contactForm');

        await useContactForm(root).submitForm(
          contactForm.value,
          recaptchaToken
        );
        addNotification({
          message: props.translations.messageSent,
          type: 'success',
        });
        contactForm.value = { ...initialForm };
        $v.value.$reset();
        messageSuccessfullySent.value = true;
        const unwatch = watch(
          contactForm,
          () => {
            messageSuccessfullySent.value = false;
            unwatch();
          },
          { deep: true }
        );
      } catch (error) {
        root.$log.error(
          '[@components/smart/forms/ContactForm::submitForm]',
          error
        );
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      reCaptcha.showBadge();
      if (isSfccContactUs) {
        try {
          categoriesSfcc.value = await useContactForm(root).getCategories();
          categoriesReactive.value = categoriesSfcc.value.reduce((acc, cat) => {
            acc[cat.category] = cat.translation;
            return acc;
          }, {});
        } catch (error) {
          root.$log.error(
            '[@components/smart/forms/ContactForm::onMounted]',
            error
          );
        }
      }
    });
    onUnmounted(() => reCaptcha.hideBadge());

    return {
      phoneMask,
      categoriesReactive,
      categoriesSfcc,
      categoryChange,
      contactForm,
      categoriesSubjects,
      subjectChange,
      form,
      submitForm,
      isLoading,
      isOrderNumberRequired,
      isSfccContactUs,
      messageSuccessfullySent,
      setValidation,
      showForm,
      richText,
    };
  },
  mounted() {
    this.setValidation(this.$v);
  },
  validations: {
    contactForm: {
      productInfo: { required },
      subject: { required },
      fullName: { required },
      lastName: {
        required: requiredIf(function () {
          return this.isSfccContactUs;
        }),
      },
      consumerEmail: {
        required,
        email,
      },
      consumerPhone: {
        required: requiredIf(function () {
          return !this.isSfccContactUs;
        }),
        phoneNumber,
      },
      orderNumber: {
        required: requiredIf(function () {
          return this.isOrderNumberRequired;
        }),
      },
      message: { required, valid: (value) => /^[^<>]*$/.test(value) },
      consent: {
        sameAs: function (val) {
          return this.isSfccContactUs ? val === true : true;
        },
      },
    },
  },
});



















































import { storeToRefs } from 'pinia';
import {
  computed,
  defineComponent,
  nextTick,
  ref,
  onMounted,
  onBeforeUnmount,
} from '@vue/composition-api';
import { useRequestTracker } from '@vf/composables';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import useRootInstance from '@/shared/useRootInstance';
import { pluralize } from '@/helpers/pluralize';

import CheckoutAddress from '@/components/static/addressBook/CheckoutAddress.vue';
import CheckoutShippingMethods from '@/components/static/checkout/CheckoutShippingMethods.vue';
import CheckoutPickup from '@/components/static/checkout/pickup/CheckoutPickup.vue';

import { replaceAll } from '@/helpers/replaceAll';
import useLoader from '@/shared/useLoader';

export default defineComponent({
  name: 'ShippingStepComponents',
  components: {
    CheckoutAddress,
    CheckoutShippingMethods,
    CheckoutPickup,
  },
  emits: ['click:save-shipping-step'],
  setup(_, { emit }) {
    const { root } = useRootInstance();
    const { showSpinner, hideSpinner } = useLoader();
    const {
      isMixedShippingAndPickup,
      shippingItems,
      cartShipments,
      pickupAddress,
      inStoreItems,
      cartItems,
      hasPoAddress,
      hasCustomItems,
    } = storeToRefs(useCartStore());
    const { isShippingFormTouched } = storeToRefs(useCheckoutStore());
    const { isRequestOngoing, getOngoingRequest } = useRequestTracker(root);
    const { checkoutStickyCtas } = useFeatureFlagsStore();

    const checkoutAddressRef = ref(null);
    const checkoutPickupRef = ref(null);
    const isProcessing = ref(false);
    const ctaContainer = ref(null);
    const isAddressFormTouched = ref(false);

    onMounted(() => {
      checkoutStickyCtas && window.addEventListener('scroll', handleScroll);
    });

    onBeforeUnmount(() => {
      isShippingFormTouched.value = false;
      checkoutStickyCtas && window.removeEventListener('scroll', handleScroll);
    });

    const deliveryLabels = computed(() => {
      const shippingItemsCount = shippingItems.value.length;
      const pickupItemsCount = inStoreItems.value.length;
      return {
        sth: replaceAll(
          `${root.$t(pluralize('pickupTime.itemCount', shippingItemsCount))}`,
          { count: '' + shippingItemsCount }
        ),
        pickup: replaceAll(
          `${root.$t(pluralize('pickupTime.itemCount', pickupItemsCount))}`,
          { count: '' + pickupItemsCount }
        ),
      };
    });

    const isContinueDisabled = computed(
      () =>
        !cartItems.value.length ||
        (hasCustomItems.value && hasPoAddress.value) ||
        (checkoutStickyCtas &&
          root.$viewport.isSmall &&
          !isShippingFormTouched.value)
    );

    const isShippingStepReady = async () => {
      if (cartShipments.value.length) {
        const isAddressValid = await checkoutAddressRef.value.submit();
        if (!isAddressValid) return false;
      }

      if (pickupAddress.value) {
        const isPickupValid = await checkoutPickupRef.value.submit();
        if (!isPickupValid) return false;
      }

      //TODO: GLOBAL15-66633 show notification about changed shipping method if it's changed
      await nextTick();
      if (isRequestOngoing('useCart-getShippingMethods')) {
        await getOngoingRequest('useCart-getShippingMethods');
      }

      if (isContinueDisabled.value) {
        return false;
      }

      return true;
    };

    const onSaveAndContinueClick = async () => {
      showSpinner();
      isProcessing.value = true;

      const isReady = await isShippingStepReady();

      if (isReady) {
        emit('click:save-shipping-step');
      } else {
        isProcessing.value = false;
        hideSpinner();
      }
    };

    const handleScroll = () => {
      if (!ctaContainer.value) return;
      const viewportHeight = window.innerHeight;
      const ctaBottom = ctaContainer.value.getBoundingClientRect().bottom;

      const shadowClass = 'vf-shipping-step__cta-container-sticky-shadow';

      if (ctaBottom === viewportHeight) {
        ctaContainer.value.classList.add(shadowClass);
      } else {
        ctaContainer.value.classList.remove(shadowClass);
      }
    };

    const handleShippingFormEdit = (address) => {
      if (isShippingFormTouched.value || !address) return;

      isShippingFormTouched.value = [
        address.firstName,
        address.lastName,
        address.addressLine1,
        address.city,
        address.province,
        address.postalCode,
        address.phone,
        address.email,
      ].some(Boolean);
    };

    return {
      isMixedShippingAndPickup,
      deliveryLabels,
      checkoutAddressRef,
      checkoutPickupRef,
      cartShipments,
      pickupAddress,
      isContinueDisabled,
      isShippingStepReady,
      onSaveAndContinueClick,
      cartItems,
      isProcessing,
      checkoutStickyCtas,
      ctaContainer,
      isAddressFormTouched,
      handleShippingFormEdit,
    };
  },
});

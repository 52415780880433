

















































import type { PropType } from 'vue';
import { ref, defineComponent, onMounted } from '@vue/composition-api';
import type { SearchSortByTranslations } from '@vf/api-contract';
import { useSearch } from '@vf/composables';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'SearchSortBy',
  props: {
    translations: {
      type: Object as PropType<SearchSortByTranslations>,
      default: () => ({}),
    },
    /** Default chosen value for Sort By select  */
    selectedSort: {
      type: String,
      default: 'bestMatches',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const {
      changeSort,
      getSearchResults,
      sortingOptions,
      setActivePage,
    } = useSearch(root);
    // TODO: GLOBAL15-63799 remove after plp redesign
    const { isVansPlpRedesignEnabled } = useFeatureFlagsStore();
    const selectedValue = ref(props.selectedSort);
    const refSearchBarHeaderSort = ref<HTMLDivElement | null>(null);

    const changeActiveSortOption = async (sortOptionId) => {
      selectedValue.value = sortOptionId;
      changeSort(sortOptionId);
      setActivePage(1);
      await getSearchResults();
    };

    // TODO: GLOBAL15-63799 update on CMS only one div for filter and sort button like plp section
    const attachRedesignToTheParent = () => {
      if (!refSearchBarHeaderSort.value) return;

      refSearchBarHeaderSort.value
        .closest('.row')
        ?.classList.add('search-results-header__row-redesign');
    };

    onMounted(() => {
      isVansPlpRedesignEnabled && attachRedesignToTheParent();
    });

    return {
      changeActiveSortOption,
      isVansPlpRedesignEnabled,
      refSearchBarHeaderSort,
      sortingOptions,
      selectedValue,
    };
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vf-flag-review-outer"},[_c('div',{staticClass:"vf-flag-review-controls"},[_c('ThemeLink',{staticClass:"vf-flag-review-item",class:{ 'vf-flag-review-item--redesign': _vm.isCoreRedesignEnabled },attrs:{"color":"primary","size":_vm.isCoreRedesignEnabled ? 'sm' : 'md',"legacy":false,"disabled":_vm.flaggedState},on:{"click":function($event){return _vm.handleFlagReviewOpen()}}},[_c('VfIcon',{staticClass:"vf-flag-review-item-icon",attrs:{"icon":"flag","size":"xs"}}),_vm._v(" "),_c('span',{staticClass:"vf-flag-review-item-text"},[_vm._v("\n        "+_vm._s(_vm.flaggedState
            ? _vm.flagUgc
              ? _vm.translations.flagUGCFlagged
              : _vm.translations.flagReviewFlagged
            : _vm.flagUgc
            ? _vm.translations.flagUGCNotFlagged
            : _vm.translations.flagReviewNotFlagged)+"\n      ")])],1)],1),_vm._v(" "),_c('VfModal',{staticClass:"vf-flag-review-modal",attrs:{"visible":_vm.modalVisible},on:{"close":_vm.handleModalClose}},[_c('div',{staticClass:"vf-flag-review-modal-content"},[_c('form',{staticClass:"vf-flag-review__form",attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();}}},[_c('VfHeading',{staticClass:"vf-flag-review__heading",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'resourceBundle'),"title":_vm.translations.heading,"level":2,"title-modifier":"title-4","subtitle-modifier":"font-small"}}),_vm._v(" "),_c('fieldset',[_c('legend',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.translations.heading))]),_vm._v(" "),_c('VfRow',{staticClass:"vf-flag-review__form-row"},[_c('VfColumn',{staticClass:"vf-flag-review__form-col",attrs:{"small":"12","medium":"6","large":"6"}},_vm._l((_vm.radioOptionsColumn1),function(radioCol1Item){return _c('VfRadio',{key:radioCol1Item.id,staticClass:"vf-flag-review__form-radio",attrs:{"name":"issue","label":radioCol1Item.text,"value":radioCol1Item.id},model:{value:(_vm.issue),callback:function ($$v) {_vm.issue=$$v},expression:"issue"}})}),1),_vm._v(" "),_c('VfColumn',{staticClass:"vf-flag-review__form-col",attrs:{"small":"12","medium":"6","large":"6"}},_vm._l((_vm.radioOptionsColumn2),function(radioCol2Item){return _c('VfRadio',{key:radioCol2Item.id,staticClass:"vf-flag-review__form-radio",attrs:{"name":"issue","label":radioCol2Item.text,"value":radioCol2Item.id},model:{value:(_vm.issue),callback:function ($$v) {_vm.issue=$$v},expression:"issue"}})}),1)],1)],1),_vm._v(" "),_c('VfInput',{staticClass:"vf-input--white-background vf-flag-review__input",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'resourceBundle'),"label":_vm.translations.emailPlaceholder,"name":'email',"required":true,"valid":!_vm.$v.email.$error,"error-message":!_vm.$v.email.required
              ? _vm.translations.emailRequiredText
              : _vm.translations.emailInvalidText},on:{"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('VfTextArea',{staticClass:"vf-input--white-background vf-flag-review__input",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'resourceBundle'),"label":_vm.translations.commentsPlaceholder,"valid":!_vm.$v.comments.$error,"name":"comments","required":_vm.requireComment},on:{"blur":function($event){return _vm.$v.comments.$touch()}},model:{value:(_vm.comments),callback:function ($$v) {_vm.comments=$$v},expression:"comments"}}),_vm._v(" "),_c('ThemeButton',{staticClass:"vf-flag-review-submit-button",attrs:{"color":"primary","size":"md","data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'resourceBundle')},on:{"click":_vm.flagReviewSubmit}},[_vm._v("\n          "+_vm._s(_vm.translations.flagButtonLabel)+"\n        ")]),_vm._v(" "),_c('ThemeButton',{staticClass:"vf-flag-review-cancel-button",attrs:{"color":"secondary","size":"md","data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'resourceBundle')},on:{"click":_vm.handleModalClose}},[_vm._v("\n          "+_vm._s(_vm.translations.cancelButtonLabel)+"\n        ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
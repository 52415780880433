









































import type { PropType } from 'vue';
import type {
  Address,
  AddressSelectionAdditionalOption,
} from '@vf/api-client/src/types';
import type { CheckoutBillingInfoTranslations } from '@vf/api-contract';

import { computed, defineComponent } from '@vue/composition-api';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'AddressSelection',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    /** Static data for shipping address labels */
    translations: {
      type: Object as PropType<CheckoutBillingInfoTranslations>,
      required: true,
    },
    /** Array of addresses saved for user */
    addresses: {
      type: Array as PropType<(Address & { disabled?: boolean })[]>,
      default: () => [],
    },
    /** Id of selected address */
    selectedAddressId: String,
    /** Id of selected additional option */
    selectedOption: String,
    /** Array of additional options in select. Object mast have two fields: value and label
     * value is the event name emitted by selection
     * label is the displayed text
     * [
     *   { value: 'open-address-modal', label: 'Add new address' },
     *   { value: 'set-same-as-shipping', label: 'Same as shipping address' },
     * ]
     */
    additionalOptions: {
      type: Array as PropType<AddressSelectionAdditionalOption[]>,
      default: () => [],
    },
    autofocusAddressSelect: Boolean,
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.addressSelection;

    const selectedAddressChanged = (id) => {
      const option = optionsList.value.find((o) => o.id === id);
      if (option.type === 'address') {
        emit('change', option.dataId);
      } else if (option.type === 'additionalOption') {
        if (option.dataId === 'set-same-as-shipping') {
          emit('change', option.dataId);
        }
        emit(option.dataId);
      }
    };

    const optionsList = computed(() => [
      ...(props?.addresses || []).map((address) => ({
        id: `address-${address.id}`,
        label: `${address.firstName} ${address.lastName}, ${
          address.addressLine1
        }, ${address.country || address.countryCode}`,
        type: 'address',
        dataId: address.id,
        ...(address.disabled && { disabled: address.disabled }),
        isProtected: true,
      })),
      ...(props?.additionalOptions || []).map((additionalOption) => ({
        id: `additionalOption-${additionalOption.value}`,
        label: additionalOption.label,
        type: 'additionalOption',
        dataId: additionalOption.value,
        isProtected: false,
      })),
    ]);

    const getSelectedId = computed(() => {
      if (props.selectedAddressId) {
        return `address-${props.selectedAddressId}`;
      } else if (props.selectedOption) {
        return `additionalOption-${props.selectedOption}`;
      } else {
        return 'additionalOption-new';
      }
    });

    return {
      theme,
      selectedAddressChanged,
      optionsList,
      getSelectedId,
    };
  },
});
























import { defineComponent } from '@vue/composition-api';
import type { LoyaltyVouchersResponse } from '@vf/api-contract';
import type { PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'LoyaltyRewardsExpiry',
  props: {
    accordion: {
      type: Boolean,
      default: false,
    },
    rewards: {
      type: Object as PropType<LoyaltyVouchersResponse>,
    },
  },
});

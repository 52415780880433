









import { defineComponent, computed } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { useAccount, useLoyalty, useI18n } from '@vf/composables';
import LoyaltyRewardsProgressAccordion from './LoyaltyRewardsProgressAccordion.vue';
import LoyaltyRewardsProgressDialog from './LoyaltyRewardsProgressDialog.vue';
import type { LoyaltyRewardsProgressTranslations } from '@vf/api-contract';
import type { PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'LoyaltyRewardsProgress',
  components: {
    LoyaltyRewardsProgressAccordion,
    LoyaltyRewardsProgressDialog,
  },
  props: {
    accordion: {
      type: Boolean,
      default: false,
    },
    translations: {
      type: Object as PropType<LoyaltyRewardsProgressTranslations>,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { currencySymbol } = useI18n(root);
    const { basicInformation } = useAccount(root);
    const { rewards } = useLoyalty(root);

    const increment = 100;
    const currency = currencySymbol.value || '$';
    const clamp = (val: number) => Math.min(Math.max(val, 0), 1);
    const normalize = (val: number, max: number, min: number) =>
      clamp((val - min) / (max - min));

    const points = computed(() => rewards.value.currentLoyaltyPoints);

    const vouchers = computed(() =>
      (rewards.value.applicableLoyaltyVouchers || []).map(
        (voucher, i, vouchers) => {
          const previousMarker = vouchers[i - 1]?.points || 0;

          return {
            ...voucher,
            amountInCurrency: currency + voucher.amountInCurrency,
            progress: normalize(
              Math.floor(points.value / increment) * increment,
              voucher.points,
              previousMarker
            ),
          };
        }
      )
    );

    const greeting = computed(() => {
      if (!basicInformation.value.firstName) return props.translations.greeting;
      return props.translations.greetingWithName.replace(
        '{{firstName}}',
        basicInformation.value.firstName
      );
    });

    const totalRewards = computed(() => {
      if (!vouchers.value) return currency + 0;
      const reward = [...vouchers.value]
        .reverse()
        .find((voucher) => voucher.available)?.amountInCurrency;
      return props.translations.totalRewards.replace(
        '{{currencyAmount}}',
        reward || currency + 0
      );
    });

    const summary = computed(() => {
      return props.translations.rewardsSummary.replace(
        '{{points}}',
        points.value?.toLocaleString('en-US')
      );
    });

    const full = computed(() => {
      if (!vouchers.value) return 0;
      const largestVoucher =
        vouchers.value[vouchers.value.length - 1]?.points || 0;
      return points.value >= largestVoucher + increment;
    });

    const data = computed(() => ({
      greeting: greeting.value,
      totalRewards: totalRewards.value,
      summary: summary.value,
      vouchers: vouchers.value,
      full: full.value,
    }));

    return {
      data,
      rewards,
    };
  },
});

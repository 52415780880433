var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vf-cart-products"},[_vm._l((_vm.cartProductSections),function(section){return [(section.visible)?_c('div',{key:section.label,staticClass:"vf-cart-section"},[(_vm.isMixedShippingAndPickup)?_c('div',{staticClass:"vf-cart-section__title"},[_c('span',{staticClass:"vf-cart-section__title_label"},[_vm._v("\n          "+_vm._s(section.label)+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"vf-cart-section__title_count"},[_vm._v("\n          "+_vm._s(section.countLabel)+"\n        ")])]):_vm._e(),_vm._v(" "),_vm._l((section.subSections),function(subSection,index){return [(subSection.items.length)?_c('div',{key:index,staticClass:"vf-cart-section__subsection"},[(subSection.label)?_c('div',{staticClass:"vf-cart-section__subtitle"},[_vm._v("\n            "+_vm._s(subSection.label)+"\n          ")]):_vm._e(),_vm._v(" "),_c('div',_vm._l((subSection.items),function(product){return _c('VfThemeProductCard',{key:product.id,attrs:{"product":product,"config":_vm.staticCartTheme.productCard,"is-disabled":_vm.isSpinnerVisible},on:{"click:open-menu":function($event){$event.stopPropagation();return _vm.handleOpenMenu($event, product)},"click:change-quantity":_vm.changeQuantityAction,"click:remove":_vm.removeAction,"click:save-for-later":_vm.addSaveForLaterAction,"click:edit":function($event){return _vm.editProduct(product)}},scopedSlots:_vm._u([{key:"errorMessages",fn:function(){return _vm._l((_vm.getMessagesByProductId(
                    _vm.customerFacingFlashes,
                    product.productId
                  )),function(ref){
                  var code = ref.code;
                  var message = ref.message;
return _c('div',{key:("flash-" + code)},[_vm._v("\n                  "+_vm._s(message)+"\n                ")])})},proxy:true},(product.productPromotions)?{key:"promotionDescription",fn:function(){return _vm._l((product.productPromotions),function(ref){
                  var calloutMsg = ref.calloutMsg;
                  var promotionId = ref.promotionId;
return _c('div',{key:promotionId,staticClass:"vf-cart-section__promo-callout"},[_vm._v("\n                  "+_vm._s(calloutMsg)+"\n                ")])})},proxy:true}:null,{key:"extraMessages",fn:function(){return [(_vm.getNotEligibleProductMessage(product))?_c('div',{staticClass:"vf-cart-section__message"},[_vm._v("\n                  "+_vm._s(_vm.getNotEligibleProductMessage(product))+"\n                  "),_c('VfIcon',{attrs:{"icon":"error","size":"xxs"}})],1):_vm._e()]},proxy:true},{key:"bottomContent",fn:function(){return [(_vm.isDeliverySelectorVisible(product))?[(_vm.isSmall)?[_c('div',{staticClass:"vf-cart-products__shipping-label"},[_c('span',[_vm._v("\n                        "+_vm._s(_vm.determineShippingMethodLabel(product.shippingMethod))+"\n                      ")]),_vm._v(" "),(_vm.isPickupOrSts(product.shippingMethod.code))?_c('span',{staticClass:"vf-cart-products__shipping-delivery-time"},[_c('span',[_vm._v(_vm._s(product.shippingMethod.deliveryTime))]),_vm._v(" "),_c('VfTooltip',{staticClass:"vf-delivery-method__tooltip",attrs:{"is-visible":_vm.isTooltipVisible === product.id},on:{"click-close":function($event){_vm.isTooltipVisible = null}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v("\n                            "+_vm._s(_vm.$t(
                                'deliveryMethodSelector.selectStoreTooltipContent'
                              ))+"\n                          ")]},proxy:true}],null,true)},[_c('VfIcon',{attrs:{"aria-label":_vm.$t(
                                'deliveryMethodSelector.selectStoreTooltipContent'
                              ),"icon":"info"},on:{"click":function($event){$event.preventDefault();_vm.isTooltipVisible = _vm.isTooltipVisible
                                ? null
                                : product.id}}})],1)],1):_vm._e()]),_vm._v(" "),(!_vm.isPickupOrSts(product.shippingMethod.code))?_c('div',{staticClass:"vf-cart-products__shipping-description"},[_vm._v("\n                      "+_vm._s(_vm.$getEDDLabel(
                          _vm.$root,
                          product.shippingMethod.deliveryTime
                        ))+"\n                    ")]):_c('ThemeLink',{staticClass:"vf-cart-products__open-store-selector",on:{"click":function($event){return _vm.openStoreSelector(product)}}},[_c('span',[_vm._v("@ "+_vm._s(_vm.getStoreName(product)))])]),_vm._v(" "),_c('ThemeLink',{staticClass:"vf-cart-products__change-method",on:{"click":function($event){_vm.deliveryMethodModalProduct = product}}},[_c('span',[_vm._v("\n                        "+_vm._s(_vm.isPickupOrSts(product.shippingMethod.code)
                            ? _vm.$t('cartProducts.changeMethodToHome')
                            : _vm.$t('cartProducts.changeMethodToPickup'))+"\n                      ")])])]:[_c('DeliveryMethodSelector',{attrs:{"product":product,"pickup-product":_vm.pickupProduct,"shipping-options":product.shippingOptions,"selected-shipping-option":_vm.getSelectedShippingOption(product.shippingOptions)},on:{"change:delivery-method":function (storeInfo, code) { return _vm.changeDeliveryMethod(product, storeInfo, code); },"open:store-selector":_vm.openStoreSelector}})],_vm._v(" "),(_vm.isPickupUnavailable(product.shippingOptions))?_c('div',{staticClass:"vf-cart-products__error-notification"},[_vm._v("\n                    "+_vm._s(_vm.$t('cartNotifications.PickupToSthTransition'))+"\n                  ")]):_vm._e()]:[_c('div',{staticClass:"vf-cart-products__shipping-description_main"},[_vm._v("\n                    "+_vm._s(_vm.$t('cartProducts.delivery'))+"\n                  ")]),_vm._v(" "),_c('div',{staticClass:"vf-cart-products__shipping-description"},[_vm._v("\n                    "+_vm._s(_vm.$getEDDLabel(_vm.$root, product.deliveryTime))+"\n                  ")])],_vm._v(" "),(_vm.staticCartTheme.giftOptionEnabled)?_c('GiftOptionSummary',{staticClass:"vf-cart-products__gift-option",attrs:{"product":product,"show-actions":""},on:{"click:open-modal":function($event){_vm.giftOptionProduct = product},"click:remove-gift-option":_vm.updateGiftOption}}):_vm._e(),_vm._v(" "),(_vm.employeeConnected)?_c('PriceOverride',{key:product.id,staticClass:"vf-cart-products__price-override",attrs:{"product":product},on:{"override-price":_vm.overrideProductPrice,"update-price":_vm.updateProductPrice,"reset-price":_vm.resetProductPrice}}):_vm._e()]},proxy:true}],null,true)})}),1)]):_vm._e()]})],2):_vm._e()]}),_vm._v(" "),(
      _vm.dynamicSlots.insideCartItems &&
      _vm.dynamicSlots.insideCartItems.children.length
    )?_c('CmsDynamicSlot',{staticClass:"vf-cart-products__espot",attrs:{"data":_vm.dynamicSlots.insideCartItems,"context-key":_vm.contextKey,"name":_vm.dynamicSlots.insideCartItems.props.name}}):_vm._e(),_vm._v(" "),_c('VfFlyoutContainer',{ref:"flyoutContainer"},[_c('MenuList',{attrs:{"items":_vm.flyoutContainerListItems}})],1),_vm._v(" "),(_vm.deliveryMethodModalProduct != null)?_c('DeliveryMethodModal',{attrs:{"product":_vm.deliveryMethodModalProduct},on:{"on-modal-close":function($event){_vm.deliveryMethodModalProduct = null},"change:delivery-method":function (storeInfo, code) { return _vm.changeDeliveryMethod(_vm.deliveryMethodModalProduct, storeInfo, code); },"open:store-selector":_vm.openStoreSelector}}):_vm._e(),_vm._v(" "),_c('StoreSelector',{attrs:{"visible":_vm.pickupProduct !== null,"pickup-item":_vm.pickupProduct},on:{"close":function($event){_vm.pickupProduct = null}}}),_vm._v(" "),(_vm.giftOptionProduct)?_c('GiftOption',{attrs:{"product":_vm.giftOptionProduct},on:{"close":function($event){_vm.giftOptionProduct = null},"save":_vm.updateGiftOption}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./ProductsListNoResults.vue?vue&type=template&id=6a308f72&scoped=true&"
import script from "./ProductsListNoResults.vue?vue&type=script&lang=ts&"
export * from "./ProductsListNoResults.vue?vue&type=script&lang=ts&"
import style0 from "./ProductsListNoResults.vue?vue&type=style&index=0&id=6a308f72&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a308f72",
  null
  
)

export default component.exports
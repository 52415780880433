








































































































import type { PropType } from 'vue';
import {
  computed,
  ref,
  onMounted,
  onUnmounted,
  watch,
  defineComponent,
  inject,
} from '@vue/composition-api';
import { validationMixin } from 'vuelidate';
import type {
  ProductSizesTranslations,
  WidthAttributeValue,
} from '@vf/api-contract';
import {
  useFindInStore,
  useGtm,
  useMonetate,
  useProduct,
  useProductInventory,
  useSectionNumeration,
  useValidation,
} from '@vf/composables';
import { ProductDetailViewViewType } from '@vf/composables/src/types/gtm';
import { addVariantOptionToUrlParams } from '@/helpers';
import useRootInstance from '@/shared/useRootInstance';
import { useUserData } from '../../cms/cmsUtils';

export default defineComponent({
  name: 'ProductAdditionalSizes',
  mixins: [validationMixin],
  props: {
    /** Flag to determine if default size from SFCC should be pre-selected */
    preselectDefaultSize: {
      type: Boolean,
      default: false,
    },
    /** Flag to determine if whole component should be hidden or not */
    hideComponent: {
      type: Boolean,
      default: false,
    },
    /** Flag to determine if product is displayed in swap cart item modal */
    isSwapCartItemModal: {
      type: Boolean,
      default: false,
    },
    /** Flag to determine if selected option displyed in option title */
    showSelectedVariationTitle: {
      type: Boolean,
      default: false,
    },
    /** Product variation role ['length', 'inseam', 'waist', 'dimension', 'width'] */
    role: {
      type: String,
      default: '',
    },
    translations: {
      type: Object as PropType<ProductSizesTranslations>,
      default: () => ({}),
    },
    /** Select with page type */
    contextKey: {
      type: String,
      default: 'product',
    },
    /** Prefix counter label */
    showNumeration: {
      type: Boolean,
    },
    /** Show divider on bottom of component */
    showBottomDivider: {
      type: Boolean,
      default: false,
    },
    /** Show divider on top of component */
    showTopDivider: {
      type: Boolean,
      default: false,
    },
    showSizesExpanded: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit, attrs }) {
    const { root } = useRootInstance();
    const size = ref('');
    const sizeLabel = ref('');
    const missingRole =
      'missing' + props.role.charAt(0).toUpperCase + props.role.slice(1);
    const {
      product,
      getProductDetails,
      configure,
      loading,
      availableAttributes,
      getColorByWidth,
      attributePriceVariationClickAction,
      getSortedVariantGrouping,
      isWideAvailable,
      priceData,
      attributesData,
    } = useProduct(root, props.contextKey);
    const { getExperienceDecision, addCustomVars } = useMonetate(
      root,
      props.contextKey
    );
    const { setValidation } = useValidation(
      root,
      props.role.toUpperCase() + '_SELECT'
    );
    const {
      removeNumberedSection,
      addNumberedSection,
      getSectionNumber,
    } = useSectionNumeration(root);
    const { resetData } = useFindInStore(root);
    const { getProductInventory } = useProductInventory(root, props.contextKey);
    const { dispatchEvent } = useGtm(root);
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    // To be cleaned up with GLOBAL15-63801
    const isVansPdpRedesignEnabled = inject('isVansPdpRedesignEnabled');

    let routeTimer = null;

    const themeConfig = root.$themeConfig?.productSizeSelect || {};

    const showRolePrice = computed(
      () =>
        product.value?.id &&
        product.value.displayPriceRange &&
        attributesData.value.attributeGroupingPrices === props.role
    );

    const roleAvailableVariants = computed(() => {
      return availableAttributes.value[props.role] || [];
    });

    const roleOptionsAttName = computed(() => {
      return attributesData.value.attributeOptionsAttName[props.role];
    });

    const showOption = computed(() => {
      const attrOptions = product.value?.[roleOptionsAttName.value];

      return attrOptions?.length >= themeConfig.minAttributeOptionsNumber;
    });

    const sectionId = attrs['data-id'] as string;

    const showOptionWatcher = (isVisible: boolean) =>
      isVisible
        ? addNumberedSection(sectionId)
        : removeNumberedSection(sectionId);

    watch(showOption, showOptionWatcher);

    if (!(!props.hideComponent && showOption.value)) {
      // improve performance
      removeNumberedSection(sectionId);
    }

    const headingModifier = computed(
      () => themeConfig.headingModifier || 'subtitle-6'
    );

    const notifyMissingSize = computed(
      () => !!product.value?.validation && product.value.validation[missingRole]
    );

    const replaceRoute = (path) => {
      clearTimeout(routeTimer);
      routeTimer = setTimeout(() => {
        // TODO: this timer is important cause setup is loaded multiple time
        root.$router
          .replace({
            path,
          })
          .catch(() => {
            // TODO: catch error, this happened because this instance is loaded multiple time, need investigation
          });
      }, 100);
    };

    const configureSize = (event, preselect = false) => {
      const hasSizeChanged = event.value !== size.value && size.value;
      attributePriceVariationClickAction(props.role);
      sizeLabel.value = event.label;
      size.value = event.value;
      if (props.contextKey === 'quickshop') {
        resetData();
      }
      emit('select', event);
      configure({ [props.role]: event.value });
      product.value.validation.missingSize = false;
      const userData = useUserData(root);
      addCustomVars(userData);
      if (isWideRole.value && hasSizeChanged && !preselect) {
        selectWidth(event.value);
      } else if (
        themeConfig.addVariantOptionToUrlQueryParams &&
        props.contextKey !== 'quickshop'
      ) {
        const unWatchRoute = watch(
          () => root.$route.query[props.role],
          () => {
            root.$route.query[props.role] && getExperienceDecision(true);
            unWatchRoute();
          }
        );
        const path = addVariantOptionToUrlParams(root, props.role, size.value);
        if (preselect) {
          replaceRoute(path);
        } else {
          root.$router.push({
            path,
          });
        }
      } else {
        getExperienceDecision(true);
      }
    };

    const availableSizes = computed(() => {
      if (!product.value) return [];
      let sizes = product.value[roleOptionsAttName.value] || [];

      if (
        !isWideRole.value &&
        product.value?.size?.value &&
        product.value?.color?.value
      ) {
        let variants = product.value?.variants.filter((vrn) => {
          return vrn.attributes.size === product.value?.size?.value;
        });
        sizes.forEach((sizeItem) => {
          const vrnt = variants.find(
            (item) =>
              item.attributes[props.role] === sizeItem.value &&
              product.value.color.value === item.attributes.color
          );
          sizeItem.available = vrnt?.stock?.inStock || false;
        });
      }

      if (sizes.length === 1 && !sizeLabel.value) {
        configureSize(sizes[0], true);
      }
      return sizes;
    });

    const getFormattedPrice = (price: number) => {
      return root.$formatPrice(price, priceData.value.currency);
    };

    const isWideRole = computed(() => {
      return props.role === 'width' && isWideAvailable.value;
    });

    const selectWidth = async (widthValue: WidthAttributeValue) => {
      const newColor = getColorByWidth(widthValue);
      if (props.contextKey !== 'quickshop') {
        root.$router.push({ path: newColor.pageURL });
      } else {
        getProductDetails(newColor.slug);
        await getProductInventory(newColor.slug);
        dispatchEvent({
          eventName: 'productDetailView',
          composablesContexts: { useProduct: props.contextKey },
          overrideAttributes: {
            viewType: ProductDetailViewViewType.QUICK_VIEW,
          },
        });
      }
    };

    const variantsGroupedBySizePrice = computed(() => {
      return getSortedVariantGrouping(props.role);
    });

    const availableSizesRange = (obj) => {
      return availableSizes.value.filter((el) => {
        return obj.options
          .map((prop) => {
            return prop.attributes[props.role] === el.value;
          })
          .some(Boolean);
      });
    };

    const selectDefault = () =>
      selectDefaultImplementation({ configure, product, props });

    const productWatcher = (newSize, oldSize) => {
      if (newSize?.value === oldSize?.value) {
        return;
      }

      if (!newSize) {
        size.value = '';
        sizeLabel.value = '';
      } else {
        size.value = newSize.value;
        sizeLabel.value = newSize.label;
      }
    };

    onMounted(() => {
      if (!product.value?.[props.role] && props.preselectDefaultSize) {
        selectDefault();
      }

      watch(() => product.value[props.role], productWatcher, {
        immediate: true,
      });

      onMountedImplementation({
        configureSize,
        product,
        props,
        root,
      });
    });

    onUnmounted(() => {
      clearTimeout(routeTimer);
    });

    const mainTitle = computed(() => {
      const prefixLabel = getSectionNumber(attrs['data-id'] as string);
      return props.showNumeration && prefixLabel
        ? `${prefixLabel}. ${props.translations.sizeLabel}`
        : props.translations.sizeLabel;
    });

    return {
      loading,
      mainTitle,
      product,
      configure,
      notifyMissingSize,
      availableSizes,
      configureSize,
      headingModifier,
      size,
      sizeLabel,
      setValidation,
      showOption,
      roleAvailableVariants,
      themeConfig,
      variantsGroupedBySizePrice,
      availableSizesRange,
      showRolePrice,
      getFormattedPrice,
      selectDefault,
      productWatcher,
      showOptionWatcher,
      isWideRole,
      isCoreRedesignEnabled,
      isVansPdpRedesignEnabled,
    };
  },
  mounted() {
    if (this.showOption) {
      this.setValidation(this.$v);
    }
  },
  validations() {
    return {
      [this.role]: {
        customRequired: () => {
          return this.product[this.role] !== null;
        },
      },
    };
  },
});

const onMountedImplementation = ({ configureSize, product, props, root }) => {
  let variantId = root.$route.query['variant'] ?? null;
  const lengthQuery =
    props.role === 'length' && root.$route?.query?.length
      ? root.$route.query.length
      : null;

  if (variantId) {
    variantId = decodeURIComponent(variantId as string).toUpperCase();
    const productVariant = product.value.variants.find(
      (element) => element.id === variantId
    );
    const attrOptValue = productVariant?.attributes?.[props.role];
    if (attrOptValue) {
      const attrOption = product.value.attributes
        .find((attr) => attr.code === props.role)
        .options.find((opt) => opt.value === attrOptValue);
      configureSize(attrOption, true);
    }
  }

  if (lengthQuery) {
    const selectedLength = product.value.lengths?.find(
      (l) => l.value === lengthQuery
    );
    configureSize(selectedLength, true);
  }
};

const selectDefaultImplementation = ({ configure, product, props }) => {
  const { variant, attributes } = product.value || {};

  const selectedAttributeName = variant?.attributes?.[props.role];

  const selectedAttributes =
    attributes?.find(({ code }) => code === props.role)?.options || [];

  const preSelectedAttr = selectedAttributes.find(
    ({ value, available }) => value === selectedAttributeName && available
  );

  preSelectedAttr && configure({ [props.role]: preSelectedAttr.value });
};















































import type { PropType } from 'vue';
import {
  ref,
  reactive,
  defineComponent,
  toRefs,
  onMounted,
  onUnmounted,
} from '@vue/composition-api';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import type { CheckGiftCardBalanceFormTranslations } from '@vf/api-contract';
import {
  useCart,
  useNotification,
  useValidation,
  useReCaptcha,
  useGiftCards,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { getGiftCardFormValidationPatterns } from '@vf/composables/src/utils/giftCard';

export default defineComponent({
  name: 'CheckGiftCardBalanceForm',
  mixins: [validationMixin],
  props: {
    translations: {
      type: Object as PropType<CheckGiftCardBalanceFormTranslations>,
      required: true,
    },
  },
  setup() {
    const { root } = useRootInstance();
    const { checkGiftCardBalance } = useCart(root);
    const { clearNotifications } = useNotification(root);
    const { setValidation, $v } = useValidation(root, 'CHECK_GIFT_CARD_FORM');
    const { setGiftCardBalance } = useGiftCards(root);
    const recaptcha = useReCaptcha(root);

    const state = reactive({
      cardNumber: null,
      pin: null,
    });

    const buttonIsDisabled = ref(false);

    const submitForm = async () => {
      buttonIsDisabled.value = true;

      setGiftCardBalance({
        cardNumber: null,
        balance: null,
      });

      clearNotifications();

      try {
        const recaptchaToken = await recaptcha.executeRecaptcha('giftCard');
        const response = await checkGiftCardBalance({
          cardNumber: state.cardNumber,
          pin: state.pin,
          recaptcha: recaptchaToken,
        });

        if (response?.data?.success) {
          setGiftCardBalance({
            cardNumber: state.cardNumber,
            balance: response.data.data.balance,
          });
        }

        state.cardNumber = '';
        state.pin = '';
      } finally {
        $v.value.$reset();
        buttonIsDisabled.value = false;
      }
    };

    const giftCardValidation = getGiftCardFormValidationPatterns(
      root.$getEnvValueByCurrentLocale<{
        cardNumberLengths: number[];
        pinLengths: number[];
      }>('GIFT_CARD')
    );

    onMounted(() => recaptcha.showBadge());
    onUnmounted(() => {
      setGiftCardBalance({
        cardNumber: null,
        balance: null,
      });

      recaptcha.hideBadge();
    });

    return {
      ...toRefs(state),
      submitForm,
      setValidation,
      buttonIsDisabled,
      giftCardValidation,
    };
  },
  mounted() {
    this.setValidation(this.$v);
  },
  validations: {
    cardNumber: {
      required,
      cardNumber: (value, vm) =>
        new RegExp(vm.giftCardValidation.cardNumber, 'g').test(value),
    },
    pin: {
      required,
      pin: (value, vm) =>
        new RegExp(vm.giftCardValidation.pin, 'g').test(value),
    },
  },
});










































































































































import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from '@vue/composition-api';
import { mask, masker, tokens } from 'ke-the-mask';
import { useUserStore } from '@vf/composables/src/store/user';
import useValidators from '@vf/composables/src/useValidators';
import { parse as parseE164phone } from '@/helpers/addressBook/e164Utils';

import useRootInstance from '@/shared/useRootInstance';
import {
  useGoogleAutocomplete,
  useUtilities,
  useBasicInformation,
} from '@vf/composables';
import getAddressFormConfig from '@/helpers/addressBook/getAddressFormConfig';
import type { AddressFormTranslations } from '@/helpers/addressBook/types';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

import type { AddressFormContext, AddressModel } from './addressModel';
import { addressModel } from './addressModel';

export default defineComponent({
  name: 'AddressForm',
  directives: { mask },
  props: {
    contextName: {
      type: String as PropType<AddressFormContext>,
      default: 'shippingForm',
    },
    /** Shipping address v-model */
    value: {
      type: Object as PropType<AddressModel>,
      default: () => ({ ...addressModel }),
    },
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const { getCountryList, getPhoneInputCountryList } = useUtilities(root);
    const { customerEmail } = useBasicInformation(root);
    const userStore = useUserStore(root);
    const theme = root.$themeConfig.AddressForm;
    const hasChanges = ref(false);
    const phoneCountryCode = ref('+1');
    // TODO: GLOBAL15-61059 remove after redesign core
    const { isCoreRedesignEnabled } = useFeatureFlagsStore();

    const locale = root.$i18n.locale;
    const localeCountry = (root.$getEnvValueByCurrentLocale(
      'COUNTRY'
    ) as string).toUpperCase();

    const hasShippingContext = computed(
      () => props.contextName === 'shippingForm'
    );

    // model holds the form values
    const model = reactive({ ...addressModel });

    watch(model, (newModel) => emit('edit', newModel), { deep: true });

    // a copy of the object model used to track internal changes
    const modelStamp = {};

    // formConfig holds meta information about how the form values should be treated:
    const formConfig = reactive({
      formId: null,
      options: null,
      hints: null,
      masks: null,
      transformers: null,
      types: null,
      validation: null,
      attrs: null,
    });
    // countries holds the list of countries used in the dropdown and phone input
    const countries = reactive([]);
    const phoneCountries = reactive([]);

    // provinces holds the list of provinces used in the dropdown, if applicable
    const provinces = computed(() => formConfig.options?.province || []);
    const phoneMask = computed(() => formConfig.masks?.phone || '###-###-####');
    const postalCodeMask = computed(() =>
      formConfig.masks?.postalCode.length ? formConfig.masks.postalCode : null
    );

    // do we really need this? why not just use $text.province?
    const provinceLabel = computed(
      () => formConfig.options?.fieldLabelKey || root.$t('addressForm.province')
    );
    const isProvinceTextField = computed(() => !provinces.value.length);

    const { validate, validationFields, reset } = useValidators(
      () => {
        // Send to parse function, phone without country code
        const phoneWithoutCode = model.phone?.startsWith(phoneCountryCode.value)
          ? model.phone.slice(phoneCountryCode.value.length)
          : model.phone;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, phone] = parseE164phone(phoneWithoutCode);
        /* This is needed because API validation rules don't take into account the country code
          but the numbers returned from the API and the address book include it */
        return {
          ...model,
          phone: masker(phone, phoneMask.value, true, tokens),
        };
      },
      computed(() => formConfig.validation)
    );

    const onInputBlur = (field) => {
      if (formConfig.transformers?.[field]) {
        model[field] = formConfig.transformers[field](model[field] ?? '');
      }
      validate(field);
      const valid = validationFields.value[field].valid;
      if (!valid) return;

      if (modelStamp[field] !== model[field]) {
        hasChanges.value = true;
        emit('change', [{ ...model }, field]);
        Object.assign(modelStamp, model);
      }
    };

    // Shorcut for v-bind on the form fields
    // it would probably make more sense if we can add the validation, mask, events and v-model here too
    const fieldAttrs: Record<string, any> = computed(() => {
      return Object.keys(addressModel).reduce((acc, fieldName) => {
        acc[fieldName] = {
          class: 'address-form__input',
          label: root.$t(`addressForm.${fieldName}`),
          type: formConfig.types?.[fieldName],
          name: fieldName,
          ...(formConfig.attrs?.[fieldName] || {}),
        };
        return acc;
      }, {});
    });

    // Assing the props values to the form model
    // called on init function and also on props change
    const assignPropsToModel = () => {
      Object.assign(model, addressModel, props.value);
      model.country = props.value.country || localeCountry;
      model.email = props.value.email || customerEmail.value;
      Object.assign(modelStamp, model);
    };

    // Loads the i18n data for the address form
    // called on init and on country change
    const assignFormConfig = async () => {
      const data = await getAddressFormConfig(
        root,
        model.country,
        locale,
        props.contextName,
        (root.$t('addressForm') as unknown) as AddressFormTranslations
      );
      Object.assign(formConfig, data);
    };

    // The init function will be called just once at the setup
    // wrapped in an async function to be able to use await
    const init = async () => {
      countries.push(...(await getCountryList(locale)));
      const countryList = await getPhoneInputCountryList(locale);
      if (props.contextName !== 'shippingForm') {
        phoneCountries.push(...countryList);
      } else {
        phoneCountries.push(
          ...countryList.filter((c) => c.countryCode === localeCountry)
        );
      }
      assignPropsToModel();
      await assignFormConfig();
    };

    // on country change,
    // clear all the address related fields on the model. TODO: Should we add the zip code?
    // recall the getAddressFormConfig
    const onCountryChange = async (e) => {
      model.country = e.target.value;
      model.province = null;
      await assignFormConfig();
    };
    const handleCountryPrefixChanged = (value) => {
      phoneCountryCode.value = value;
    };

    // on props change,
    // update the internal model
    // and re-call the getAddressFormConfig
    watch(props, async () => {
      assignPropsToModel();
      await assignFormConfig();
    });

    // Google autocomplete
    const {
      loadGoogleApisScript,
      unloadGoogleApisScript,
      setupGoogleAutocomplete,
    } = useGoogleAutocomplete(root);

    const onGoogleAddressFill = () => {
      reset(['addressLine1', 'addressLine2', 'city', 'province', 'postalCode']);
      emit('change-on-autocomplete', { ...model });
    };

    onMounted(async () => {
      await init();
      await loadGoogleApisScript();
      setupGoogleAutocomplete(
        'addressline1',
        'addressline2',
        model,
        [localeCountry.toLowerCase()],
        onGoogleAddressFill
      );
    });
    onUnmounted(() => unloadGoogleApisScript());

    return {
      countries,
      fieldAttrs,
      formConfig,
      hasChanges: computed(() => hasChanges.value), // Expose this to the parent component to be able to track the form status. Computed => read only
      isCoreRedesignEnabled,
      isProvinceTextField,
      localeCountry,
      model,
      onCountryChange,
      onInputBlur,
      phoneCountries,
      phoneMask,
      postalCodeMask,
      provinceLabel,
      provinces,
      showCountry: computed(() => !hasShippingContext.value),
      showPhoneCountry: theme.showPhoneInputCountry,
      showSaveAddress: computed(() => !model.id && userStore.loggedIn),
      showEmailSubscription: computed(
        () => !userStore.loggedIn && hasShippingContext.value
      ),
      validate,
      validationFields,
      handleCountryPrefixChanged,
    };
  },
});















































































import type { CartLineItem } from '@vf/api-client';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import {
  computed,
  watch,
  defineComponent,
  onUnmounted,
} from '@vue/composition-api';
import useLoader from '@/shared/useLoader';
import { ROUTES, useCart, useI18n, useGtm, useRouting } from '@vf/composables';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import { pluralize } from '@/helpers/pluralize';
import useRootInstance from '@/shared/useRootInstance';
import { isClient } from '@vf/shared/src/utils/helpers';
import { focusTrap } from '@vf/shared/src/utils/directives';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'MiniCart',
  directives: { focusTrap },
  setup(_, { refs }) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.staticCart;
    const {
      isCoreRedesignEnabled,
      configMinicartRemoveIcon,
    } = useFeatureFlagsStore();

    const { localePath } = useI18n(root);
    const { dispatchEvent } = useGtm(root);
    const { previousRoute } = useRouting(root);
    const { showSpinner, hideSpinner, isSpinnerVisible } = useLoader();
    const {
      cart,
      cartItems,
      totalItems,
      deleteItem,
      isMinicartOpen,
      setMiniCart,
    } = useCart(root);

    const itemsText = computed(() => {
      return `${totalItems.value} ${pluralize(
        root.$t('miniCartNew.item') as string,
        totalItems.value
      )}`;
    });

    const totalAmount = computed(() => {
      return root.$formatPrice(
        cart.value.totals?.totalWithoutTax,
        cart.value.currency
      );
    });

    const paths = {
      checkout: localePath(ROUTES.CHECKOUT()),
      cart: localePath(ROUTES.CART()),
    };

    const close = () => {
      setMiniCart(false);
      root.$emit('click:close-minicart');
    };

    const removeItem = async (product: CartLineItem) => {
      showSpinner();
      await deleteItem(product.id);
      hideSpinner();

      dispatchEvent({
        ...getEventFromTemplate('cart:remove', {}),
        composablesContexts: { useProduct: 'quickShop' },
        overrideAttributes: {
          product: product,
          quantity: product.qty,
        },
      });

      dispatchEvent(getEventFromTemplate('cart:update', {}));
    };

    watch(previousRoute, close, { immediate: true });

    const closeKeydownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape' || e.key === 'Esc') {
        close();
      }
    };

    const clearLocks = () => {
      clearAllBodyScrollLocks();
      document.removeEventListener('keydown', closeKeydownHandler);
      // Fix body scroll for iOS for 12 version and older
      if (/iP(ad|hone)/.test(navigator.userAgent)) {
        document.body.classList.remove('block-scroll');
      }
    };

    const isBopisInfoOnMinicart = root.$getConfigValueByCurrentLocale(
      'IS_BOPIS_INFO_ON_MINICART'
    );

    watch(isMinicartOpen, (value) => {
      if (!isClient) return;
      if (value) {
        clearLocks();
        root.$nextTick(() => {
          if (!refs.minicartRef) return;
          (refs.minicartRef as HTMLElement).focus();
          root.$nextTick(() => {
            // Specifically, the target element is the one we would like to allow scroll on (NOT a parent of that element).
            disableBodyScroll(refs.minicartRef as HTMLElement);
            // Fix body scroll for iOS for 12 version and older
            if (/iP(ad|hone)/.test(navigator.userAgent)) {
              document.body.classList.add('block-scroll');
            }
          });
          document.addEventListener('keydown', closeKeydownHandler);
        });
      } else {
        clearLocks();
      }
    });

    onUnmounted(() => {
      clearLocks();
    });

    return {
      theme,
      isCoreRedesignEnabled,
      itemsText,
      paths,
      products: computed(() =>
        [...cartItems.value].reverse().sort((a, b) => (b.bonus ? -1 : 1))
      ),
      totalItems,
      totalAmount,
      close,
      removeItem,
      isMinicartOpen,
      isSpinnerVisible,
      isBopisInfoOnMinicart,
      configMinicartRemoveIcon,
    };
  },
});





























































































































































































































import type { PropType } from 'vue';
import { storeToRefs } from 'pinia';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import {
  computed,
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api';
import type { OrderStatusFormTranslations } from '@vf/api-contract';
import {
  ROUTES,
  useI18n,
  useOrders,
  useNotification,
  useReturns,
  useValidation,
  useReCaptcha,
} from '@vf/composables';
import { useUserStore } from '@vf/composables/src/store/user';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import useRootInstance from '@/shared/useRootInstance';
import { useOrderStatusStore } from '@vf/composables/src/store/orderStatus';

export default defineComponent({
  name: 'OrderStatusForm',
  mixins: [validationMixin],
  props: {
    translations: {
      type: Object as PropType<OrderStatusFormTranslations>,
      require: true,
    },
    validations: {
      type: Object,
      default: () => ({}),
    },
    showTooltip: Boolean,
    showRequiredFieldsText: Boolean,
    showRadioButtonsSection: Boolean,
    showCancelButton: { type: Boolean, default: true },
    tooltipImageUrl: String,
    tooltipImageHeight: String,
    tooltipImageWidth: String,
    tooltipImageAltText: String,
    signInLink: String,
  },
  setup(props) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.orderStatus;

    const { localePath } = useI18n(root);
    const { addNotification, clearNotifications } = useNotification(root);
    const { findGuestOrder } = useOrders(root);
    const { setOrderType } = useReturns(root);

    const userStore = useUserStore(root);
    const { loggedIn } = storeToRefs(userStore);
    const { areNarvarReturnsEnabled } = useFeatureFlagsStore();

    const orderTranslations = computed(() => {
      if (areNarvarReturnsEnabled)
        return {
          ...props.translations,
          ...props.translations.narvar,
        };
      return props.translations;
    });

    const { setValidation, $v } = useValidation(root, 'ORDER_STATUS_FORM');
    const recaptcha = useReCaptcha(root);

    const orderNumber = ref(null);
    const emailOrLastName = ref(null);
    const zipCode = ref(null);
    const orderType = ref('purchase');
    const tooltipShown = ref(false);

    const link = computed<string>(() =>
      loggedIn.value ? '' : props.signInLink ?? localePath(ROUTES.SIGN_IN())
    );

    const fetchingOrder = ref(false);

    const getOrderInfo = async ({
      orderNumber,
      emailOrLastName,
      zipCode,
      orderType,
    }) => {
      clearNotifications();

      try {
        const searchByLastName = theme.searchByKey === 'lastName';

        if (props.showRadioButtonsSection) {
          setOrderType(orderType);
        }

        await findGuestOrder(
          orderNumber,
          emailOrLastName,
          searchByLastName,
          zipCode
        );

        const orderStatusStore = useOrderStatusStore();
        orderStatusStore.$patch({
          orderNumber,
          [theme.searchByKey]: emailOrLastName,
          zipCode,
        });

        root.$router.push({
          path: localePath(ROUTES.ORDER_DETAILS_GUEST()),
        });
      } catch (err) {
        clearData();

        addNotification({
          message: props.translations.errorMessageText,
          type: 'danger',
        });
      } finally {
        fetchingOrder.value = false;
      }
    };

    const clearData = () => {
      orderNumber.value = null;
      emailOrLastName.value = null;
      zipCode.value = null;
      $v.value.$reset();
    };

    const getOrderInformation = () => {
      $v.value.$touch();
      if (!$v.value.$anyError) {
        fetchingOrder.value = true;

        getOrderInfo({
          zipCode: zipCode.value,
          orderNumber: orderNumber.value,
          emailOrLastName: emailOrLastName.value,
          orderType: orderType.value,
        });
      }
    };

    const hasHistory = () => {
      return window.history.length > 2;
    };

    const handleCancel = () => {
      hasHistory() ? root.$router.back() : root.$router.push(link.value);
    };

    const checkOrderNumberByRegex = (number) => {
      if (!number) return true;
      const reg =
        props.validations && props.validations.orderNumberRegex !== ''
          ? new RegExp(props.validations.orderNumberRegex)
          : /^\d{1,256}$/;
      if (reg.test(number)) {
        return true;
      }
      return false;
    };

    const checkEmailOrLastNameFieldByRegex = (val) => {
      if (!val) return true;
      const reg = props.validations.emailOrLastNameRegex
        ? new RegExp(props.validations.emailOrLastNameRegex)
        : null;
      if (reg?.test(val)) {
        return true;
      }
      return email(val);
    };

    const openInNewModal = computed(() => {
      return link.value.includes('data-id');
    });

    const orderNumberPattern = () => {
      return props.validations.orderNumberRegex || '[\\d*]';
    };

    const validateOrderNumberInput = (e) => {
      const tooltipFocused = e.relatedTarget?.closest('.vf-tooltip');
      if (!tooltipFocused) $v.value.orderNumber.$touch();
    };

    const { zipCodeError } = props.translations.validationMessages;
    const errorMessage = zipCodeError || '{{zipCodeFormat}}';
    const zipCodeErrorMessage = errorMessage.replace(
      '{{zipCodeFormat}}',
      props.validations.zipCodeFormat
    );

    const checkZipByRegex = (zipCode: string) => {
      const { zipCodeRegex } = props.validations;

      const regex = zipCodeRegex
        ? new RegExp(zipCodeRegex, 'i')
        : /^[0-9]{5}(?:-[0-9]{4})?$/;

      if (!regex.test(zipCode)) {
        return false;
      }

      return true;
    };

    onMounted(() => recaptcha.showBadge());
    onUnmounted(() => recaptcha.hideBadge());

    return {
      theme,
      areNarvarReturnsEnabled,
      orderTranslations,
      orderNumber,
      emailOrLastName,
      orderType,
      link,
      handleCancel,
      checkOrderNumberByRegex,
      checkEmailOrLastNameFieldByRegex,
      validateOrderNumberInput,
      getOrderInformation,
      clearData,
      openInNewModal,
      tooltipShown,
      getOrderInfo,
      orderNumberPattern,
      setValidation,
      checkZipByRegex,
      zipCodeErrorMessage,
      zipCode,
      fetchingOrder,
    };
  },
  mounted() {
    this.setValidation(this.$v);
  },
  validations() {
    return {
      emailOrLastName: {
        required,
        checkEmailOrLastName: this.checkEmailOrLastNameFieldByRegex,
      },
      orderNumber: {
        required,
        checkOrderNumber: this.checkOrderNumberByRegex,
      },
      zipCode: {
        required,
        checkZip: this.checkZipByRegex,
      },
    };
  },
});

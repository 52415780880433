








































































































import type { PropType } from 'vue';
import type {
  AddressFormTranslations,
  CreditCardFormTranslations,
  CreditCardListingTranslations,
} from '@vf/api-contract';

import {
  ref,
  onMounted,
  onUnmounted,
  watch,
  defineComponent,
  computed,
  onBeforeUnmount,
} from '@vue/composition-api';
import CreditCardForm from '@/components/payment/CreditCardForm.vue';
import CreditCardMicroform from '@/components/static/checkout/CreditCardMicroform.vue';
import AddressSelection from '@/components/address/AddressSelection.vue';
import { usePaymentStore } from '@vf/composables/src/store/payment';
import {
  useI18n,
  useAccount,
  useValidation,
  useCyberSource,
  ROUTES,
  useReCaptcha,
  usePaymentProvider,
} from '@vf/composables';
import { scrollTo } from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';
import useLoader from '@/shared/useLoader';

export default defineComponent({
  name: 'SmartCreditCardForm',
  components: {
    AddressSelection,
    CreditCardForm,
    CreditCardMicroform,
  },
  props: {
    /** Name of the context where component should be displayed */
    contextName: {
      type: String,
      default: 'credit-cards',
    },
    contextKey: {
      type: String,
      default: 'page-content',
    },
    translations: {
      type: Object as PropType<CreditCardListingTranslations>,
      required: true,
    },
    creditCardFormTranslations: {
      type: Object as PropType<CreditCardFormTranslations>,
      required: true,
    },
    addressFormTranslations: {
      type: Object as PropType<AddressFormTranslations>,
      required: true,
    },
    /** Prop to decide whether show action buttons or not */
    showActionButtons: {
      type: Boolean,
      default: true,
    },
    /** Prop to decide whether show email helper text or not */
    showEmailHelperText: {
      type: Boolean,
      default: true,
    },
    /** Prop to decide whether show phone helper text or not */
    showPhoneHelperText: {
      type: Boolean,
      default: true,
    },
    /** Uri to card CVV tooltip hint image */
    securityCodeTooltipImage: {
      type: String,
      default: () => '',
    },
    /** Url to card list page also need to display head section */
    accountCreditCardsListLink: {
      type: String,
      default: '',
    },
    /** Prop to decide whether show credit card save checkbox */
    showSaveCardCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { localePath } = useI18n(root);
    const { showSpinner, hideSpinner } = useLoader();
    const {
      getAddresses,
      addAddress,
      updateAddress,
      savePaymentInstrument,
      newPaymentInstrumentAdded,
      isAccountSaveCreditButtonDisabled,
    } = useAccount(root);

    const paymentStore = usePaymentStore(root);
    const recaptcha = useReCaptcha(root);

    const addNewAddressOptionValue = 'new';
    const addNewAddressOption = computed(() => ({
      value: addNewAddressOptionValue,
      label: props.translations.addNewAddress,
    }));

    const isCreditCardFormVisible = ref();

    onMounted(async () => {
      recaptcha.showBadge();
      isAccountSaveCreditButtonDisabled.value = true;
      if (!paymentStore.service) {
        showSpinner();
        await paymentStore.getSession();
      }

      if (paymentStore.provider === 'CYBERSOURCE') {
        isCreditCardFormVisible.value = true;
      } else {
        const {
          load,
          initService,
          showCreditCardNumberSuccess,
          showExpirationDateSuccess,
          showSecurityNumberSuccess,
        } = usePaymentProvider(root);

        showSpinner();
        await load();
        await initService();
        isCreditCardFormVisible.value = true;

        const showAllFieldsSuccess = computed(() => {
          return (
            showCreditCardNumberSuccess.value &&
            showExpirationDateSuccess.value &&
            showSecurityNumberSuccess.value
          );
        });
        watch(
          showAllFieldsSuccess,
          (val) => (isAccountSaveCreditButtonDisabled.value = !val)
        );
      }
      hideSpinner();
    });

    onUnmounted(() => recaptcha.hideBadge());
    onBeforeUnmount(() => usePaymentProvider(root).unload());

    const selectedAddress = ref({ addressId: '', option: '' });
    const userBillingAddresses = getAddresses('B');
    const { $v: $vADDRESS } = useValidation(root, 'ADDRESS_FORM');
    const addressFormKey = ref(
      root.$getEnvValueByCurrentLocale<string>('COUNTRY').toUpperCase()
    );

    function goToError(errors: HTMLElement[]) {
      setTimeout(() => {
        if (errors.length >= 1) {
          scrollTo({ top: errors[0].offsetTop - 120 });
        }
      }, 100);
    }

    const openCyberSourceError = () => {
      setTimeout(() => {
        $v.value && $v.value.$touch();
        $v.value.securityCode?.$invalid &&
          cardErrors.value.push({ location: 'securityCode' });
        collectErrors();
        goToError(errors.value);
      }, 100);
    };

    const fillAddressForm = (addressData) => {
      addressModelData.value = {
        id: addressData.id,
        addressLine1: addressData.addressLine1,
        addressLine2: addressData.addressLine2,
        approachType: 'B',
        city: addressData.city,
        country: addressData.country,
        email: addressData.email,
        firstName: addressData.firstName,
        lastName: addressData.lastName,
        main: addressData.main,
        phone: addressData.phone,
        postalCode: addressData.postalCode,
        province: addressData.province,
        recipientContactEmail: addressData.recipientContactEmail,
        recipientContactPhone: addressData.recipientContactPhone,
        recipientFirstName: addressData.recipientFirstName,
        recipientLastName: addressData.recipientLastName,
      };
      validateCyberSourceForm($vADDRESS);
    };

    const selectAddressChangeHandler = (addressId) => {
      if (addressId === addNewAddressOptionValue) {
        selectedAddress.value.addressId = '';
        selectedAddress.value.option = addNewAddressOptionValue;
        fillAddressForm({
          id: null,
          addressLine1: null,
          addressLine2: null,
          approachType: 'B',
          city: null,
          country: root
            .$getEnvValueByCurrentLocale<string>('COUNTRY')
            .toUpperCase(),
          email: null,
          firstName: null,
          lastName: null,
          main: !getAddresses('B').value.length,
          phone: null,
          postalCode: null,
          province: null,
          recipientContactEmail: null,
          recipientContactPhone: null,
          recipientFirstName: null,
          recipientLastName: null,
        });
        return;
      }
      const selectedBillingAddress = userBillingAddresses.value.find(
        (address) => address.id === addressId
      );
      selectedAddress.value = {
        addressId: selectedBillingAddress.id,
        option: '',
      };
      if (isCountryChanging(selectedBillingAddress.country)) {
        setAddressFormKey(selectedBillingAddress.country);
      }
      fillAddressForm(selectedBillingAddress);
    };

    const isCountryChanging = (countryCode) => {
      return addressFormKey.value !== countryCode;
    };

    const setAddressFormKey = (countryCode) => {
      // When key changes addressForm is destroyed & created again
      // and we ensure the new country is set hence provinces are fetched
      addressFormKey.value = countryCode;
    };

    const cancel = () => {
      root.$router.push(localePath(ROUTES.CREDIT_CARDS()));
    };

    const addressModelData = ref<Record<string, any>>({
      id: null,
      addressLine1: null,
      addressLine2: null,
      approachType: 'B',
      city: null,
      country: root
        .$getEnvValueByCurrentLocale<string>('COUNTRY')
        .toUpperCase(),
      email: null,
      firstName: null,
      lastName: null,
      main: !getAddresses('B').value.length,
      phone: null,
      postalCode: null,
      province: null,
      recipientContactEmail: null,
      recipientContactPhone: null,
      recipientFirstName: null,
      recipientLastName: null,
    });

    watch(newPaymentInstrumentAdded, (newValue) => {
      if (newValue) {
        root.$router.push(localePath(ROUTES.CREDIT_CARDS()));
        hideSpinner();
      }
    });

    const goToCardForm = () => {
      const formElement: HTMLElement = document.querySelector(
        '.cc-form__credit-card-form'
      );
      if (!formElement) return;
      scrollTo({ top: formElement.offsetTop });
    };

    const {
      validateCyberSourceForm,
      cardErrors,
      isCcIconBlurred,
    } = useCyberSource(root);

    const { $v } = useValidation(root, 'CYBER_SOURCE_FORM');
    const errors = ref([]);

    watch(cardErrors, (newValue, oldValue) => {
      if (newValue.length != 0 && newValue.length > oldValue.length) {
        goToCardForm();
      }
    });

    const collectErrors = () => {
      errors.value = (document.querySelectorAll(
        '.vf-input--invalid, .vf-select--is-invalid, .vf-notification--invalid, .credit-card-form-error'
      ) as unknown) as HTMLElement[];
    };

    const submitForm = async (formData) => {
      showSpinner();
      try {
        if (paymentStore.provider === 'CYBERSOURCE') {
          if (!validateCyberSourceForm($v)) {
            openCyberSourceError();
            return;
          }

          collectErrors();
          if (errors.value.length) {
            goToError(errors.value);
            return;
          }
        } else if (paymentStore.provider === 'ADYEN') {
          const {
            preparePaymentData,
            form: { validationFields },
          } = usePaymentProvider(root);
          preparePaymentData();
          const fields = ['cardNumber', 'expirationDate', 'securityCode'];
          if (fields.some((field) => validationFields.value[field].invalid)) {
            goToCardForm();
            return;
          }
        }

        const data = {
          ...formData,
          approachType: 'B',
        };
        let response;
        if (formData.id) {
          response = await updateAddress(formData.id, data);
        } else {
          response = await addAddress(data);
          formData.id = response.data.addressId;
        }
        if (response) {
          const captchaToken = await recaptcha.executeRecaptcha('creditCard');
          await savePaymentInstrument(formData, captchaToken);
        }
      } finally {
        hideSpinner();
      }
    };

    return {
      isCreditCardFormVisible,
      paymentStore,
      userBillingAddresses,
      selectedAddress,
      selectAddressChangeHandler,
      addressModelData,
      scrollToTop: scrollTo,
      cancel,
      submitForm,
      $v,
      addressFormKey,
      addNewAddressOption,
      isCcIconBlurred,
    };
  },
});

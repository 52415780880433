




























































































































































































/* eslint-disable no-case-declarations */
import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  onMounted,
  onBeforeUnmount,
} from '@vue/composition-api';
import {
  useFitFinder,
  useI18n,
  useRewardCycleTranslations,
} from '@vf/composables';
import type {
  OrderSuccessDetailsTranslation,
  ProductShippingOption,
} from '@vf/api-contract';
import { PaymentMethodCode } from '@vf/api-client';
import OrderSummaryInfo from '@/components/order/OrderSummaryInfo.vue';
import OrderPickupDetails from '@/components/order/OrderPickupDetails.vue';
import {
  DataProtectedInfo,
  mapToDataProtectedInfos,
  pushProtected,
  pushUnprotected,
  resolveTwoConditions,
} from '@/helpers';
import useRootInstance from '@/shared/useRootInstance';
import { maskGiftCardNumber } from '@vf/composables/src/utils/giftCard';
import { getDeliveryDateLabel } from '@vf/shared/src/utils/helpers';
import { storeToRefs } from 'pinia';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';

export default defineComponent({
  name: 'SuccessDetails',
  components: {
    OrderSummaryInfo,
    OrderPickupDetails,
  },
  props: {
    translations: {
      type: Object as PropType<OrderSuccessDetailsTranslation>,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.orderSuccess;

    const { exposeFitFinderData, removeFitFinderData } = useFitFinder(root);
    const { getStaticTranslation, localeCode, localeMapping } = useI18n(root);

    const localeData = {
      localeCode: localeCode(),
      localeMapping: localeMapping(),
    };

    const narvarTranslations = getStaticTranslation('narvar');

    const { order, orderShipmentsSortedByDelivery } = storeToRefs(
      useCheckoutStore()
    );

    const headingLevel = theme.summaryHeadingLevelModifier || 4;
    const headingModifier = theme.summaryHeadingTitleModifier || 'title-4';
    const pickupDetailsAtTop = theme.pickupDetailsAtTop || false;
    const getDirectionButtonClass = theme.getDirectionButtonClass || '';
    const getDirectionButtonIcon = theme.getDirectionButtonIcon || '';
    const showDeliveryDataInOneRow = theme.showDeliveryDataInOneRow ?? false;

    onMounted(() => {
      exposeFitFinderData(window, order.value);
    });

    onBeforeUnmount(() => {
      removeFitFinderData(window);
    });
    const isPaymentLabelHtml = computed(() =>
      order.value.paymentMethod.some(
        (method) => method.code === PaymentMethodCode.KLARNA
      )
    );

    const paymentDetails = computed<DataProtectedInfo[]>(() => {
      const paymentMethods = order.value.paymentMethod.map((method) => ({
        code: method.code as string,
        label: method.label as string,
        cardType: String(method.additionalData?.card_type) || '',
        number: method.additionalData?.number_last_digits || '',
        giftCardNumber: method.additionalData?.gcCardNumber || '',
        amount: method.additionalData?.amount || '',
        loyaltyPoints: method.additionalData?.loyaltyPoints || '',
        additionalData: method.additionalData,
      }));
      const infos: DataProtectedInfo[] = [];
      if (!Array.isArray(paymentMethods) || paymentMethods.length < 1) {
        return infos;
      }
      paymentMethods.forEach((paymentMethod, i) => {
        if (i > 0) {
          // For more than payment method push empty line as separator between methods
          pushUnprotected(null, infos);
        }
        if (paymentMethod.code === 'APPLEPAY') {
          paymentMethod.code = PaymentMethodCode.APPLEPAY;
        }
        switch (paymentMethod.code) {
          case PaymentMethodCode.CREDIT_CARD:
            const cardType = paymentMethod.cardType;
            const cardNumber = paymentMethod.number;
            const mask =
              props.translations.cardNumberReplacement || ': **** **** ****';
            pushUnprotected(
              props.translations.paymentMethodLabel.CREDIT_CARD,
              infos
            );
            pushProtected(`${cardType}${mask} ${cardNumber}`, infos);
            break;

          case PaymentMethodCode.GIFT_CARD:
            const giftCardNumber = maskGiftCardNumber(
              paymentMethod.giftCardNumber,
              4,
              props.translations.cardNumberReplacement ? '' : '*'
            );
            pushUnprotected(
              props.translations.paymentMethodLabel.GIFT_CARD,
              infos
            );
            pushProtected(
              `${
                props.translations.cardNumberReplacement
                  ? props.translations.cardNumberReplacement + ' '
                  : ''
              }${giftCardNumber}`,
              infos
            );
            break;

          case PaymentMethodCode.REWARD_CARD:
            const rewardCardNumber = maskGiftCardNumber(
              paymentMethod.giftCardNumber,
              4,
              props.translations.cardNumberReplacement ? '' : '*'
            );
            pushUnprotected(
              props.translations.paymentMethodLabel.REWARD_CARD,
              infos
            );
            pushProtected(
              `${
                props.translations.cardNumberReplacement
                  ? props.translations.cardNumberReplacement + ' '
                  : ''
              }${rewardCardNumber}`,
              infos
            );
            break;

          case PaymentMethodCode.KLARNA:
            const klarnaLabel = `<div class="klarnaLabel"> ${props.translations.paymentMethodLabel.KLARNA.replace(
              '{{klarnaIconRed}}',
              '<div class="klarnaIconRed" role="img" aria-label="Klarna"></div>'
            )} </div>`;
            pushUnprotected(klarnaLabel, infos);
            break;

          case PaymentMethodCode.PAYPAL:
            pushUnprotected(
              props.translations.paymentMethodLabel.PAYPAL,
              infos
            );
            break;

          case PaymentMethodCode.APPLEPAY:
            const labels = [
              props.translations.paymentMethodLabel.DW_APPLE_PAY,
              paymentMethod.cardType.toUpperCase(),
            ];
            pushUnprotected(labels.join('-'), infos);
            break;

          case PaymentMethodCode.ATHLETES:
            pushUnprotected(
              props.translations.paymentMethodLabel.ATHLETE_CREDIT,
              infos
            );
            break;

          case PaymentMethodCode.LOYALTY_POINTS:
            pushUnprotected(
              props.translations.paymentMethodLabel.LOYALTY_POINTS,
              infos
            );

            let loyaltyVoucherDetails =
              props.translations.loyaltyPointsDisplayText;

            loyaltyVoucherDetails = loyaltyVoucherDetails.replace(
              '{{dollarAmount}}',
              root.$formatPrice(paymentMethod.amount)
            );

            let points = String(
              parseFloat(paymentMethod.loyaltyPoints as string)
            );
            loyaltyVoucherDetails = loyaltyVoucherDetails.replace(
              '{{pointAmount}}',
              points
            );

            pushUnprotected(loyaltyVoucherDetails, infos);
            break;

          case PaymentMethodCode.REWARD_CODE:
            const { getRewardCycleText } = useRewardCycleTranslations({
              summer: props.translations.summerReward,
              winter: props.translations.winterReward,
              spring: props.translations.springReward,
            });
            pushUnprotected(
              props.translations.paymentMethodLabel.REWARD_CODE,
              infos
            );
            pushProtected(getRewardCycleText(paymentMethod), infos);
            break;

          default:
            pushUnprotected(paymentMethod.label, infos);
            break;
        }
      });
      return infos;
    });

    const pickupLocations = computed(() => {
      return order.value.shipments.filter((method) => method.storeName);
    });

    const shippingLocations = computed(() => {
      return order.value.shipments.filter((method) => !method.storeName);
    });

    const getDirectionLink = (address) => {
      return `https://maps.google.com/?q=${address.addressLine1} ${address.addressLine2},${address.city},${address.postalCode},${address.country}`;
    };

    const isAltPickupPerson = (address) => {
      return address.altFirstName?.length > 0;
    };

    const uniqueSortedShipments = computed(() => {
      return orderShipmentsSortedByDelivery.value.filter(
        ({ shippingMethod, storeName }, index, self) => {
          const shipmentIndex = self.findIndex(
            (shipment) => shipment.shippingMethod.label === shippingMethod.label
          );
          return shipmentIndex === index && !storeName;
        }
      );
    });

    const hasPickupOptions = (
      shippingOption: ProductShippingOption,
      validOptions: string[]
    ): boolean => {
      return !!shippingOption?.storeInfo?.pickupOptions.some((option) =>
        validOptions.includes(option)
      );
    };

    const hasInStoreEligibleItems = computed(
      () =>
        !!order.value?.items?.some(
          (item) =>
            !!item?.shippingOptions?.some((option) =>
              hasPickupOptions(option, ['BOPIS', 'STS'])
            )
        )
    );

    const hasCurbsideEligibleItems = computed(
      () =>
        !!order.value?.items?.some(
          (item) =>
            !!item?.shippingOptions?.some((option) =>
              hasPickupOptions(option, ['CURBSIDE'])
            )
        )
    );

    const pickupDetailsTranslations = computed(() => {
      const {
        detailsInfoLabel,
        detailsIntroText,
        detailsQuestionText,
        detailsInStoreStepsTexts,
        detailsCurbsideStepsTexts,
        detailsInStoreAndCurbsideStepsTexts,
      } = props.translations;
      return {
        detailsInfoLabel,
        detailsIntroText,
        detailsQuestionText,
        detailsStepsTexts: resolveTwoConditions(
          hasInStoreEligibleItems.value,
          hasCurbsideEligibleItems.value,
          detailsInStoreAndCurbsideStepsTexts,
          detailsInStoreStepsTexts,
          detailsCurbsideStepsTexts,
          detailsInStoreStepsTexts
        ),
      };
    });

    const getPickupLocationInfo = (store) => {
      return [
        {
          value: store.storeName,
          isProtected: false,
        },
        ...mapToDataProtectedInfos(
          store.shippingAddress,
          theme.pickupAddressFormat
        ),
      ];
    };

    return {
      theme,
      paymentDetails,
      order,
      pickupLocations,
      shippingLocations,
      isAltPickupPerson,
      getDirectionLink,
      uniqueSortedShipments,
      headingLevel,
      headingModifier,
      isPaymentLabelHtml,
      pickupDetailsAtTop,
      pickupDetailsTranslations,
      getDirectionButtonClass,
      getDirectionButtonIcon,
      showDeliveryDataInOneRow,
      getPickupLocationInfo,
      mapToDataProtectedInfos,
      getDeliveryDateLabel,
      localeData,
      narvarTranslations,
    };
  },
});


































































































import { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { CartProductsTranslations } from '@vf/api-contract';
import { CartLineItem, NotAddedItem } from '@vf/api-client';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';

/**
 * @deprecated - GLOBAL15-39789 - this component will be replaced by ThemeProductLine
 */
export default defineComponent({
  name: 'CartItem',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    translations: {
      type: Object as PropType<CartProductsTranslations>,
      required: true,
    },
    product: {
      type: Object as PropType<CartLineItem & NotAddedItem>,
      required: true,
    },
    /**
     * Disable all product links
     */
    noLink: { type: Boolean, default: false },
  },
  setup(props) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.cartProductList;
    // TODO: GLOBAL15-61059 remove after redesign core
    const { isCoreRedesignEnabled } = useFeatureFlagsStore();

    const linkComponent = computed(() => {
      return props.noLink ? 'div' : 'ThemeLink';
    });

    const attributes = computed(() => {
      return props.product.variants
        .map(({ id, label: variantLabel, value }) => {
          const label = props.translations[variantLabel] || variantLabel;
          return {
            id,
            label,
            value,
          };
        })
        .filter(({ id, value }) => id && value);
    });

    const price = computed(() => {
      return root.$formatPrice(
        props.product.price?.current,
        props.product.price?.currency
      );
    });

    const totalPrice = computed(() => {
      return root.$formatPrice(
        props.product.price?.rowTotal,
        props.product.price?.currency
      );
    });

    return {
      isCoreRedesignEnabled,
      theme,
      linkComponent,
      attributes,
      price,
      totalPrice,
    };
  },
});















import { defineComponent, watch, ref } from '@vue/composition-api';
import { useCart } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import BasePanel from '@/components/panel/BasePanel.vue';

export default defineComponent({
  name: 'MiniCartRedesign',
  components: { BasePanel },
  setup() {
    const { root } = useRootInstance();
    const { isMinicartOpen, setMiniCart } = useCart(root);
    const panel = ref(null);

    watch(isMinicartOpen, (isOpen) => {
      if (isOpen) {
        panel.value.openPanel();
      }
    });

    return {
      panel,
      setMiniCart,
    };
  },
});

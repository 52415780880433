var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-sizes-wrapper"},[(_vm.showTopDivider && !_vm.isVansPdpRedesignEnabled)?_c('VfDivider'):_vm._e(),_vm._v(" "),(!_vm.hideComponent && _vm.showOption)?_c('div',{staticClass:"product-sizes additional",class:{
      'product-sizes--invalid': _vm.notifyMissingSize,
      'product-sizes--on-swap-modal': _vm.isSwapCartItemModal,
      'product-sizes--redesign': _vm.isVansPdpRedesignEnabled,
    },attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'])}},[_c('div',{staticClass:"product-sizes__label"},[_c('VfHeading',{staticClass:"product-sizes__heading",class:{
          'product-sizes__heading--redesign-catalog': _vm.isVansPdpRedesignEnabled,
        },attrs:{"data-id":_vm.$attrs['data-id'],"level":3,"title":_vm.showSelectedVariationTitle && _vm.sizeLabel
            ? _vm.mainTitle + ' - ' + _vm.sizeLabel
            : _vm.mainTitle,"title-modifier":_vm.headingModifier}}),_vm._v(" "),((_vm.$v[_vm.role].$error || _vm.notifyMissingSize) && _vm.product)?_c('span',{staticClass:"product-sizes__error"},[(_vm.themeConfig.showSizeErrorIcon)?_c('VfIcon',{staticClass:"product-sizes__error-icon",attrs:{"icon":"error","size":"18px","color":"danger"}}):_vm._e(),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.translations.validationMessages.unselectedMessage))])],1):_vm._e()],1),_vm._v(" "),(_vm.showRolePrice)?_vm._l((_vm.variantsGroupedBySizePrice),function(item,key){return _c('div',{key:key,staticClass:"product-sizes__priceRange-block"},[_c('VfPrice',{staticClass:"product-sizes__price",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'componentConfig'),"special":item.price.current != item.price.original
              ? _vm.getFormattedPrice(item.price.current)
              : '',"regular":_vm.getFormattedPrice(item.price.original),"special-price-colored":true,"show-regular-price-after":true}}),_vm._v(" "),_c('VfOptionsSwatch',{attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'resourceBundle'),"sizes":_vm.availableSizesRange(item),"show-more-text":_vm.translations.showMoreText,"out-of-stock-text":_vm.translations.outOfStock,"round-items":false,"value":_vm.size,"product":_vm.product,"role":_vm.role,"available-variants":_vm.roleAvailableVariants,"view-all-options-default":_vm.showSizesExpanded},on:{"input":function($event){return _vm.configureSize($event)},"blur":function($event){return _vm.$v[_vm.role].$touch()}}})],1)}):(_vm.product)?_c('VfOptionsSwatch',{attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'resourceBundle'),"sizes":_vm.availableSizes,"show-more-text":_vm.translations.showMoreText,"out-of-stock-text":_vm.translations.outOfStock,"round-items":false,"value":_vm.size,"product":_vm.product,"role":_vm.role,"available-variants":_vm.roleAvailableVariants,"view-all-options-default":_vm.showSizesExpanded,"show-only-unavailable-sizes":_vm.isWideRole},on:{"input":function($event){return _vm.configureSize($event)},"blur":function($event){return _vm.$v[_vm.role].$touch()}}}):_vm._e()],2):_vm._e(),_vm._v(" "),(_vm.showBottomDivider)?_c('VfDivider'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('VfHeading',{staticClass:"vf-quick-add-to-cart-modal__label",attrs:{"title":_vm.translations.sizeLabel,"level":5,"title-modifier":"title-5","styles":{
      titleFontSize: 'var(--font-base)',
    }}}),_vm._v(" "),(_vm.showError)?_c('VfText',{staticClass:"vf-quick-add-to-cart-modal__error",attrs:{"tag":"span","content":_vm.translations.sizeError,"text-color":"var(--c-primary)","styles":{ fontSize: 'var(--font-base)' }}}):_vm._e(),_vm._v(" "),_c('VfOptionsSwatch',{staticClass:"vf-quick-add-to-cart-modal__sizes",attrs:{"sizes":_vm.sizes,"value":_vm.size,"available-variants":_vm.availableSizeVariants,"view-all-options-default":true,"disable-based-on-attribute-only":true},on:{"input":_vm.selectSize}}),_vm._v(" "),_c('VfHeading',{staticClass:"vf-quick-add-to-cart-modal__label",attrs:{"title":_vm.translations.qtyLabel,"level":5,"title-modifier":"title-5","styles":{
      titleFontSize: 'var(--font-base)',
    }}}),_vm._v(" "),_c('div',{staticClass:"vf-quick-add-to-cart-modal__quantities"},_vm._l((_vm.maxQuantity),function(qtyOption){return _c('button',{key:qtyOption,class:{
        'vf-quick-add-to-cart-modal__chip': true,
        'is-active': _vm.qty === qtyOption,
      },domProps:{"textContent":_vm._s(qtyOption)},on:{"click":function($event){return _vm.selectQty(qtyOption)}}})}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoyaltyRewardsProgressBar from './LoyaltyRewardsProgressBar.vue';
import LoyaltyRewardsExpiry from './LoyaltyRewardsExpiry.vue';

export default {
  name: 'LoyaltyRewardsProgressAccordion',
  components: {
    LoyaltyRewardsProgressBar,
    LoyaltyRewardsExpiry,
  },
  props: {
    greeting: {
      type: String,
      default: '',
    },
    totalRewards: {
      type: String,
      default: '',
    },
    summary: {
      type: String,
      default: '',
    },
    vouchers: {
      type: Array,
      default: () => [],
    },
    full: {
      type: Boolean,
      default: false,
    },
    rewards: {
      type: Object,
    },
  },
};

import { Ref, computed } from '@vue/composition-api';
import { ComponentInstance, ComposablesStorage } from '../types';
import initStorage from '../utils/storage';
import { ref } from '@nuxtjs/composition-api';
import { FacetCmsConfiguration } from '../useCms/types';

export type UseFiltersStorage = {
  visibleAllFacets: Ref<string[]>;
  facetLimits: Ref<FacetLimits>;
  showFiltersBar: Ref<boolean>;
};

export type FacetLimits = {
  filterNumberOfFacetsToShow: number;
  filterNumberOfFacetsToShowLarge: number;
  filterNumberOfFacetsToShowMedium: number;
  filterNumberOfFacetsToShowSmall: number;
};
/**
 * Composable for returns
 * @param {ComponentInstance} instance root vue instance
 */
export const useFilters = (instance: ComponentInstance) => {
  const storage: ComposablesStorage<UseFiltersStorage> = initStorage<UseFiltersStorage>(
    instance,
    'useFilters'
  );

  const visibleAllFacets: Ref<string[]> =
    storage.get('visibleAllFacets') ??
    storage.save('visibleAllFacets', ref([]));

  const showFiltersBar: Ref<boolean> =
    storage.get('showFiltersBar') ?? storage.save('showFiltersBar', ref(true));

  const facetLimits: Ref<FacetLimits> =
    storage.get('facetLimits') ?? storage.save('facetLimits', ref(null));

  const setFacetLimits = (limits) => (facetLimits.value = limits);

  const isFacetLimitDefined = computed(
    () =>
      facetLimits.value?.filterNumberOfFacetsToShow ||
      facetLimits.value?.filterNumberOfFacetsToShowSmall ||
      facetLimits.value?.filterNumberOfFacetsToShowMedium ||
      facetLimits.value?.filterNumberOfFacetsToShowLarge
  );

  const setShowFiltersBar = (show: boolean) => {
    showFiltersBar.value = show;
  };

  const getFiltersOptions = (filteringOptions) =>
    filteringOptions.value.reduce((result, filter) => {
      /** Show all facets if its expanded or there is not limit specified */
      const areAllFiltersShown =
        visibleAllFacets.value.includes(filter.code) ||
        !isFacetLimitDefined.value;

      result[filter.code] = areAllFiltersShown
        ? filter.options.map((element) => {
            return { ...element, show: true };
          })
        : getDefinedNumberOfFilters(filter.options);
      return result;
    }, {});

  const getDefinedNumberOfFilters = (filters) => {
    if (!instance.$isServer && instance.$viewport) {
      switch (instance.$viewport.size) {
        case 'large':
          return mapFilterShow(
            filters,
            facetLimits.value?.filterNumberOfFacetsToShowLarge ||
              facetLimits.value?.filterNumberOfFacetsToShow ||
              filters.length
          );
        case 'medium':
          return mapFilterShow(
            filters,
            facetLimits.value?.filterNumberOfFacetsToShowMedium ||
              facetLimits.value?.filterNumberOfFacetsToShow ||
              filters.length
          );
        default:
          return mapFilterShow(
            filters,
            facetLimits.value?.filterNumberOfFacetsToShowSmall ||
              facetLimits.value?.filterNumberOfFacetsToShow ||
              filters.length
          );
      }
    }
    return filters;
  };

  const mapFilterShow = (filters, number) =>
    filters.map((element, index) => {
      return index >= number
        ? { ...element, show: false }
        : { ...element, show: true };
    });

  const isViewAllVisible = (filters) =>
    getDefinedNumberOfFilters(filters.options).some(
      (item) => item.show === false
    );

  const getButtonText = (filters, translations, showCount = true) => {
    const showMoreText = showCount
      ? `${translations.showMore} (${filters.options.length})`
      : translations.showMore;
    return visibleAllFacets.value.includes(filters.code)
      ? translations.showLess
      : showMoreText;
  };

  const toggleVisibilityAllFacets = (filter) => {
    if (visibleAllFacets.value.includes(filter.code)) {
      visibleAllFacets.value = visibleAllFacets.value.filter(
        (code) => code !== filter.code
      );
    } else {
      visibleAllFacets.value.push(filter.code);
    }
  };

  const resetVisibleAllFacets = () => (visibleAllFacets.value = []);

  const getFilterItemValue = (
    filter,
    item,
    displayCount,
    facetConfiguration?: Record<string, FacetCmsConfiguration>,
    defaultFacetType?: string
  ) => {
    let displayItemCount = displayCount;
    if (facetConfiguration && defaultFacetType) {
      const hasOverridenCategory = ['chip', 'checkbox', 'thumbnail'].some(
        (type: 'chip' | 'thumbnail' | 'checkbox') => {
          return isFilterFacetOverriden(
            filter.code,
            type,
            facetConfiguration,
            defaultFacetType
          );
        }
      );
      displayItemCount = !hasOverridenCategory;
    }
    if (!displayItemCount) return item.text;
    const filtersNotShowingCounter =
      instance.$themeConfig.categoryFilters?.hideFilterCounter || [];
    const counter = filtersNotShowingCounter.includes(
      filter.code.trim().toLowerCase()
    )
      ? ``
      : ` (${item.items})`;
    return `${item.text}${counter}`;
  };

  const isFilterFacetOverriden = (
    code: string,
    displayType: 'chip' | 'thumbnail' | 'checkbox',
    facetConfiguration: Record<string, FacetCmsConfiguration>,
    defaultFacetType: string
  ) => {
    const category = facetConfiguration[code];
    return category
      ? category.displayType !== defaultFacetType &&
          category.displayType === displayType
      : false;
  };

  const getFilterItemImageIcon = (id: string) => {
    const prefix = 'shoe-style-';
    const modelSegment = id.toLocaleLowerCase().replace(/ /g, '-');
    return `${prefix}${modelSegment}`;
  };

  const isRangeFilter = (
    code: string,
    facetConfiguration: Record<string, FacetCmsConfiguration>
  ) => {
    return facetConfiguration[code]?.displayType === 'range';
  };

  const createRangeFilter = (maxDisplayPrice, resetFilter, selectFilter) => {
    // Price queries are delivered as [$min TO $max || *], "*" being all prices above $min
    return ({ min, max }, code: string) => {
      // reset filter values
      resetFilter(code);
      // min and max are at original values, so we reset and return
      if (min === 0 && max === maxDisplayPrice) return;
      // otherwise we query
      max = max === maxDisplayPrice ? '*' : max;
      selectFilter({ code, value: `[${min} TO ${max}]` });
    };
  };

  return {
    getFiltersOptions,
    getFilterItemValue,
    isViewAllVisible,
    getButtonText,
    toggleVisibilityAllFacets,
    visibleAllFacets: computed(() => visibleAllFacets.value),
    setFacetLimits,
    setShowFiltersBar,
    showFiltersBar: computed(() => showFiltersBar.value),
    isFilterFacetOverriden,
    getFilterItemImageIcon,
    isFacetLimitDefined,
    resetVisibleAllFacets,
    isRangeFilter,
    createRangeFilter,
  };
};

export default useFilters;

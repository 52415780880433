




















































import type { PropType } from 'vue';
import { ref, defineComponent } from '@vue/composition-api';
import type { CustomsSizeQuantitySelectorTranslations } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';
import { useGtm } from '@vf/composables';

export default defineComponent({
  name: 'CustomsSizeQuantitySelector',
  props: {
    translations: {
      type: Object as PropType<CustomsSizeQuantitySelectorTranslations>,
      default: () => ({}),
    },
    maxQuantity: { type: Number, default: 10 },
    additionalData: { type: Object, default: () => ({}) },
    showError: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const qty = ref(1);
    const size = ref(null);
    const { root } = useRootInstance();
    const { dispatchEvent } = useGtm(root);
    const config = root.$themeConfig?.carousel || false;

    const sizeValues = Object.keys(props.additionalData.sizesObject);

    // VfOptionsSwatch component needs this formatting to read sizes
    const availableSizeVariants = sizeValues.map((size) => ({
      attributes: { size },
    }));

    const sizes = sizeValues.map((s, i) => ({
      id: '',
      value: Object.values(props.additionalData.sizesObject)[i],
      available: true,
      label: s,
    }));

    const selectSize = (key) => {
      size.value = key.value;
      context.emit('update');

      if (!config.dispatchGtmEvents) {
        return;
      }
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventCategory: 'Customs',
          eventAction: 'Add Your Touch - Select Size',
          eventLabel: size.value,
          nonInteractive: false,
          customMetrics: {},
          customVariables: {},
          _clear: true,
        },
      });
    };

    const selectQty = (key) => {
      qty.value = key;
      context.emit('update');

      if (!config.dispatchGtmEvents) {
        return;
      }
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventCategory: 'Customs',
          eventAction: 'Add Your Touch - Select Quantity',
          eventLabel: qty.value,
          nonInteractive: false,
          customMetrics: {},
          customVariables: {},
          _clear: true,
        },
      });
    };

    return {
      size,
      availableSizeVariants,
      sizes,
      selectSize,
      selectQty,
      qty,
    };
  },
});

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { onMounted, ref } from '@vue/composition-api';
import ClickOutside from 'vue-click-outside';
import LoyaltyRewardsProgressBar from './LoyaltyRewardsProgressBar.vue';
import LoyaltyRewardsExpiry from './LoyaltyRewardsExpiry.vue';
import useRootInstance from '@/shared/useRootInstance';

export default {
  name: 'LoyaltyRewardsProgressDialog',
  components: {
    LoyaltyRewardsProgressBar,
    LoyaltyRewardsExpiry,
  },
  directives: {
    ClickOutside,
  },
  props: {
    greeting: {
      type: String,
      default: '',
    },
    totalRewards: {
      type: String,
      default: '',
    },
    summary: {
      type: String,
      default: '',
    },
    vouchers: {
      type: Array,
      default: () => [],
    },
    full: {
      type: Boolean,
      default: false,
    },
    rewards: {
      type: Object,
    },
  },
  setup() {
    const { root } = useRootInstance();

    const dialog = ref(null);

    onMounted(() => {
      root.$eventBus.$on('simplified-enrollment-login', () => {
        dialog.value.show();
        setTimeout(() => {
          dialog.value.close();
        }, 5000);
      });
    });

    return {
      dialog,
    };
  },
};

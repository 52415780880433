










































































































































































































































import type { CartLineItem, FlashError } from '@vf/api-client';

import {
  computed,
  defineComponent,
  inject,
  ref,
  watch,
} from '@vue/composition-api';
import DeliveryMethodSelector from './DeliveryMethodSelector.vue';
import DeliveryMethodModal from './DeliveryMethodModal.vue';
import {
  useAccount,
  useCart,
  useGtm,
  useI18n,
  useNotification,
  useProduct,
  useProductInventory,
  useSaveForLater,
  useSignInToStore,
} from '@vf/composables';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { useUserStore } from '@vf/composables/src/store/user';
import {
  getCartProductListThemeConfig,
  getFlashesByProductId,
  getGtmPayloadforCartUpdate,
} from '@/helpers';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import useRootInstance from '@/shared/useRootInstance';
import useLoader from '@/shared/useLoader';
import { AthletePromoType, Context } from '@vf/api-contract';
import useModal from '@/shared/useModal';
import MenuList from '@/components/MenuList.vue';
import { isMainlineItem, isPickupOrSts } from '@vf/shared/src/utils/helpers';
import StoreSelector from '@/components/static/checkout/pickup/storeselector/StoreSelector.vue';
import GiftOption from '@/components/static/cart/GiftOption.vue';
import GiftOptionSummary from '@/components/static/cart/GiftOptionSummary.vue';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import { storeToRefs } from 'pinia';
import { replaceAll } from '@/helpers/replaceAll';
import { pluralize } from '@/helpers/pluralize';

type ItemLevelMessage = { code: string; message: string | null };

export default defineComponent({
  name: 'CartProducts',
  components: {
    GiftOptionSummary,
    GiftOption,
    StoreSelector,
    DeliveryMethodSelector,
    DeliveryMethodModal,
    MenuList,
    CmsDynamicSlot: () => import('@/components/cms/CmsDynamicSlot.vue'),
    PriceOverride: () => import('@/components/static/cart/PriceOverride.vue'),
  },
  setup() {
    const { root } = useRootInstance();
    const { localePath } = useI18n(root);
    const { isBopisEnabled } = useFeatureFlagsStore();
    const userStore = useUserStore(root);
    const { favoriteStoreId, setFavoriteStoreId } = useAccount(root);
    const {
      updateItem,
      deleteItem,
      overridePrice,
      updatePrice,
      resetPrice,
      isPriceAdjusted,
    } = useCart(root);
    const { dispatchEvent, deleteCartProductTrace } = useGtm(root);
    const { showSpinner, hideSpinner, isSpinnerVisible } = useLoader();
    const {
      clearNotifications,
      addNotification,
      removeNotification,
      notifications,
    } = useNotification(root);
    const { addSaveForLater, isSaveForLaterProductToUpdate } = useSaveForLater(
      root
    );
    const { ...editedProduct } = useProduct(root, Context.QuickShop);
    const { getProductInventory } = useProductInventory(
      root,
      Context.QuickShop
    );
    const { openModal, closeModal } = useModal();
    const { setCartLoading } = useCartStore();
    const {
      cartId,
      shippingItems,
      inStoreItems,
      isMixedShippingAndPickup,
      isBopisSupported,
      customerFacingFlashes,
    } = storeToRefs(useCartStore());
    const { employeeConnected } = useSignInToStore(root);
    const { contextKey, dynamicSlots } = inject('dynamicSlots');
    const staticCartTheme = root.$themeConfig.staticCart;
    const theme = getCartProductListThemeConfig(root.$themeConfig);
    const flyoutContainer = ref(null);
    const clickedProduct = ref(null);
    const deliveryMethodModalProduct = ref<CartLineItem>(null);
    const mapShippingItem = (product) => ({
      ...product,
      shippingMethod: (product.shippingOptions ?? []).find(
        ({ selected }) => selected
      )?.shippingMethod,
    });
    const calculateItemsCount = (items) =>
      items.reduce((acc, item) => acc + item.qty, 0);
    const cartProductSections = computed(() => {
      return [
        {
          label: root.$t('cartProducts.delivery'),
          visible: shippingItems.value.length,
          subSections: [
            {
              items: shippingItems.value
                .filter((item) => !item.isCustoms)
                .map(mapShippingItem),
            },
            {
              label: root.$t('cartProducts.customs'),
              items: shippingItems.value
                .filter((item) => item.isCustoms)
                .map(mapShippingItem),
            },
          ],
          countLabel: replaceAll(
            `${root.$t(
              pluralize(
                'cartProducts.itemCount',
                calculateItemsCount(shippingItems.value)
              )
            )}`,
            {
              count: calculateItemsCount(shippingItems.value),
            }
          ),
        },
        {
          label: root.$t('cartProducts.pickup'),
          visible: inStoreItems.value.length,
          subSections: [
            {
              items: inStoreItems.value.map(mapShippingItem),
            },
          ],
          countLabel: replaceAll(
            `${root.$t(
              pluralize(
                'cartProducts.itemCount',
                calculateItemsCount(inStoreItems.value)
              )
            )}`,
            {
              count: calculateItemsCount(inStoreItems.value),
            }
          ),
        },
      ];
    });

    const isTooltipVisible = ref<string>(null);

    const changeQuantityAction = async (data: {
      product: CartLineItem;
      quantity?: number;
      storeId?: string;
      onFinishCall?: (status?: boolean) => void;
    }) => {
      let query = '';
      const isShippingOptionChange: boolean = data.storeId !== undefined;
      if (typeof data.quantity === 'undefined') {
        data.quantity = data.product.qty;
      }
      const triggerGTM = !(
        data.quantity === data.product.qty || isShippingOptionChange
      );
      if (isBopisEnabled && isShippingOptionChange) {
        if (data.storeId && favoriteStoreId.value !== data.storeId) {
          setFavoriteStoreId(data.storeId);
        }
        query = `action=pickup${
          // we can extract and reuse query builder from useUrl/handlers/urlHelpers.ts
          favoriteStoreId.value ? '&favStoreId=' + favoriteStoreId.value : ''
        }`;
      }

      const success = await updateItem(
        {
          productId: data.product.productId,
          recipeId: data.product.recipeId,
          itemId: data.product.id,
          qty: data.quantity,
          maxQty: data.product.maxQty,
          pdpUrl: data.product.pdpUrl,
          productImageURL: data.product.productImageURL,
          ...(isBopisEnabled && // can be moved to useCart composable
          isShippingOptionChange
            ? {
                storeId: data.storeId,
              }
            : {}),
        },
        !theme.showErrorNotification,
        query
      );
      data.onFinishCall?.(success);
      if (triggerGTM && success) {
        dispatchEvent(getGtmPayloadforCartUpdate(data as any));
        dispatchEvent(getEventFromTemplate('cart:update', {}));
      }
    };
    const removeAction = async (product: CartLineItem) => {
      flyoutContainer.value.hide();
      showSpinner();
      await deleteItem(product.id);
      hideSpinner();
      dispatchEvent({
        ...getEventFromTemplate('cart:remove', {}),
        composablesContexts: { useProduct: 'quickShop' },
        overrideAttributes: {
          product: product,
          quantity: product.qty,
        },
      });
      dispatchEvent(getEventFromTemplate('cart:update', {}));
    };

    const addSaveForLaterAction = async (product: CartLineItem) => {
      flyoutContainer.value.hide();
      clearNotifications();
      showSpinner();
      const success = await addSaveForLater(product);
      hideSpinner();
      // TODO: GLOBAL15-56035 Resolve problem with product visible in the same time in sandard products and saved for later
      if (success) {
        addNotification({
          message: root.$t('cartProducts.savedForLaterNotification') as string,
          type: 'info',
        });
      }
    };
    const changeDeliveryMethod = async (product, storeInfo, code) => {
      if (!isPickupOrSts(code) || storeInfo) {
        showSpinner();
        const storeId = storeInfo?.id || favoriteStoreId.value || '';
        const query = `action=pickup&favStoreId=${storeId}`;
        deleteCartProductTrace(product.productId, ['shippingMethod']);
        await updateItem(
          {
            productId: product.productId,
            recipeId: product.recipeId,
            itemId: product.id,
            qty: product.qty,
            maxQty: product.maxQty,
            pdpUrl: product.pdpUrl,
            productImageURL: product.productImageURL,
            storeId: storeInfo?.id || '',
          },
          !theme.showErrorNotification,
          query
        );
        hideSpinner();
      }
      deliveryMethodModalProduct.value = null;
    };

    const editProduct = async (product) => {
      editedProduct.setOldProduct(product);
      // check if edited product is Save For Later type
      isSaveForLaterProductToUpdate.value = false;
      // if customizer or Custom PDP product
      if (product.recipeId) {
        root.$router.push(product.pdpUrlRelative);
      } else {
        // open quickshop
        const defaultVariants = product.variants.reduce(
          (acc, variant) => ({
            ...acc,
            [variant.code]: variant.id,
          }),
          {}
        );

        await editedProduct.toggleQuickShop(
          product[theme.productIdPropertyName],
          defaultVariants
        );
        openModal({
          type: 'page',
          path: localePath('/quickshop-cart'),
          contextKey: Context.QuickShop,
        });
        await getProductInventory(product[theme.productIdPropertyName]);
        flyoutContainer.value.hide();
      }
    };

    const determineShippingMethodLabel = (method) =>
      isPickupOrSts(method?.code)
        ? method.label
        : root.$t('cartProducts.delivery');

    const flyoutContainerListItems = computed(() => {
      if (!clickedProduct.value) return [];
      const items = [
        {
          text: root.$t('cartProducts.remove'),
          icon: 'close',
          handler: () => removeAction(clickedProduct.value),
        },
      ];
      if (isMainlineItem(clickedProduct.value)) {
        items.push(
          {
            text: root.$t('cartProducts.saveForLater'),
            icon: 'arrow_down',
            handler: () => addSaveForLaterAction(clickedProduct.value),
          },
          {
            text: root.$t('cartProducts.edit'),
            icon: 'edit',
            handler: () => editProduct(clickedProduct.value),
          }
        );
      }
      return items;
    });

    const handleOpenMenu = (event, product) => {
      flyoutContainer.value.show(event);
      clickedProduct.value = product;
    };

    const pickupProduct = ref<CartLineItem>(null);
    const openStoreSelector = (product) => {
      pickupProduct.value = product;
    };

    const isPickupUnavailable = (shippingOptions) => {
      const shippingMethod = shippingOptions.find(
        ({ shippingMethod, storeInfo }) =>
          isPickupOrSts(shippingMethod.code) && storeInfo
      );
      return shippingMethod && !shippingMethod.available;
    };

    const giftOptionProduct = ref<CartLineItem>(null);
    const updateGiftOption = async (data) => {
      giftOptionProduct.value = null;
      setCartLoading(true);
      await updateItem(data);
      setCartLoading(false);
    };

    const getNotEligibleProductMessage = (product) => {
      if (userStore.athlete) {
        if (product.price.original > product.price.current) {
          return root.$t('cartProducts.excludedFromAthleteDueSale');
        }
        // TODO: spike GLOBAL15-66797, workaround for athlete users. expected custom message instead 'notEligibleProductInfoMsg'
        const hasAthletePromotions = product.productPromotions?.some(
          ({ promotionId }) => promotionId === AthletePromoType.PROMO_ID
        );
        if (!hasAthletePromotions) {
          return root.$t('cartProducts.excludedFromAthleteMessage');
        }

        return;
      }

      return product.notEligibleProductInfoMsg;
    };

    watch(
      () => editedProduct.isQuickShopOpen.value,
      (val) => {
        !val && closeModal();
      }
    );

    // TODO: Workaround for the $viewport not reporting the correct size. To be removed when fixed.
    const isSmall = computed(() =>
      ['small', 'smDown', 'smUp'].includes(root.$viewport.size)
    );

    const isDeliverySelectorVisible = (product: CartLineItem) =>
      isBopisSupported.value && !product.isCustoms && isMainlineItem(product);

    const getStoreName = (product: CartLineItem) => {
      return product.shippingOptions.find((option) => option.selected === true)
        ?.storeInfo?.name;
    };

    const getMessagesByProductId = (
      flashes: FlashError[],
      productId: string
    ): ItemLevelMessage[] => {
      const productRelatedFlashes = getFlashesByProductId(flashes, productId);
      const messages = productRelatedFlashes.map(({ code }) => {
        const key = `cartNotifications.itemLevel.${code}`;
        return {
          code,
          message: root.$te(key) ? (root.$t(key) as string) : null,
        };
      });
      return messages.filter(({ message }) => !!message);
    };

    const overrideMessages = [
      root.$t('priceOverride.overrideSuccessMessage') as string,
      root.$t('priceOverride.resetSuccessMessage') as string,
    ];

    const addPriceOverrideNotification = (newMessage: string) => {
      overrideMessages.forEach((item) => {
        const index = notifications.value.findIndex(
          ({ message }) => message === item
        );
        if (index != -1) removeNotification(index);
      });
      addNotification({
        message: newMessage,
        type: 'success',
        modifiers: 'scrollToNotification',
      });
    };

    const overrideProductPrice = async (payload) => {
      const data = {
        discount: {
          type: 'fixed_price',
          value: parseFloat(payload.price),
        },
        item_id: payload.itemId,
        reasonCode: payload.comment,
        level: 'product',
      };
      await overridePrice(cartId.value, data);
      if (isPriceAdjusted.value) {
        addPriceOverrideNotification(
          root.$t('priceOverride.overrideSuccessMessage') as string
        );
      }
    };

    const updateProductPrice = async (payload) => {
      await updatePrice(cartId.value, payload.priceAdjustmentId, {
        applied_discount: {
          type: 'fixed_price',
          amount: parseFloat(payload.price),
        },
      });
      addPriceOverrideNotification(
        root.$t('priceOverride.overrideSuccessMessage') as string
      );
    };

    const resetProductPrice = async (priceAdjustmentId) => {
      await resetPrice(cartId.value, priceAdjustmentId);
      addPriceOverrideNotification(
        root.$t('priceOverride.resetSuccessMessage') as string
      );
    };

    const getSelectedShippingOption = (selectedShippingOptions = []) =>
      selectedShippingOptions.find(({ selected }) => selected)?.shippingMethod
        ?.code || '';

    return {
      flyoutContainer,
      flyoutContainerListItems,
      changeQuantityAction,
      removeAction,
      editProduct,
      addSaveForLaterAction,
      changeDeliveryMethod,
      isPickupOrSts,
      isBopisSupported,
      getNotEligibleProductMessage,
      cartProductSections,
      deliveryMethodModalProduct,
      isTooltipVisible,
      isSpinnerVisible,
      handleOpenMenu,
      staticCartTheme,
      pickupProduct,
      giftOptionProduct,
      updateGiftOption,
      openStoreSelector,
      isPickupUnavailable,
      isSmall,
      isMixedShippingAndPickup,
      determineShippingMethodLabel,
      isDeliverySelectorVisible,
      contextKey,
      dynamicSlots,
      customerFacingFlashes,
      getMessagesByProductId,
      getStoreName,
      employeeConnected,
      overrideProductPrice,
      updateProductPrice,
      resetProductPrice,
      getSelectedShippingOption,
    };
  },
});









import { defineComponent, ref } from '@vue/composition-api';
import BaseDialog from '@/components/dialog/BaseDialog.vue';

export default defineComponent({
  name: 'PanelComponent',
  components: { BaseDialog },
  setup() {
    const dialog = ref(null);
    const openPanel = () => dialog.value.open();

    return {
      dialog,
      openPanel,
    };
  },
});

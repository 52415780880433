






















































import {
  defineComponent,
  PropType,
  ref,
  watch,
  computed,
} from '@vue/composition-api';
import { CreateAccountFormTranslations } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'XplrPass',
  props: {
    translations: {
      type: Object as PropType<CreateAccountFormTranslations>,
      required: true,
    },
    required: {
      type: Boolean,
      default: true,
    },
    termsValid: {
      type: Boolean,
      default: false,
    },
    privacyValid: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const isTermsConfirmed = ref<boolean>(false);
    const isPrivacyConfirmed = ref<boolean>(false);
    const xplrLearnMoreExternalLink = computed(() =>
      props.translations.xplrLearnMoreLink.startsWith('http')
        ? props.translations.xplrLearnMoreLink
        : `https://${
            root.$getDomainName() + props.translations.xplrLearnMoreLink
          }`
    );

    watch(isTermsConfirmed, (val) =>
      emit('input', val && isPrivacyConfirmed.value)
    );
    watch(isPrivacyConfirmed, (val) =>
      emit('input', val && isTermsConfirmed.value)
    );

    return {
      isTermsConfirmed,
      isPrivacyConfirmed,
      xplrLearnMoreExternalLink,
    };
  },
});












































































import {
  defineComponent,
  PropType,
  ref,
  computed,
  watch,
} from '@nuxtjs/composition-api';
import type { ProductShippingOption } from '@vf/api-contract';
import type { CartLineItem } from '@vf/api-client';
import { isPickupOrSts } from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'DeliveryMethodSelector',
  props: {
    product: {
      type: Object as PropType<CartLineItem>,
      default: () => ({}),
    },
    pickupProduct: {
      type: Object as PropType<CartLineItem>,
      default: () => ({}),
    },
    shippingOptions: {
      type: Array as PropType<ProductShippingOption[]>,
      default: () => [],
    },
    selectedShippingOption: {
      type: String,
      default: '',
    },
  },
  emits: ['open:store-selector'],
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const selectedShippingOpt = ref(props.selectedShippingOption);
    const isTooltipVisible = ref(false);
    const openStoreSelectionModal = () => {
      emit('open:store-selector', props.product);
    };
    const isSmall = computed(() => {
      return root.$viewport.isSmall && root.$viewport.breakpoint.xs;
    });
    const onInput = (storeInfo, code) => {
      if (isPickupOrSts(code) && !storeInfo?.id) {
        openStoreSelectionModal();
      } else {
        emit('change:delivery-method', storeInfo, code);
      }
    };
    const ariaControlsId = (label) => {
      return label
        ? `${label.replace(/\s/g, '-').toLowerCase()}-tooltip`
        : null;
    };

    watch(
      () => props.pickupProduct,
      (val) => {
        if (!val) {
          selectedShippingOpt.value = props.selectedShippingOption;
        }
      }
    );

    return {
      openStoreSelectionModal,
      isPickupOrSts,
      isSmall,
      isTooltipVisible,
      onInput,
      ariaControlsId,
      selectedShippingOpt,
    };
  },
});

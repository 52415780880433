



import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Anchor',
  props: {
    id: {
      type: String,
      required: true,
      default: '',
    },
  },
});

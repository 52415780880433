import { onBeforeUnmount, Ref } from '@vue/composition-api';
import { PaymentMethodCode } from '@vf/api-client';
import {
  ROUTES,
  useCart,
  useCheckout,
  useI18n,
  usePayPal,
} from '@vf/composables';
import { getCartShippingAddress } from '@vf/composables/src/useCart/helpers';
import { errorMessages } from '@vf/composables/src/utils/errorMessages';
import { SessionPaymentData } from '@vf/composables/src/usePayPal';
import useRootInstance from './useRootInstance';
import useLoader from './useLoader';
import {
  usePayPalStore,
  PayPalContext,
} from '@vf/composables/src/store/paypal';
import { storeToRefs } from 'pinia';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import { useOrderStore } from '@vf/composables/src/store/order';

/**
 * Expand if needed based on
 * https://developer.paypal.com/docs/archive/checkout/how-to/customize-button/#button-styles
 * */
type PayPalButtonStyle = {
  shape: 'pill' | 'rect';
  color: 'gold' | 'blue' | 'silver' | 'white' | 'black';
  height?: number;
};

type UsePayPalButton = {
  renderPayPalButton: (
    selector: string | HTMLButtonElement,
    style: PayPalButtonStyle
  ) => void;
  payPalContext: Ref<PayPalContext>;
  setPayPalContext: (value: PayPalContext) => void;
};

function usePayPalButton(): UsePayPalButton {
  const { root } = useRootInstance();
  const { localePath } = useI18n(root);
  const paypalStore = usePayPalStore(root);
  const { isShippingAddressIncomplete } = useCartStore();

  const { setPayPalContext } = paypalStore;
  const { payPalContext } = storeToRefs(paypalStore);

  const {
    cartId,
    initPayPalSession,
    updatePayPalSession,
    updateCart,
    setMiniCart,
  } = useCart(root);
  const { saveShippingAddress, paymentMethod, placeOrder } = useCheckout(root);
  const {
    setPayPalOrderObject,
    payPalCheckoutFlowInProgress,
    setPayPalCheckoutFlowInProgress,
    setPayPalPaymentInProgress,
  } = usePayPal(root);
  const { displayErrorMessages } = errorMessages(root);
  const { hideSpinner, showSpinner } = useLoader();

  let buttons;
  let buttonStateController: { enable: () => void; disable: () => void };

  const setButtonState = (state: 'enable' | 'disable') => {
    /** Only applicable for payment page */
    if (payPalContext.value === 'payment') {
      buttonStateController?.[state]?.();
    } else {
      buttonStateController?.enable?.();
    }
  };

  const renderPayPalButton = (selector, style) => {
    let sessionPaymentData: SessionPaymentData;

    const placeOrderExecution = async () => {
      paymentMethod.value =
        payPalContext.value === 'payment'
          ? PaymentMethodCode.PAYPAL
          : PaymentMethodCode.PAYPAL_EXPRESS;
      await placeOrder();
      setPayPalCheckoutFlowInProgress(false);
      setPayPalPaymentInProgress(false);
    };

    buttons = paypal.Buttons({
      style,
      onInit: function (data, actions) {
        buttonStateController = actions;
        setButtonState(
          payPalCheckoutFlowInProgress.value ? 'disable' : 'enable'
        );
      },
      onCancel: function () {
        setPayPalPaymentInProgress(false);
      },
      onClick: async function () {
        /** Set a boolean flag to determine payment is in progress (used to block cart re-fetch on every window tab focus) */
        setPayPalPaymentInProgress(true);

        /** Instant place order flow if paypal modal window journey has been already completed elsewhere */
        if (
          payPalContext.value === 'payment' &&
          payPalCheckoutFlowInProgress.value
        ) {
          await placeOrderExecution();
        }
      },
      createOrder: async function () {
        try {
          const session = await initPayPalSession();

          if (isShippingAddressIncomplete()) {
            saveShippingAddress(getCartShippingAddress(session.data));
          }

          const paymentInstrument = session.data.payment_instruments.find(
            (element) => element.payment_method_id === 'PAYPAL'
          );
          if (!paymentInstrument) {
            throw new Error('Init PayPal session error');
          }

          sessionPaymentData = {
            c_paymentID: '',
            c_requestID: '',
            ...paymentInstrument,
          };

          return sessionPaymentData.c_paymentID;
        } catch (e) {
          displayErrorMessages(e);
          throw new Error('Init PayPal session error');
        }
      },
      onApprove: async function (data) {
        try {
          showSpinner();
          if (data.paymentID) {
            sessionPaymentData['c_paymentID'] = data.paymentID;
          }

          setPayPalOrderObject(sessionPaymentData, data.payerID);

          const isAddressValidated =
            root.$themeConfig.payPal.skipShippingAddressChecking ||
            !isShippingAddressIncomplete();

          /**
           * Quick order flow - place an order right after PayPal modal submission.
           * Flow may be determined by checking existence of paymentID parameter that comes from PayPal.
           * */

          if (
            (data.paymentID || payPalContext.value === 'payment') &&
            isAddressValidated
          ) {
            await placeOrderExecution();
          } else {
            /**
             * Checkout flow - redirect user to shipping page after PayPal modal submission
             * */
            try {
              /** PATCH payment instrument after PayPal modal journey */
              const response = await updatePayPalSession({
                cartId: cartId.value,
                paymentInstrumentId: sessionPaymentData.payment_instrument_id,
                payerId: data.payerID,
                paymentMethodId: PaymentMethodCode.PAYPAL,
                requestId: sessionPaymentData.c_requestID,
              });
              /** Close mini-cart */
              setMiniCart(false);

              /** Set a boolean flag to determine checkout flow is in progress (used in payment step) */
              setPayPalCheckoutFlowInProgress(true);
              /** Update cart object in composable */
              await updateCart(response.data);
              const orderStore = useOrderStore();
              orderStore.paymentMethod = PaymentMethodCode.PAYPAL;
              /** Save Shipping Address from PayPal Cart response */
              saveShippingAddress(getCartShippingAddress(response.data));
              /** Redirect user to shipping step */
              root.$router.push(localePath(ROUTES.CHECKOUT_SHIPPING()));
            } catch (e) {
              /** Handle errors locally to prevent multiple notifications on payment page */
              displayErrorMessages(e);
            } finally {
              setPayPalPaymentInProgress(false);
            }
          }
        } finally {
          hideSpinner();
        }
      },
      onError: (err) => {
        setPayPalPaymentInProgress(false);
        root.$log.error(
          `[@/shared/usePayPalButton.ts] Error from the PayPal error handler`,
          err
        );
      },
    });
    buttons.render(selector);
  };

  onBeforeUnmount(() => {
    if (buttons?.close && buttonStateController) {
      buttons.close();
    }
  });

  return {
    renderPayPalButton,
    payPalContext,
    setPayPalContext,
  };
}

export default usePayPalButton;





































import { defineComponent, ref } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { useProduct } from '@vf/composables';

export default defineComponent({
  name: 'ProductLink',
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    linkLabel: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    contextKey: {
      type: String,
      default: 'product',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { product } = useProduct(root, props.contextKey);

    const modalVisible = ref(false);

    const iconSize = root.$themeConfig?.productLink?.iconSize || 'xl';

    const displayConfig = ref(true);

    if (props.config['pdp-attributeId'] == 'wearTestDaysAttribute') {
      if (props.config.value != product.value.wearTestDaysAttribute)
        displayConfig.value = false;
    }
    if (props.config['pdp-attributeId'] == 'sizeChart') {
      if (props.config.operator == 'IN')
        displayConfig.value = props.config.value
          .split(',')
          .includes(product.value.sizeChart);
      if (props.config.operator == 'NOTIN')
        displayConfig.value = !props.config.value
          .split(',')
          .includes(product.value.sizeChart);
    }
    return {
      modalVisible,
      iconSize,
      displayConfig,
    };
  },
});

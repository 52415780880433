
















import { ROUTES, useI18n } from '@vf/composables';
import { storeToRefs } from 'pinia';
import ExpressCheckout from '@/components/static/cart/ExpressCheckout.vue';
import useRootInstance from '@/shared/useRootInstance';
import { useCartStore } from '@vf/composables/src/store/cartStore';

export default {
  name: 'CartCtaSidebar',
  components: { ExpressCheckout },
  setup() {
    const { root } = useRootInstance();
    const { localePath } = useI18n(root);
    const { totalItems } = storeToRefs(useCartStore());
    return {
      goToCheckoutLink: localePath(ROUTES.CHECKOUT()),
      totalItems,
    };
  },
};

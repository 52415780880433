












































import type { PropType } from 'vue';
import { computed, ref, defineComponent } from '@vue/composition-api';
import { validationMixin } from 'vuelidate';
import type { ProductFindInStoreListTranslations } from '@vf/api-contract';
import { useI18n, ROUTES } from '@vf/composables';
import CreateAccountFormBirthdate from '../account/CreateAccountFormBirthdate.vue';
import useRootInstance from '@/shared/useRootInstance';
import Notifications from '../layout/Notifications.vue';
import { useUserStore } from '@vf/composables/src/store/user';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'SignUp',
  components: {
    CreateAccountFormBirthdate,
    Notifications,
  },
  mixins: [validationMixin],
  props: {
    translations: {
      type: Object as PropType<ProductFindInStoreListTranslations>,
      required: true,
    },
    /** Flag to determine auth from CMS side**/
    isLoggedFromCms: {
      type: String,
      default: '',
    },
    interestsLink: {
      type: String,
      default: '',
    },
    /** Show sms subscription checkbox below phone number **/
    showSmsSubscription: Boolean,
    contextKey: {
      type: String,
      required: true,
    },
    /** Component Form Validations */
    validations: {
      type: Object,
      default: () => ({
        birthdayRegex: '',
        phoneNumberRegex: '',
        zipCodeRegex: '',
        zipCodeFormat: '',
        phoneNumberFormat: '',
      }),
    },
  },
  setup() {
    const { root } = useRootInstance();
    const userStore = useUserStore(root);
    const { loggedIn } = storeToRefs(userStore);
    const { localePath } = useI18n(root);
    const signUpVisible = ref(false);

    const onCloseModal = () => {
      signUpVisible.value = false;
    };

    const onShowModal = () => {
      signUpVisible.value = true;
    };

    const cartRedirectPath = computed(() => {
      return localePath(ROUTES.CART());
    });

    return {
      minRegisterAge: root.$config.MINIMUM_REGISTER_AGE,
      onCloseModal,
      onShowModal,
      loggedIn,
      signUpVisible,
      cartRedirectPath,
    };
  },
});


































































import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api';
import type {
  CreateAccountFormTranslations,
  FormValidationConfig,
} from '@vf/api-contract';
import { Context } from '@vf/api-contract';
import {
  ROUTES,
  useAccount,
  useAuthentication,
  useCart,
  useFavorites,
  useGtm,
  useI18n,
  useNotification,
  useReCaptcha,
  useSignInToStore,
  useUtilities,
} from '@vf/composables';
import { PageLayoutName } from '@vf/composables/src/useCms/types';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import { errorMessages } from '@vf/composables/src/utils/errorMessages';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import useAuthenticationEvents, {
  AuthenticationType,
} from '@/shared/useAuthenticationEvents';
import { scrollToElement, scrollTo } from '@vf/shared/src/utils/helpers';
import type { PhoneInputCountry } from '@vf/composables/src/useUtilities';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@vf/composables/src/store/user';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

const XplrPass = () => import('@/components/loyalty/XplrPass.vue');

interface PrefilledUserData {
  firstName?: string;
  lastName?: string;
  email?: string;
  zip?: string;
  phone?: string;
  isSubscribed?: boolean;
}

export default defineComponent({
  name: 'CreateAccountFormBirthdate',
  components: { XplrPass },
  props: {
    translations: {
      type: Object as PropType<CreateAccountFormTranslations>,
      required: true,
    },
    /** Component Form Validations */
    validations: {
      type: Object as PropType<FormValidationConfig>,
      required: true,
    },
    /** Form heading level 1-6 */
    headingLevel: {
      type: Number,
      default: 4,
    },
    /** Link to redirect after successful sign up */
    link: {
      type: String,
      default: '',
    },
    /** Show sms subscription checkbox below phone number **/
    showSmsSubscription: Boolean,
    /** Show password icon **/
    showPasswordToggler: {
      type: Boolean,
      default: false,
    },
    interestsLink: {
      type: String,
      default: '',
    },
    /* Toggles visibility of heading text */
    showHeading: {
      type: Boolean,
      default: true,
    },
    /* Toggles visibility of heading icon above input fields */
    showHeadingIcon: {
      type: Boolean,
      default: false,
    },
    /* Toggles visibility of Xplr Pass section */
    showXplrPass: {
      type: Boolean,
      default: true,
    },
    /**
     * Redirect to destination page right after account creation
     */
    redirect: {
      type: Boolean,
      default: true,
    },
    redirectTarget: {
      type: String,
      default: ROUTES.PROFILE(),
    },
    redirectEnrolledTarget: {
      type: String,
      default: ROUTES.ACCOUNT_FAMILY(),
    },
    /* Show interests modal after enrolment */
    showInterestsModal: {
      type: Boolean,
      default: true,
    },
    headingIcon: {
      type: String,
      default: () => '',
    },
    headingIconSize: {
      type: String,
      default: () => '',
    },
    /** Initial value for newsletter consent checkbox */
    newsletterSignUpAutoCheck: {
      type: Boolean,
      default: false,
    },
    contextKey: {
      type: String,
      required: true,
    },
  },
  emits: ['sign-up', 'create-account-success'],
  setup(props, { emit }) {
    const cmsRefStore = useCmsRefStore();
    const { root } = useRootInstance();

    const { dispatchEvent, getFormLocation } = useGtm(root);
    const formLocation = getFormLocation(props.contextKey);
    const { openModal, closeModal } = useModal();
    const { getIetfLocale } = useI18n(root);
    const { getCountry, getPhoneInputCountryList } = useUtilities(root);

    const { pageLayoutName } = storeToRefs(cmsRefStore);

    const theme = root.$themeConfig.createAccountFormBirthdate;
    const isCatalogPage = [
      PageLayoutName.PDP,
      PageLayoutName.PLP,
      PageLayoutName.SRP,
    ].includes(pageLayoutName.value as PageLayoutName);

    const { isCheckoutSuccessRedesignEnabled } = useFeatureFlagsStore();
    const isCheckoutSuccessPage =
      root.$route.path.endsWith(ROUTES.CHECKOUT_ORDER_STATUS()) &&
      isCheckoutSuccessRedesignEnabled;

    const locale = getIetfLocale();
    const countryCode = getCountry().toUpperCase();
    const { basicInformation, setBasicInformation } = useAccount(root);
    const {
      signUp,
      signIn,
      saveForLaterId,
      processPendingActionForLoyaltyUser,
    } = useAuthentication(root);
    const { order } = storeToRefs(useCheckoutStore());
    const userStore = useUserStore(root);
    const { loggedIn, loyaltyEnrolled } = storeToRefs(userStore);
    const { createSignIn, createSignUp } = useAuthenticationEvents(
      formLocation,
      {
        useLoadEvent: true,
        type: AuthenticationType.Registration,
      }
    );
    const reCaptcha = useReCaptcha(root);

    const { cartId, saveGuestCart } = useCart(root);
    const { favoriteId } = useFavorites(root);
    const { getStaticTranslation } = useI18n(root);
    const { clearNotifications } = useNotification(root);
    const { hasStoreCookie, storeInfoData } = useSignInToStore(root);
    const { getErrorDetails } = errorMessages(root);
    const vfCreateAccountBirthdateRef = ref(null);

    const userType = computed(() => {
      if (!loggedIn.value) {
        return 'guest';
      }

      if (loyaltyEnrolled.value) {
        return 'member';
      }

      return 'legacy';
    });

    const showSignInModal = async () => {
      emit('close-modal');

      const isSignInPage = root.$route.path.endsWith(ROUTES.SIGN_IN());
      if (isSignInPage) return;

      await nextTick();

      openModal({
        type: 'page',
        path: ROUTES.SIGN_IN(),
      });
    };

    const buttonDisabled = ref(false);
    const formSubmitted = ref(false);
    const error = ref(null);
    // Should be applied only for already exits account errors
    const uniqueEmailError = ref(false);
    const xplrEnrolled = ref(false);

    const prefillAccountData = computed<PrefilledUserData>(() => {
      return {
        ...(loggedIn.value
          ? {
              firstName: basicInformation.value.firstName,
              lastName: basicInformation.value.lastName,
              email: basicInformation.value.email,
              zip: basicInformation.value.postalCode,
              phone: basicInformation.value.phone,
            }
          : {}),
        isSubscribed: props.newsletterSignUpAutoCheck,
      };
    });

    const boundWithGtmEventsSignIn = createSignIn(signIn, {
      isAutomaticLogin: true,
    });

    const boundWithGtmEventsSignUp = createSignUp(
      async <
        T extends {
          enrollments: unknown[];
          store: string;
          source?: string;
          channel?: string;
        }
      >(
        data: T
      ) => {
        // In store Sign Up
        if (hasStoreCookie.value) {
          data.store = storeInfoData.value.storeId;
          data.source = 'IPAD';
          data.channel = 'RETAIL';
        }

        const result = await signUp(data);
        emit('sign-up', data);

        return result;
      }
    );

    const boundWithGtmEventsLoyaltyEnrollment = createSignUp(
      async (data: Record<string, unknown>) => {
        const { getCountry } = useUtilities(root);

        return await setBasicInformation({
          ...data,
          enrollments: [
            { type: 'Loyalty', country: getCountry().toUpperCase() },
          ],
        });
      }
    );

    /**
     * Enroll existing user to loyalty program
     * @param data
     */
    const loyaltyEnrollmentLegacy = async (data) => {
      const response = await boundWithGtmEventsLoyaltyEnrollment(data, {
        overrideAttributes: {
          eventLabel: 'Confirmed',
          customVariables: {
            registrationFlow: 'loyalty',
          },
        },
      });

      closeModal();

      return response;
    };

    const newLoyaltyEnrollment = async (data) => {
      await boundWithGtmEventsSignUp(data, {
        overrideAttributes: {
          customVariables: {
            registrationFlow: xplrEnrolled.value ? 'ecom + loyalty' : 'ecom',
          },
        },
      });

      const signInPayload = {
        password: data.password,
        username: data.email,
        ...(order.value && {
          action: 'postLogin',
          st: order.value.st,
          orderNo: order.value.orderNumber,
        }),
        guestObjects: {
          basketId: cartId.value,
          saveForLaterId: saveForLaterId.value,
          favoriteId: favoriteId.value,
        },
      };

      const signInResponse = await boundWithGtmEventsSignIn(
        signInPayload,
        true
      );

      if (signInResponse) {
        await processPendingActionForLoyaltyUser();
        formSubmitted.value = true;
        emit('create-account-success');
        emit('close-modal');

        closeModal();

        if (props.showInterestsModal) {
          await nextTick();

          openModal({
            type: 'page',
            path: props.interestsLink,
          });
        }
      }
    };

    const handleSignUp = async (data) => {
      formSubmitted.value = false;
      buttonDisabled.value = true;
      clearErrors();
      saveGuestCart();

      const signUpData = {
        ...data,
        enrollNewUsersToLoyalty: true,
        isTermsConfirmed: data.isTermsConfirmed || xplrEnrolled.value,
        skipSubmit: props.contextKey !== Context.Modal,
      };

      try {
        if (userType.value === 'legacy') {
          await loyaltyEnrollmentLegacy(signUpData);
        } else {
          await newLoyaltyEnrollment(signUpData);
        }

        vfCreateAccountBirthdateRef.value?.resetValidation();
        if (
          (!isCatalogPage || isCheckoutSuccessPage) &&
          (props.redirect || root.$route.query.redirectTo)
        ) {
          await root.$router.push(getRedirectUrl());
        }
      } catch (e) {
        // remove global level notifications
        // component should have ONLY local errors
        clearNotifications();

        const errorDetails = getErrorDetails(e.response?.data?.errorDetails)[0];
        const ALREADY_EXISTS_ERROR_CODE = 'PRO400';

        if (errorDetails.errorMessageId === ALREADY_EXISTS_ERROR_CODE) {
          uniqueEmailError.value = true;
        }

        error.value = getStaticTranslation('muleSoftErrors')[
          errorDetails.errorMessageId
        ];

        nextTick(() => {
          const target = vfCreateAccountBirthdateRef.value.$el?.closest(
            '.vf-modal__content'
          );
          scrollToErrors(target);
        });
      } finally {
        buttonDisabled.value = false;
      }
    };

    const getRedirectUrl = () => {
      if (userType.value === 'member') {
        return root.localePath(props.redirectEnrolledTarget);
      }

      if (userType.value === 'legacy') {
        return root.localePath(props.redirectTarget);
      }

      if (root.$route.query.redirectTo) {
        return root.$route.query.redirectTo as string;
      }
    };

    const scrollToErrors = (target) => {
      if (target) {
        scrollTo({
          target,
        });
        return;
      }
      scrollToElement('.vf-notification');
    };

    const clearErrors = () => {
      uniqueEmailError.value = false;
      error.value = null;
    };

    const phoneInputCountries = ref<PhoneInputCountry[]>([]);

    onMounted(async () => {
      reCaptcha.showBadge();
      const allPhoneInputCountries = await getPhoneInputCountryList(locale);
      phoneInputCountries.value = allPhoneInputCountries.filter(
        (item) => item.countryCode === countryCode
      );
    });

    onUnmounted(() => {
      reCaptcha.hideBadge();
      if (!formSubmitted.value && props.contextKey === Context.Modal) {
        dispatchEvent(
          getEventFromTemplate('create-account:close', {
            formLocation,
          })
        );
      }
    });

    return {
      minRegisterAge: root.$config.MINIMUM_REGISTER_AGE,
      userType,
      prefillAccountData,
      buttonDisabled,
      error,
      uniqueEmailError,
      showSignInModal,
      handleSignUp,
      openModal,
      clearErrors,
      vfCreateAccountBirthdateRef,
      scrollToElement,
      scrollToErrors,
      scrollTo,
      xplrEnrolled,
      phoneInputCountries,
      phoneInputDefaultCountryCode: countryCode,
      showPhoneInputCountry: theme.showPhoneInputCountry,
    };
  },
});

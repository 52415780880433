



































































































import type { PropType } from 'vue';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import type {
  OrderItemDetailed,
  ShipmentTrackReturnModalTranslations,
} from '@vf/api-contract';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'ShipmentTrackReturnModal',
  props: {
    translations: {
      type: Object as PropType<ShipmentTrackReturnModalTranslations>,
      required: true,
    },
    returnTrackableItems: {
      type: Array as PropType<OrderItemDetailed[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const modalVisible = ref(false);
    const {
      areNarvarReturnsEnabled,
      isPricingStrikethroughEnabled,
    } = useFeatureFlagsStore();

    const calculatePrice = (product) => {
      const { currency, original, priceAfterItemDiscount } =
        product.price ?? {};

      let unitPrice = root.$formatPrice(original, currency);
      let totalPrice = root.$formatPrice(priceAfterItemDiscount, currency);

      return {
        unitPrice,
        totalPrice,
      };
    };

    const trackableItems = computed(() => {
      return props.returnTrackableItems.map((item) => ({
        ...item,
        productImageURL: item.img,
        price: calculatePrice(item),
        variants: item.attributes.filter((attribute) => attribute.value),
      }));
    });

    const closeModal = () => {
      emit('close');
    };

    watch(
      () => props.returnTrackableItems,
      (val) => (modalVisible.value = !!val.length)
    );

    const formatDate = (date: string) => {
      const intlDate = new Intl.DateTimeFormat(root.$i18n.locale, {
        dateStyle: 'long',
      });
      return intlDate.format(new Date(date));
    };

    return {
      areNarvarReturnsEnabled,
      isPricingStrikethroughEnabled,
      modalVisible,
      trackableItems,
      getReturnDateLabel: (date, key) => {
        return `${props.translations.returnDateLabel}`
          .replace('{{number}}', key)
          .replace(': {{MMMM dd, yyyy}}', date ? `: ${formatDate(date)}` : '');
      },
      closeModal,
    };
  },
});
